/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { FC, useState } from 'react';
import Edit from '@material-ui/icons/Edit';
import Stars from '@material-ui/icons/Stars';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnailContainerDiv: {
      '& p': {
        textAlign: 'left',
        cursor: 'pointer',
        color: '#666666',
        fontSize: '13px',
        fontFamily: 'helvetica',
        lineHeight: '15px',
        paddingLeft: '10px',
        transition: '0.2s ease all',
        position: 'relative',
        paddingRight: '8px',
        '& img': {
          position: 'absolute',
          top: '1px',
          right: '1px',
          width: '13px',
          height: '15px',
        },
      },
      '& .watermark': {
        position: 'relative',
        '& img': {
          position: 'absolute',
          height: '5%',
          left: '43%',
          top: '48%',
          cursor: 'pointer',
          opacity: '0.9',
        },
      },
      '&:hover': {
        top: '1px',
      },
      '& .img-thumbnail': {
        height: '150px',
        width: '200px',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer',
      },
      '& input': {
        display: 'flex',
        margin: '1rem auto',
        color: '#999',
        fontFamily: 'helvetica',
        fontSize: '13px',
        width: '90%',
        marginTop: '8px',
        marginBottom: 0,
      },
      '& .pdf-thumbnail': {
        background: '#f6f6f6',
        textAlign: 'center',
        width: '200px',
        height: '150px',
      },
      '& .pdf-thumbnail-img': {
        height: '150px',
        width: '200px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer',
      },
      '& #pencilIcon': {
        opacity: 0.7,
        height: '14px',
        top: 0,
        left: '190px',
        bottom: 0,
      },
    },
    infoThumbnailContainerDiv: {
      '& p': {
        textAlign: 'left',
        color: '#666666',
        fontSize: '13px',
        fontFamily: 'helvetica',
        lineHeight: '15px',
        paddingLeft: '10px',
        position: 'relative',
        paddingRight: '8px',
      },
      '& .bkg-thumbnail': {
        height: '120px',
        width: '170px',
        padding: '15px',
        textAlign: 'center',
        border: '1px rgba(0, 0, 0, 0.15) dashed',
        background: '#f6f6f6',
      },
      '& .info-text': {
        paddingTop: '40px',
        color: '#dcdcdc',
      },
      '& .error-text': {
        paddingTop: '40px',
        color: 'red',
      },
    },
    primaryStarSpan: {
      height: '25px',
      width: '25px',
      position: 'absolute',
      top: '2px',
      right: '5px',
      color: '#f1307a',
      textAlign: 'center',
      fontSize: '18px',
    },
  }));

// eslint-disable-next-line no-shadow
export enum ThumbnailSize {
  NORMAL,
  SMALL,
}

interface ThumbnailProps {
  file: any;
  previewUrl?: string; // the pdf thumbnail image url.
  primary: boolean;
  setFileName?: any;
  index: number;
  openShadowBox?: (url: string) => void;
  openPDFPreviewFunc?: (url: string) => void;
  constructImageArray?: any;
  id?: number;
  name: any;
  hideStar: boolean;
  errorMsg?: string | null;
  loadingMsg?: string | null;
  showNameEdit: boolean;
  size: ThumbnailSize;
  removeFailedProcess?: (id: number) => void;
  overrideFailure?: (id: number) => void;
  retryError?: (id: number) => void;
}

const Thumbnail: FC<ThumbnailProps> = (props) => {
  const classes = useStyles();
  const [showInput, setShowInput] = useState(false);
  const [fileName, setFileName] = useState(props.name ? props.name.substr(0, props.name.lastIndexOf('.')) : '');

  let fileExtension: string = '';
  if (props?.file) {
    if (!props.name || props.name?.lastIndexOf('.') === -1) {
      const tmpfileExtension = props.file.substr(props.file.lastIndexOf('.'), props.file.length);
      fileExtension = tmpfileExtension.substr(0, tmpfileExtension.lastIndexOf('?'));
    } else {
      fileExtension = props.name ? props.name.substr(props.name.lastIndexOf('.'), props.name.length) : '';
    }
  }

  let imageHeight: number = 150;
  let imageWidth: number = 200;

  if (props.size === ThumbnailSize.SMALL) {
    imageHeight = 93.75;
    imageWidth = 125;
  }

  // toggles between <input> element and p tag
  const toggleInput = () => {
    setShowInput(!showInput);
  };

  // updates local state with the input's value
  const updateFileNameDisplay = (event: any) => {
    if (setFileName) {
      const { value } = event.currentTarget;
      setFileName(value);
    }
  };

  // updates form-upload's parent state with the input's value
  const updateFileNameValue = (event: any) => {
    // if (setFileName) {
    const { value } = event.currentTarget;
    props.setFileName(value, fileExtension, props.index);
    // }
  };

  // reverts to title text from input if the user hits esc or enter
  const handleKeyPress = (event: any) => {
    const { value } = event.currentTarget;
    setFileName(value);
    if (event.keyCode === 13) {
      if (fileName !== '') {
        toggleInput();
        props.setFileName(value, fileExtension, props.index);
      }
    } else if (event.keyCode === 27) {
      if (fileName !== '') {
        toggleInput();
      }
    }
  };

  const openShadowBoxFunc = () => {
    if (props.openShadowBox) {
      props.openShadowBox(props.file);
    }
  };

  const openPDFPreviewFunc = () => {
    if (props.openPDFPreviewFunc) {
      props.openPDFPreviewFunc(props.file);
    }
  };

  // render methods
  const displayFileName = () => {
    if (props.showNameEdit) {
      return (
        <>
          <p role="button" onClick={toggleInput}>
            {fileName} <Edit />
          </p>
        </>
      );
    }
    return (
      <>
        <p>{fileName}</p>
      </>
    );
  };

  if (props.loadingMsg === 'Loading') {
    return (
      <div key={props.file + (props.name || '')} className={classes.infoThumbnailContainerDiv}>
        <div className="bkg-thumbnail">
          <div className="info-text">
            <div style={{ marginBottom: '5px' }}>Processing...</div>
            {/* TODO: progress indicator will go here */}
          </div>
        </div>
        <p>{fileName}</p>
      </div>
    );
  }
  if (props.errorMsg !== undefined && props.errorMsg !== null) {
    return (
      <div key={props.file + (props.name || '')} className={classes.infoThumbnailContainerDiv}>
        <div className="bkg-thumbnail">
          <div className="error-text">
            <div style={{ marginBottom: '5px' }}>{props.errorMsg}</div>
          </div>
        </div>
        <p>{fileName}</p>
      </div>
    );
  }
  if (!props.file) {
    return null;
  }

  return (
    <div
      className={classes.thumbnailContainerDiv}
      key={props.file + (props.name || '')}
    >
      {
        !props.previewUrl ? (
          <div>
            <div
              onClick={() => openShadowBoxFunc()}
              style={{ backgroundImage: `url('${props.file}')` }}
              className="img-thumbnail"
              role="button"
            />
          </div>
        ) : (
          <div>
            <div
              style={{ backgroundImage: `url('${props.previewUrl}')` }}
              className="pdf-thumbnail-img"
              role="button"
              onClick={openPDFPreviewFunc}
            />
          </div>
        )
      }

      {showInput ? (
        <input
          onKeyDown={handleKeyPress}
          onChange={(e: any) => updateFileNameDisplay(e)}
          onBlur={(e: any) => updateFileNameValue(e)}
          type="text"
          value={fileName}
        />
      ) : (
        displayFileName()
      )}
      {props.primary &&
        fileExtension !== '.pdf' &&
        !fileExtension.includes('.xl') &&
        !props.hideStar && (
          <span className={classes.primaryStarSpan}>
            <Stars />
          </span>
      )}
    </div>
  );
};

export default React.memo(Thumbnail);
