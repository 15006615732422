import React, { useState, useEffect } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { Property, GalleryFile, MediaFile } from '../../types';
import { MediaState } from '../../redux/types/dataTypes';
import { mediaFileToGalleryFile, flattenMediaFilesWithModifiedFiles } from '../../utilities';
import PDFPreview from '../PDFPreview';

interface FlierPage {
  id?: string;
  title: string;
  subtitle: string;
  order: string,
  flierUrl: string;
  originalUrl?: string;
}

const PropertyFlyerPreview: React.FC<Props> = ({
  property,
  mediaState,
  pdfPaperSize,
  propertyOrder,
  pageMode,
  isVisible,
}) => {
  const mediaList = useSelector((state: any) => state.media, shallowEqual);
  const [propertiesPreview, setPropertiesPreview] = useState<FlierPage[]>([]);
  const getFliersForProperty = (propertyId: string) => {
    const fileState = mediaList[propertyId] || {};
    const { fliers } = fileState;
    // eslint-disable-next-line arrow-body-style
    return (
      fliers
        ?.filter(
          (media: MediaFile) => media.isHidden === undefined || media.isHidden === false,
        )
        .map((media: MediaFile) => mediaFileToGalleryFile(media)) || []
    );
  };

  useEffect(() => {
    // eslint-disable-next-line no-await-in-loop
    if (property.id && Object.keys(mediaList).length > 0 && isVisible) {
      const fliers: GalleryFile[] = getFliersForProperty(property.id);
      let flierPages: FlierPage[] = flattenMediaFilesWithModifiedFiles(fliers);

      flierPages = fliers.map((flier: any) => ({
        id: flier.id,
        subtitle: '',
        title: property.name,
        order: propertyOrder,
        flierUrl: flier.thumbnailUrl || '',
        originalUrl: flier.url,
      }));
      setPropertiesPreview(flierPages);
    } else {
      setPropertiesPreview([]);
    }
  }, [property.id, mediaList, isVisible, property.name, propertyOrder]);

  return (
    <>
      {/* this section generates the pages for fliers  */}
      {isVisible && propertiesPreview.map((flierPage, index) => (
        <PDFPreview
          pdfUrl={flierPage.flierUrl}
          originalUrl={flierPage.originalUrl || ''}
          paperSize={pdfPaperSize}
          mediaId={flierPage.id}
          key={`${property.id}-flyer-${flierPage.order}-${index}`} />
      ))}
    </>
  );
};

export type Props = {
  property: Property;
  propertyOrder: string;
  pageMode?: string;
  pdfPaperSize: string;
  mediaState: MediaState;
  isVisible: boolean;
};

const mapStateToProps = (state: any, ownProps: any) => ({
  surveyState: state.survey,
  mediaState: state.media,
  ...ownProps,
});

PropertyFlyerPreview.displayName = 'PropertyFlyerPreview';
export default connect(mapStateToProps)(PropertyFlyerPreview);
