/* eslint-disable max-len */

import { isNumber } from 'lodash';
import { MediaFile, PropertyCustomFieldPatch, PropertyPatch, Survey, SurveyPatch } from '../types';
import { SFDCPayload } from '../types/sfdc-payload';

/**
 * Get the nested attr value by passing the attr string.
 * @param object
 * @param attrStr
 * @returns
 */
export const loadNestedAttrByString = <T extends {}>(
  object: T,
  attrStr: string,
) => {
  const attrList = attrStr.replace(/^\./, '');
  const attrKeys = attrList.split('.');
  // eslint-disable-next-line no-plusplus
  let output = { ...object } as { [key: string]: any };
  attrKeys.forEach((attr) => {
    if (attr in output) {
      output = output[attr];
    }
  });
  return output;
};

/**
 * assign the value to the nested object(based on the string)
 * @param attrStr
 * @param value
 * @returns
 */
export const getNestedObjByString = (attrStr: string, value: any) => {
  const attrList = attrStr.replace(/^\./, '');
  const attrKeys = attrList.split('.');
  const reducer = (acc: any, item: any) => ({ [item]: acc });
  return attrKeys.reduceRight(reducer, value);
};

/**
 * download the blob data file directly.
 * @param blobData
 */
export const downloadBlobAsFile = (blobData: any, fileName: string) => {
  const fileURL = window.URL.createObjectURL(new Blob([blobData]));
  const fileLink = document.createElement('a');
  fileLink.href = fileURL;
  fileLink.setAttribute('download', `${fileName}.pdf`);
  document.body.appendChild(fileLink);
  fileLink.click();
};

/**
 * get chunks of array list.
 * @param originArray
 * @param size
 * @param chunkIndex
 */
export const getArrayChunks = (originArray: any, size: number) => {
  const newArray = Array.from(
    new Array(Math.ceil(originArray.length / size)),
    (_, i) => originArray.slice(i * size, i * size + size),
  );
  return newArray;
};

/**
 * return the formatted date.
 * @param date
 * @returns
 */
export const getFormattedDate = (date: Date) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const monthIndex = date.getMonth();
  const monthName = monthNames[monthIndex];
  const year = date.getFullYear();
  const day = date.getUTCDate();
  return `${monthName} ${day},  ${year}`;
};

export const checkValueIsUnavailable = (obj: any) =>
  obj === null || obj === undefined || obj === '';

// eslint-disable-next-line arrow-body-style
export const checkCoordinateInvalid = (obj: any) => {
  return obj === null || obj === undefined || obj === '' || !isNumber(Number(obj));
};

/**
 * Returns full address formatted
 * @param street
 * @param city
 * @param state
 * @param zip
 * @returns
 */
export const getFullAddressStr = (
  street: string,
  city: string,
  state: string,
  zip: string,
) => {
  if (!street && !city && !state && !zip) return '';
  const line1 = street;
  let line2 = '';
  if (city) line2 += city;
  if (city && state) line2 += ', ';
  if (city && !state && zip) line2 += ' ';
  if (state) line2 += state;
  if ((city && zip) || (state && zip)) line2 += ' ';
  if (zip) line2 += zip;

  const fullAddressValidParts = [line1, line2].filter((p) => p);
  return fullAddressValidParts.join('\n');
};

/**
 * Sort two object based on key value
 * @param o1
 * @param o2
 * @param key
 * @returns
 */
export const compareObjects = (o1: any, o2: any, key: any) => {
  const obj1 = o1[key].toUpperCase();
  const obj2 = o2[key].toUpperCase();
  if (obj1 < obj2) {
    return -1;
  }
  if (obj1 > obj2) {
    return 1;
  }
  return 0;
};

/**
 * check if the object is empty after converted from string
 * @param inputStr
 * @returns
 */
export const isEmptyObjectFromStr = (inputStr: string) =>
  Object.keys(JSON.parse(inputStr)).length === 0;

export const getSurveyWithMapData = (
  survey: Survey,
  patch: SurveyPatch | PropertyPatch,
) => {
  const attributes = [
    'latitude',
    'longitude',
    'isIncludeLegend',
    'isIncludeHeader',
    'isHidden',
    'isClientShortlist',
  ];
  if (Object.keys(patch).some((attr) => attributes.includes(attr))) {
    if (
      survey.marketMapBamData &&
      typeof JSON.parse(survey.marketMapBamData) === 'object' &&
      !isEmptyObjectFromStr(survey.marketMapBamData)
    ) {
      const mapData = JSON.parse(survey.marketMapBamData);
      mapData.mapReset = true;
      return JSON.stringify(mapData);
    }
  }
  return '';
};

export const getPropertyFieldRuleData = (survey: Survey, patch: PropertyPatch | SurveyPatch, attr:(keyof typeof patch)):any => {
  const { surveyPropertyFieldRule, properties } = survey || {};
  const propertyFieldRuleList = surveyPropertyFieldRule || [];

  const propertyFieldRule = propertyFieldRuleList.find(
    ({ fieldKey }) => fieldKey === attr);
  const isPropertyFieldRuleAvailable = propertyFieldRule && Object.keys(propertyFieldRule).length;
  if (patch[attr] && isPropertyFieldRuleAvailable && propertyFieldRule?.status !== 'ACTIVE') {
    return { propertyFieldRule, isHidden: false };
  }
  if (!patch[attr] && isPropertyFieldRuleAvailable && propertyFieldRule?.status === 'ACTIVE') {
    const isValueNotAvailable = properties?.filter(
      (eachProperty) => eachProperty.id !== (patch as PropertyPatch)?.id)
      .every((eachProperty) => !eachProperty[attr]);
    return isValueNotAvailable ? { propertyFieldRule, isHidden: true } : '';
  }
  return '';
};

export const isChangingMapSetting = (
  patch: SurveyPatch | PropertyPatch,
) => {
  const attributes = [
    'isIncludeLegend',
    'isIncludeHeader',
    'pdfPaperSize',
  ];
  if (Object.keys(patch).some((attr) => attributes.includes(attr))) {
    return true;
  }
  return false;
};

export const isChangingMapDataSetting = (
  patch: SurveyPatch | PropertyPatch,
) => {
  const attributes = [
    'bamMapData',
    'isCurrentLocation',
  ];
  if (Object.keys(patch).some((attr) => attributes.includes(attr))) {
    return true;
  }
  return false;
};

export const mediaFileToGalleryFile = (media: MediaFile) => ({
  id: media.mediaId,
  url: media.origin !== 'SiteIQ' ? `${media.cloudfrontUrl}` : media.cloudfrontUrl,
  previewUrl: media.pdfImageUrl,
  thumbnailUrl: media.thumbnailUrl,
  displayText: media.displayName,
  active: !media.isHidden,
  primary: false,
  order: media.order,
  deletable: (!(media.origin && media.origin.toLowerCase() === 'siteiq')),
  origin: media.origin,
  modifiedFromMediaId: media.modifiedFromMediaId,
  mediaId: media.mediaId,
});

export const getCustomFieldRuleData = (survey:Survey, patch: PropertyCustomFieldPatch, propertyId: string) => {
  const propertyFieldRule = survey?.surveyPropertyFieldRule?.find(
    ({ fieldKey }) => fieldKey === patch.customFieldId);
  const isPropertyFieldRuleAvailable = propertyFieldRule && Object.keys(propertyFieldRule).length;
  if (patch.value && isPropertyFieldRuleAvailable && propertyFieldRule?.status === 'ACTIVE') {
    return { propertyFieldRule, isHidden: false };
  }
  if (!patch.value && isPropertyFieldRuleAvailable && propertyFieldRule?.status === 'ACTIVE') {
    const isValueNotAvailable = survey?.properties?.filter(
      (eachProperty) => eachProperty.id !== propertyId)
      .every((eachProperty) => !eachProperty.customFields?.find((eachCustomField) => eachCustomField.customField.customFieldId === patch.customFieldId)?.value);
    return isValueNotAvailable ? { propertyFieldRule, isHidden: true } : '';
  }
  return '';
};

export const getSFDCData = (encodedStr: string) => {
  let payloadVal: any = {};
  let client: any = {};
  if (encodedStr) {
    const decodedData = encodedStr ? atob(encodedStr) : '{}';
    const dataObj = JSON.parse(decodedData) as SFDCPayload;
    client = dataObj?.signedRequestClient || {};
    payloadVal = dataObj?.canvasEventPayload?.payload || null;
  } else {
    const sfdcData = sessionStorage.getItem('sfdcData');
    const sfdcDataObj: SFDCPayload = sfdcData ? JSON.parse(sfdcData) : {};
    client = sfdcDataObj.signedRequestClient;
    payloadVal = sfdcDataObj.canvasEventPayload?.payload ? JSON.parse(sfdcDataObj.canvasEventPayload?.payload) : null;
  }
  return {
    payload: payloadVal,
    client,
  };
};

export const flattenMediaFilesWithModifiedFiles = (flyers: any[]) => {
  const files = flyers;
  files?.forEach((item, i) => {
    const index = files.findIndex((res) => item.mediaId === res.modifiedFromMediaId);
    if (index > -1) {
      files[i] = files[index];
      files.splice(index, 1);
    }
  });
  return files;
};
