import React, { useEffect } from 'react';
import { match } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
  CircularProgress,
  createStyles, makeStyles,
} from '@material-ui/core';
import { RouteParams } from '../../routes/routes';
import { deletePayload, getPayload } from '../../services/sfdc-service';
import { SFDCPayload } from '../../types/sfdc-payload';
import { getSFDCData } from '../../utilities';
import { MediaOwner, PropertyId, RegisterMedia } from '../../types';
import { copySurveyMediaFiles } from '../../services/media-service';
import * as SurveyService from '../../services/survey-service';
import * as PropertyService from '../../services/property-service';

import BHSCAnalytics, { EVENT_TITLES, SC_EVENT } from '../../services/BHSCAnalytics';
import { copyProperty, getCollaterals } from '../../services/property-service';
import * as mediaService from '../../services/media-service';

import { deliverableRootURL, mulesoftProxyUrl, mulesoftProxyId } from '../../config';
import { getPropertyIds } from '../../utilities/property';

const useStyles = makeStyles((theme) => createStyles({
  hiddenFlatfileBtn: {
    display: 'none',
  },
}));

const Callback: React.FC<Props> = ({ match: {
  params,
} }) => {
  const classes = useStyles();
  const history = useHistory();
  const { id, data } = params as RouteParams;

  useEffect(() => {
    const publishEvent = (survey: any, client: any, payload: any) => {
      const globalIndex = window as any;
      if (client.oauthToken) {
        if (
          globalIndex.Sfdc &&
          globalIndex.Sfdc.canvas &&
          globalIndex.Sfdc.canvas.client
        ) {
          globalIndex.sfdcClient = client;
          globalIndex.Sfdc.canvas.client.publish(client, {
            name: 'cbrebrokerhub.saveSurvey_cbreone',
            payload: {
              surveyId: survey.id,
              surveyTitle: survey.coverTitle || 'Property Survey',
              surveyName: survey.name,
              passcode: survey.passcode,
              surveyUrl: `${deliverableRootURL}/${survey.id}`,
              oppId: payload.opportunityId,
            },
          });
          globalIndex.Sfdc.canvas.client.publish(client, {
            name: 'cbrebrokerhub.app_loaded',
            payload: {},
          });
        }
      }
      history.push(`/${survey.id}/builder${data ? `/data/${data}` : ''}`);
    };
    if (id) {
      if (data) {
        const { payload, client } = getSFDCData(data || '');
        const globalIndex = window as any;
        if (payload && payload.surveyId && payload.actionType && payload.actionType === 'clone') {
          const startTime = new Date().toISOString();
          SurveyService.copySurvey(payload.surveyId).then(
            (survey) => {
              PropertyService.copyProperties({
                ownerId: survey.id,
                ownerType: 'SC_SURVEY',
                copyFromId: payload.surveyId,
              }).then(
                (propertyData) => {
                  const copyMediaRes = [
                    {
                      ownerId: survey.id,
                      copiedFrom: survey.copiedFrom || '',
                      idType: 'survey',
                    },
                  ] as MediaOwner[];
                  if (Array.isArray(propertyData) && propertyData.length > 0) {
                    propertyData.forEach((property) => {
                      copyMediaRes.push({
                        ownerId: property.id,
                        copiedFrom: property.copiedFrom || '',
                        idType: 'property',
                      });
                    });
                  }
                  copySurveyMediaFiles(copyMediaRes).then(
                    (res) => {
                      const endTime = new Date().toISOString();
                      BHSCAnalytics.trackEvent(SC_EVENT, EVENT_TITLES.SC_SURVEY_CLONE, {
                        startTime,
                        endTime,
                        surveyId: survey.id,
                        projectId: survey.projectId,
                        surveyName: survey.name,
                        source: 'Button'
                      });
                      publishEvent(survey, client, payload);
                    },
                  ).catch((err) => {
                    console.log(err, '... callback error');

                    publishEvent(survey, client, payload);
                  });
                },
              );
            },
          );
        } else if (payload && payload.surveyId) {
          if (payload.properties && payload.actionType === 'modify') {
            const { opportunityId, companyName = '', properties, projectContacts = [], surveyId } = payload;

            const contacts = projectContacts.map((item: string, order: number) => {
              const contact = JSON.parse(item);
              const { User: { Email: email = '', Name: name = '', Phone: phoneNumber = '', Title: title = '' } = {} } = contact;
              return { email, name, phoneNumber, title, order };
            });

            copyProperties(surveyId, JSON.parse(properties), opportunityId, companyName, contacts, client);
          } else {
            history.push(`/${payload.surveyId}/builder${data ? `/data/${data}` : ''}`);
          }
        } else {
          history.push(`/bh/build/data/${data}`);
        }
      } else {
        getPayload(id).then((response: SFDCPayload) => {
          if (response && response.canvasEventPayload && response.signedRequestClient) {
            sessionStorage.setItem('sfdcData', JSON.stringify(response));
            const payload = JSON.parse(response.canvasEventPayload.payload);
            if (payload && payload.surveyId) {
              if (payload.properties && payload.actionType === 'modify') {
                const { surveyId, opportunityId, companyName = '', properties, projectContacts = [] } = payload;

                const contacts = projectContacts.map((item: string, order: number) => {
                  const contact = JSON.parse(item);
                  const { User: { Email: email = '', Name: name = '', Phone: phoneNumber = '', Title: title = '' } = {} } = contact;
                  return { email, name, phoneNumber, title, order };
                });

                // eslint-disable-next-line max-len
                copyProperties(surveyId, JSON.parse(properties), opportunityId, companyName, contacts, response.signedRequestClient);
              } else {
                history.push(`/${payload.surveyId}/builder`);
              }
            } else {
              history.push('/bh/build');
            }
          } else {
            // history.push('/');
            console.log('SFDC Oauth Client can not be empty!');
          }
        });
      }

      deletePayload(id).then(() => {
        console.log('signed request was removed');
      });
    }
  }, [id]);

  const copyProperties = (surveyId: any, properties: any, opportunityId: any, companyName: any, contacts: any, client: any) => copyProperty(surveyId, properties).then((propNew: PropertyId[]) => {
    // copy media files
    getCollaterals({
      opportunityId,
      companyName,
      properties: getPropertyIds(properties) as any,
      projectContacts: contacts,
      client,
      onSuccess: (response: string) => {
        const result = JSON.parse(JSON.parse(response));
        console.log('ds: getCollateral ajax result', result);

        const { media } = result;
        console.log('ds: getCollateral ajax media', media);

        if (Array.isArray(media) && media.length > 0) {
          const registerMedia = media.map((images: RegisterMedia) => {
            const { mediaType, displayName } = images;

            const propertyId = propNew.find((item) => item.copyFromPropertyId === images.propertyId)?.propertyId;

            const { S3url = '', SFurl = '' } = images;
            let url = S3url;
            if (!url) {
              if (mediaType === 'FLOORPLANS' && displayName.includes('.pdf')) {
                url = `${mulesoftProxyUrl}${btoa(SFurl)}${mulesoftProxyId}&file_type=.pdf`;
              } else {
                url = `${mulesoftProxyUrl}${btoa(SFurl)}${mulesoftProxyId}`;
              }
            }

            return {
              surveyId,
              propertyId,
              mediaType,
              displayName,
              url,
              origin: 'BH',
              order: images.order - 1,
              orderType: 'hardOrder',
            } as RegisterMedia;
          });

          console.log('ds: registerMediaFiles start with', registerMedia);
          mediaService.registerMediaFiles(registerMedia)
            .then((res) => {
              console.log('ds: registerMediaFiles end with', res);
            })
            .finally(() => {
              history.push(`/${surveyId}/builder${data ? `/data/${data}` : ''}`);
            });
        } else {
          history.push(`/${surveyId}/builder${data ? `/data/${data}` : ''}`);
        }
      },
    });
  });

  return (
    <div>
      <h1 style={{ marginTop: 200, textAlign: 'center' }}><CircularProgress /></h1>
    </div>
  );
};

export type Props = {
  match: match;
};
Callback.displayName = 'Callback';
export default Callback;
