// eslint-disable-next-line import/no-extraneous-dependencies
import { IField } from '@flatfile/adapter/build/main/interfaces';

const PropertyFields: IField[] = [
  { label: 'Property Name', key: 'name' },
  {
    label: 'Address',
    key: 'address1',
    validators: [
      {
        validate: 'required',
        error: 'This is a required field',
      },
    ],
  },
  {
    label: 'City',
    key: 'city',
    validators: [
      {
        validate: 'required',
        error: 'This is a required field',
      },
    ],
  },
  {
    label: 'State',
    key: 'state',
    type: 'select',
    matchStrategy: 'exact',
    options: [
      { value: 'AL', label: 'Alabama' },
      { value: 'AK', label: 'Alaska' },
      { value: 'AZ', label: 'Arizona' },
      { value: 'AR', label: 'Arkansas' },
      { value: 'CA', label: 'California' },
      { value: 'CO', label: 'Colorado' },
      { value: 'CT', label: 'Connecticut' },
      { value: 'DC', label: 'District of Columbia' },
      { value: 'DE', label: 'Delaware' },
      { value: 'FL', label: 'Florida' },
      { value: 'GA', label: 'Georgia' },
      { value: 'HI', label: 'Hawaii' },
      { value: 'ID', label: 'Idaho' },
      { value: 'IL', label: 'Illinois' },
      { value: 'IN', label: 'Indiana' },
      { value: 'IA', label: 'Iowa' },
      { value: 'KS', label: 'Kansas' },
      { value: 'KY', label: 'Kentucky' },
      { value: 'LA', label: 'Louisiana' },
      { value: 'ME', label: 'Maine' },
      { value: 'MD', label: 'Maryland' },
      { value: 'MA', label: 'Massachusetts' },
      { value: 'MI', label: 'Michigan' },
      { value: 'MN', label: 'Minnesota' },
      { value: 'MS', label: 'Mississippi' },
      { value: 'MO', label: 'Missouri' },
      { value: 'MT', label: 'Montana' },
      { value: 'NE', label: 'Nebraska' },
      { value: 'NV', label: 'Nevada' },
      { value: 'NH', label: 'New Hampshire' },
      { value: 'NJ', label: 'New Jersey' },
      { value: 'NM', label: 'New Mexico' },
      { value: 'NY', label: 'New York' },
      { value: 'NC', label: 'North Carolina' },
      { value: 'ND', label: 'North Dakota' },
      { value: 'OH', label: 'Ohio' },
      { value: 'OK', label: 'Oklahoma' },
      { value: 'OR', label: 'Oregon' },
      { value: 'PA', label: 'Pennsylvania' },
      { value: 'PR', label: 'Puerto Rico' },
      { value: 'RI', label: 'Rhode Island' },
      { value: 'SC', label: 'South Carolina' },
      { value: 'SD', label: 'South Dakota' },
      { value: 'TN', label: 'Tennessee' },
      { value: 'TX', label: 'Texas' },
      { value: 'UT', label: 'Utah' },
      { value: 'VT', label: 'Vermont' },
      { value: 'VA', label: 'Virginia' },
      { value: 'VI', label: 'Virgin Islands' },
      { value: 'WA', label: 'Washington' },
      { value: 'WV', label: 'West Virginia' },
      { value: 'WI', label: 'Wisconsin' },
      { value: 'WY', label: 'Wyoming' },
    ],
    validators: [
      {
        validate: 'required',
        error: 'This is a required field. We accept state codes and state names.',
      },
    ],
  },
  { label: 'Postal Code', key: 'postalCode' },
  { label: 'Country', key: 'country' },
  { label: 'Latitude', key: 'latitude' },
  { label: 'Longitude', key: 'longitude' },
  {
    label: ' Total Available Space (SF)',
    key: 'totalAvailableSpace',
    validators: [
      {
        validate: 'regex_matches',
        regex: '^([\\d,]+)(\\s*)[-~]?(\\s*)([\\d,]*)$',
        error: 'This field only accepts whole numbers, no decimals. Ex: 70,000 or 100,000 - 150,000',
      },
    ],
  },
  {
    label: 'Property Size (SF)',
    key: 'propertySize',
    validators: [{
      validate: 'regex_matches',
      regex: '^[0-9,]{0,18}$',
      error: 'This field only accepts a whole number. Ex 70,000',
    }],
  },
  {
    label: 'Net Rentable Area (SF)',
    key: 'netRentableArea',
    validators: [{
      validate: 'regex_matches',
      regex: '^[0-9,]{0,18}$',
      error: 'This field only accepts a whole number. Ex 70,000',
    }],
  },
  {
    label: 'Property Type',
    key: 'propertyType',
    type: 'select',
    matchStrategy: 'exact',
    options: [
      { value: 'Office', label: 'Office' },
      { value: 'Retail', label: 'Retail' },
      { value: 'Mixed Use', label: 'Mixed Use' },
      { value: 'Industrial', label: 'Industrial' },
      { value: 'Multifamily', label: 'Multifamily' },
      { value: 'OPRE', label: 'OPRE' },
    ],
  },
  {
    label: 'Class',
    key: 'buildingClass',
    type: 'select',
    matchStrategy: 'exact',
    options: [
      { value: 'A', label: 'A' },
      { value: 'B', label: 'B' },
      { value: 'C', label: 'C' },
      { value: 'D', label: 'D' },
      { value: 'E', label: 'E' },
      { value: 'Georgian', label: 'Georgian' },
      { value: 'Obsolete', label: 'Obsolete' },
      { value: 'Second Hand', label: 'Second Hand' },
      { value: 'Secondary', label: 'Secondary' },
      { value: 'Unknown', label: 'Unknown' },
    ],
  },
  {
    label: 'Floors in Building',
    key: 'floors',
    validators: [{
      validate: 'regex_matches',
      regex: '^\\d{0,3}$',
      error: 'Enter a number under 1000',
    }],
  },
  {
    label: 'Typical Floor Size (SF)',
    key: 'typicalFloorSize',
    validators: [{
      validate: 'regex_matches',
      regex: '^[0-9,]{0,18}$',
      error: 'This field only accepts a whole number. Ex 70,000',
    }],
  },
  {
    label: 'Property Market',
    key: 'market',
    validators: [{
      validate: 'regex_matches',
      regex: '^[\\s\\S]{0,255}$',
      error: 'Please shorten to less than 255 characters',
    }],
  },
  {
    label: 'Submarket',
    key: 'submarket',
    validators: [{
      validate: 'regex_matches',
      regex: '^[\\s\\S]{0,255}$',
      error: 'Please shorten to less than 255 characters',
    }],
  },
  {
    label: 'Year Built',
    key: 'yearBuilt',
    validators: [{
      validate: 'regex_matches',
      regex: '^\\d{4}$',
      error: 'This field only accepts a 4 digit number',
    }],
  },
  {
    label: 'Year Renovated',
    key: 'yearRenovated',
    validators: [{
      validate: 'regex_matches',
      regex: '^\\d{4}$',
      error: 'This field only accepts a 4 digit number',
    }],
  },
  {
    label: '# of Parking Spaces (property)',
    key: 'parkingSpaces',
    validators: [{
      validate: 'regex_matches',
      regex: '^[0-9,]{0,7}$',
      error: 'This field only accepts a number',
    }],
  },
  {
    label: 'Parking Ratio', key: 'parkingRatio',
    // validators: [{
    //   validate: 'regex_matches',
    //   regex: '^[\\s\\S]{0,3}$',
    //   error: 'Please shorten to less than <span style="color: red">xxx</span> characters',
    // }]
  },
  {
    label: 'Vacant Space (SF)',
    key: 'vacantSpace',
    validators: [{
      validate: 'regex_matches',
      regex: '^[0-9,]{0,18}$',
      error: 'This field only accepts a whole number. Ex 70,000',
    }],
  },
  {
    label: '% Vacant',
    key: 'vacantPercentage',
    validators: [{
      validate: 'regex_matches',
      regex: '(^100([.]0{0,1})?)$|(^\\d{1,2}([.]\\d{1})?)$',
      error: 'This field accepts a number, with up to 1 decimal',
    }],
  },
  {
    label: 'OPEX ($/SF)',
    key: 'opex',
    validators: [{
      validate: 'regex_matches',
      regex: '^(?=(?:\\d\\.?){0,16}$)\\d+(?:\\.\\d{0,2})?$',
      error: 'This field only accepts a number, with up to 2 decimals',
    }],
  },
  { label: 'Property Owner', key: 'owner' },
  { label: 'Major Tenants', key: 'majorTenants' },
  {
    label: 'Amenities',
    key: 'amenities',
    validators: [{
      validate: 'regex_matches',
      regex: '^[\\s\\S]{0,255}$',
      error: 'Please shorten to less than 255 characters',
    }],
  },
  {
    label: 'Comments',
    key: 'comments',
    validators: [{
      validate: 'regex_matches',
      regex: '^[\\s\\S]{0,255}$',
      error: 'Please shorten to less than 255 characters',
    }],
  },
  {
    label: 'Availability Type',
    key: 'availabilityType',
    type: 'select',
    matchStrategy: 'exact',
    options: [
      { value: 'Entire Building', label: 'Entire Building' },
      { value: 'Entire Floor', label: 'Entire Floor' },
      { value: 'Partial Floor', label: 'Partial Floor' },
    ],
  },
  {
    label: 'Floor', key: 'floor',
  },
  { label: 'Suite', key: 'suite' },
  {
    label: 'Available SF (Availability)',
    key: 'availableSF',
    validators: [
      {
        validate: 'regex_matches',
        regex: '^([\\d,]+)(\\s*)[-~]?(\\s*)([\\d,]*)$',
        error: 'This field only accepts whole numbers, no decimals. Ex: 70,000 or 100,000 - 150,000',
      },
    ],
  },
  {
    label: 'Lease Type',
    key: 'leaseType',
    type: 'select',
    matchStrategy: 'exact',
    options: [
      { value: 'Sublease', label: 'Sublease' },
      { value: 'Direct', label: 'Direct' },
    ],
  },
  {
    label: 'Type',
    key: 'type',
    type: 'select',
    matchStrategy: 'exact',
    options: [
      { value: 'Office', label: 'Office' },
      { value: 'Retail', label: 'Retail' },
      { value: 'Mixed Use', label: 'Mixed Use' },
      { value: 'Industrial', label: 'Industrial' },
      { value: 'Multifamily', label: 'Multifamily' },
      { value: 'OPRE', label: 'OPRE' },
    ],
  },
  {
    label: 'Availability Status',
    key: 'availabilityStatus',
    type: 'select',
    matchStrategy: 'exact',
    options: [
      { value: 'Unknown', label: 'Unknown' },
      { value: 'Immediate', label: 'Immediate' },
      { value: 'Specific date', label: 'Specific date' },
      { value: 'Off Market', label: 'Off Market' },
    ],
  },
  { label: 'Date Available', key: 'availableDate' },
  {
    label: 'Occupancy',
    key: 'occupancy',
    type: 'select',
    matchStrategy: 'exact',
    options: [
      { value: 'Vacant', label: 'Vacant' },
      { value: 'Occupied', label: 'Occupied' },
    ],
  },
  {
    label: 'Asking Rent ($)',
    key: 'askingRent',
    validators: [
      {
        validate: 'regex_matches',
        regex: '^([\\d,]+)(\\.?[\\d]{0,2})(\\s*)[-~]?(\\s*)([\\d,]*)(\\.?[\\d]{0,2})$',
        error: 'This field only accepts a number, with up to 2 decimals. Ex: 18.50 or 18.50-22.00',
      },
    ],
  },
  {
    label: 'Asking Rent Unit',
    key: 'askingRentUnit',
    type: 'select',
    matchStrategy: 'exact',
    options: [
      { value: 'SF/month', label: 'SF/month' },
      { value: 'SF/year', label: 'SF/year' },
    ],
    validators: [
      {
        validate: 'required_with',
        fields: ['askingRent'],
        error: `Choose a value from the drop down list provided<br>
        (can only be left blank if there is no value in Asking Rent field)`,
      },
    ],
  },
  {
    label: 'Asking Rent Services',
    key: 'askingRentServices',
    type: 'select',
    matchStrategy: 'exact',
    options: [
      { value: 'Double Net', label: 'Double Net', alternates: ['NN'] },
      { value: 'Full Service Gross', label: 'Full Service Gross', alternates: ['FSG'] },
      { value: 'Modified Gross', label: 'Modified Gross', alternates: ['MG'] },
      { value: 'Negotiable', label: 'Negotiable' },
      { value: 'Net', label: 'Net' },
      { value: 'Plus Utilities', label: 'Plus Utilities' },
      { value: 'Plus Electric', label: 'Plus Electric' },
      { value: 'Triple Net', label: 'Triple Net', alternates: ['NNN'] },
    ],
  },
  {
    label: 'Asking Price ($)',
    key: 'askingPrice',
    validators: [
      {
        validate: 'regex_matches',
        regex: '^([\\d,]+)(\\.?[\\d]{0,2})(\\s*)[-~]?(\\s*)([\\d,]*)(\\.?[\\d]{0,2})$',
        error: 'This field only accepts a number, with up to 2 decimals. Ex: 18.50 or 18.50-22.00',
      },
    ],
  },
  {
    label: 'Asking Price Unit',
    key: 'askingPriceUnit',
    type: 'select',
    matchStrategy: 'exact',
    options: [
      { value: '$', label: '$' },
      { value: '$/SF', label: '$/SF' },
    ],
    validators: [
      {
        validate: 'required_with',
        fields: ['askingPrice'],
        error: 'Choose a value from the drop down list provided.',
      },
    ],
  },
  {
    label: 'Max Contiguous (SF)',
    key: 'maxContiguous',
    validators: [{
      validate: 'regex_matches',
      regex: '^[0-9,]{0,18}$',
      error: 'This field only accepts a whole number. Ex 70,000',
    }],
  },
  {
    label: 'Min Divisible (SF)',
    key: 'minDivisible',
    validators: [{
      validate: 'regex_matches',
      regex: '^[0-9,]{0,18}$',
      error: 'This field only accepts a whole number. Ex 70,000',
    }],
  },
  { label: 'Term', key: 'term' },
  {
    label: '# of Parking Spaces (availability)',
    key: 'parkingSpacesNum',
    validators: [{
      validate: 'regex_matches',
      regex: '^[0-9,]{0,7}$',
      error: 'This field only accepts a number',
    }],
  },
  {
    label: 'Virtual Tour',
    key: 'virtualTour',
    validators: [{
      validate: 'regex_matches',
      regex: '(?:https?)://(\\w+:?\\w*)?(\\S+)(:\\d+)?(/|/([\\w#!:.?+=&%!-/]))?',
      error: 'This field only accepts a https url format. Ex https://www.google.com/',
    }],
  },
];

export default PropertyFields;
