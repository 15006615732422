export const MEDIA_ACTION = {
  SET_MEDIA: 'SET_MEDIA',
  ADD_MEDIA: 'ADD_MEDIA',
  UPDATE_MEDIA: 'UPDATE_MEDIA',
  ADD_IMAGES: 'ADD_IMAGES',
  UPDATE_IMAGES: 'UPDATE_IMAGES',
  ADD_FLYERS: 'ADD_FLYERS',
  ADD_SITEPLANS: 'ADD_SITEPLANS',
  UPDATE_FLYERS: 'UPDATE_FLYERS',
  UPDATE_SITEPLANS: 'UPDATE_SITEPLANS',
  REMOVE_MEDIA: 'REMOVE_MEDIA',
};

export const SURVEY_ACTION = {
  SET_SURVEY: 'SET_SURVEY',
  SET_SURVEY_SUMMARY: 'SET_SURVEY_SUMMARY',
  UPDATE_SURVEY: 'UPDATE_SURVEY',
  REMOVE_SURVEY: 'REMOVE_SURVEY',
};

export const PROPERTY_ACTION = {
  SET_ALL_PROPERTY: 'SET_ALL_PROPERTY',
  UPDATE_PROPERTY: 'UPDATE_PROPERTY',
  REMOVE_PROPERTY: 'REMOVE_PROPERTY',
  REORDER_PROPERTY: 'REORDER_PROPERTY',
  SET_DRAWER_OPEN: 'SET_DRAWER_OPEN',
};

export const MARKETMAP_ACTION = {
  SET_MARKETMAP: 'SET_MARKETMAP',
  RESET_MARKETMAP: 'RESET_MARKETMAP',
  RECENTER_MARKETMAP: 'RECENTER_MARKETMAP',
  UPDATE_MARKETMAP_STYLE: 'UPDATE_MARKETMAP_STYLE',
  UPDATE_MARKETMAP_ACTIVE_INDEX: 'UPDATE_MARKETMAP_ACTIVE_INDEX',
  UPDATE_PDF_PAPER_SIZE: 'UPDATE_PDF_PAPER_SIZE',
  UPDATE_MARKETMAP_ZOOM_CENTER: 'UPDATE_MARKETMAP_ZOOM_CENTER',
  UPDATE_MARKETMAPLIST: 'UPDATE_MARKETMAPLIST',
  UPDATE_MARKETMAP_SIZE: 'UPDATE_MARKETMAP_SIZE',
  UPDATE_MARKETMAP_POTINS: 'UPDATE_MARKETMAP_POTINS',
  UPDATE_MARKETMAP_LEGEND_HEIGHT: 'UPDATE_MARKETMAP_LEGEND_HEIGHT',
};

export const ROOT_ACTION = {
  RESET: 'RESET',
};

export const ACTIONS = {
  ...ROOT_ACTION,
  ...MEDIA_ACTION,
  ...SURVEY_ACTION,
  ...MARKETMAP_ACTION,
  ...PROPERTY_ACTION,
};
