import React from 'react';
import {
  Theme, createStyles, makeStyles, Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}));
const CustomSection: React.FC<Props> = () => {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Typography variant="caption">
        Upload PDF files and set the order you want them to appear in the survey.
      </Typography>
    </section>
  );
};

export type Props = {};

CustomSection.displayName = 'CustomSection';
export default React.memo(CustomSection);
