import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

type Props = {};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: 'relative',
      width: '100%',
      height: '100%',
      backgroundColor: '#F5F7F7',
    },
    cbreLogo: {
      height: '11.93px',
      color: 'green',
      marginBottom: '54px',
      paddingBottom: '17px',
      paddingRight: '5px',
      position: 'absolute',
      left: '92.68%',
      right: '1.26%',
      top: '20%',
      bottom: '30.36%',
    },
    footerText: {
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '10px',
      paddingRight: '88px',
      fontFamily: 'Calibre-R',
      fontSize: '7.5px',
      lineHeight: '107%',
      letterSpacing: '0em',
      textAlign: 'left',
      color: 'rgba(26, 26, 26, 0.6)',
      height: '30px',
      left: '10px',
      top: '18px',
      borderRadius: 'nullpx',
      backgroundColor: '#F5F7F7',
    },
  }),
);

const Footer: React.FC<Props> = () => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const footerText = `© ${currentYear} CBRE, Inc. We obtained the information above from sources we believe to be reliable.
      However, we have not verified its accuracy and make no guarantee, warranty or representation
      about it. It is submitted subject to the possibility of errors, omissions, change of price,
      rental or other conditions, prior sale, lease or financing, or withdrawal without notice. We
      include projections, opinions, assumptions or estimates for example only, and they may not
      represent current or future performance of the property. You and your tax and legal advisors
      should conduct your own investigation of the property and transaction.`;
  return (
    <div className={classes.container}>
      <Box display="flex" className={classes.footerText}>
        {footerText}
      </Box>
      <Box p={1} className={classes.cbreLogo}>
        <img
          src="/assets/logo/CBRE_green.svg"
          alt="cbre logo"
        ></img>
      </Box>
    </div>
  );
};

export default Footer;
