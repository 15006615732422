import Analytics from '@cbreone/core-services/dist/services/analytic-service';
import { ErrorModal, Typography } from '@cbreone/core-ui';
import InlineEditTextField from '@cbreone/core-ui/dist/components/InlineEditTextField';
import { FlatfileButton } from '@flatfile/react';
import {
  AppBar,
  Button,
  CircularProgress,
  Drawer,
  Fab,
  Theme,
  Toolbar,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ApartmentIcon from '@material-ui/icons/Apartment';
import CloseIcon from '@material-ui/icons/CloseRounded';
import EditIcon from '@material-ui/icons/Edit';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { match } from 'react-router';
import { Dispatch } from 'redux';
import { RouteParams } from '../../routes/routes';

import { Props as SurveyAppProps } from '../../apps/SurveyApp';
import PropertyFields from '../../components/ManagePropertiesView/PropertyFields';
import SurveyForm from '../../components/SurveyForm';
import SurveyPDFPreview from '../../components/SurveyPDFPreview';
import useSurveyEditorAPI from './useSurveyEditorAPI';

import PublishShareModal from '../../components/PublishShareModal';
import { EnvironmentType } from '../../config';
import marketMapAction from '../../redux/actions/marketMapAction';
import propertyAction from '../../redux/actions/propertyAction';
import { MediaState } from '../../redux/types/dataTypes';
import { exportMarketMap } from '../../services/media-service';
import { Property, Survey } from '../../types';
import { getSFDCData } from '../../utilities';
import * as mapUtil from '../../utilities/map';
import { ff_fieldHooks, ff_onRecordChange, ff_onRecordInit, prepareImportPropertyAvailabilities } from '../../utilities/property';
import DrawerWrapper from './DrawerWrapper';

const drawerWidth = 400;
const appBarHeight = 64;
const appBarHeightMobile = 54;

const useStyles = ({ top = 0, right = 0, left = 0 } = {}) =>
  makeStyles((theme: Theme) =>
    createStyles({
      appBar: {
        background: theme.palette.grey[50],
        color: theme.palette.grey[800],
        marginTop: top,
        marginLeft: left,
        marginRight: right,
        width: `calc(100% - ${left}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
          duration: theme.transitions.duration.leavingScreen,
          easing: theme.transitions.easing.sharp,
        }),
      },
      toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingLeft: theme.spacing(1),
      },
      buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
      inlineEdit: {
        width: '500px',
        fontSize: '21px',
        marginLeft: theme.spacing(1),
      },
      leftDrawerPaper: {
        position: 'fixed',
        zIndex: theme.zIndex.appBar,
        marginTop: top + appBarHeightMobile,
        marginLeft: 0,
        height: `calc(100% - ${top + appBarHeightMobile}px)`,
        background: theme.palette.background.paper,
        overflowX: 'hidden',
        transition: theme.transitions.create(['margin', 'width'], {
          duration: left
            ? theme.transitions.duration.enteringScreen
            : theme.transitions.duration.leavingScreen,
          easing: theme.transitions.easing.sharp,
        }),
        [theme.breakpoints.up('sm')]: {
          zIndex: theme.zIndex.appBar - 1,
          marginTop: top + appBarHeight,
          height: `calc(100% - ${top + appBarHeight}px)`,
          width: drawerWidth,
          marginLeft: left,
          bottom: -(top + appBarHeight),
        },
      },
      leftDrawerContent: {
        flex: 1,
        overflow: 'scroll',
        overflowX: 'hidden',
      },
      leftDrawerFooter: {
        flex: 'none',
        padding: theme.spacing(2),
        paddingTop: '16px',
        paddingBottom: '16px',
        position: 'relative',
        boxShadow: theme.shadows[10],
        zIndex: 1,
      },
      content: {
        transition: theme.transitions.create(['margin'], {
          duration: theme.transitions.duration.leavingScreen,
          easing: theme.transitions.easing.sharp,
        }),
        overflow: 'auto',
        marginTop: top + appBarHeight,
        padding: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          marginLeft: drawerWidth,
        },
      },
      leftDrawerToggle: {
        position: 'fixed',
        right: '10px',
        left: 'auto',
        bottom: '10px',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['bottom'], {
          duration: theme.transitions.duration.leavingScreen,
          easing: theme.transitions.easing.sharp,
        }),
      },
      leftDrawerToggleOpen: {
        bottom: `calc(100% - ${top + appBarHeightMobile + 20}px)`,
      },
      drawer: {
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
          maxWidth: '1136px',
          width: '75%',
        },
      },
      tourDrawer: {
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
          width: '330px',
        },
      },
      publishShareModalSection: {
        padding: '0 10px',
        background: 'none',
        boxShadow: 'none',
      },
    }),
  );

const SurveyBuilder: React.FC<Props> = ({
  match: { params },
  position,
  mediaState,
}) => {
  const classes = useStyles(position)();
  const dispatch: Dispatch<any> = useDispatch();
  const surveyFormRef = useRef();
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const [leftDrawerOpen, setLeftDrawerOpen] = useState(true);
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
  const [tourPanelOp, setTourPanelOp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const { encodedstr } = params as RouteParams;
  const [isNewSurvey, setIsNewSurvey] = useState(true);
  const isInnerAppMode = window.location !== window.parent.location;
  useEffect(() => {
    setLeftDrawerOpen(isNotMobile);
  }, [isNotMobile]);

  useEffect(() => {
    const globalIndex = window as any;
    const { payload, client } = getSFDCData(encodedstr || '');
    if (globalIndex.Sfdc && globalIndex.Sfdc.canvas && globalIndex.Sfdc.canvas.client
      && client && client.oauthToken) {
      globalIndex.Sfdc.canvas.client.publish(client, {
        name: 'cbrebrokerhub.app_loaded',
        payload: {},
      });
    }

    if (payload && payload.surveyId) {
      setIsNewSurvey(false);
    } else {
      setIsNewSurvey(true);
    }
  }, [encodedstr]);

  const { id } = params as RouteParams;
  const resetMapStateCallback = (survey: Survey, resettingMap = true, patch = {}) => {
    mapUtil.default
      .getMapPointsWithImages(survey, mediaState, true)
      .then((points: any) => {
        const mapData = {
          id: survey.id,
          points,
          properties: survey.properties.filter(
            (prop: Property) => prop.isHidden === undefined || !prop.isHidden,
          ),
          resettingMap,
        };
        dispatch(marketMapAction.updateMarketMapPointsAction(mapData, patch));
      });
  };
  const surveyApi = useSurveyEditorAPI(id as string, resetMapStateCallback);
  const {
    addPropertyFiles,
    addSurveyContact,
    addSurveySection,
    deleteSurveyContact,
    deleteSurveySection,
    reorderSurveyAttribute,
    selectProperty,
    selectedProperty,
    survey,
    updateProperty,
    updateSurvey,
    updateSurveyContact,
    updateSurveySection,
    updatePropertyFieldRule,
    generateSurveyPDF,
    updateMedia,
  } = surveyApi;

  // { ds: open property-edit to fast UI development
  // useEffect(() => {
  //   // open ManageProperties UI
  //   setTourPanelOp(false);
  //   setRightDrawerOpen(true);
  //   dispatch(propertyAction.setDrawerOpenAction(true));

  //   // select some proeprty & open detail-UI
  //   if (0 < survey?.properties?.length) {
  //     const idx = 0
  //     const property = survey.properties[idx]
  //     selectProperty(property.id)
  //   }
  // }, [selectedProperty, survey?.properties?.length])
  // }

  const emptyUser = {
    userIdList: [],
    authorIdList: [],
  };

  if (survey.user === null) {
    survey.user = emptyUser;
  }

  if (!survey.id) {
    return null;
  }

  if (survey.origin === 'SiteIQ') {
    Analytics.send({
      event: 'userDataReady',
      user_id: localStorage.getItem('employeeId'),
      jobTitle: localStorage.getItem('position'),
      officeName: localStorage.getItem('office'),
      email: localStorage.getItem('email'),
      functUnitName: localStorage.getItem('functUnitName'),
      SiteIQUserID: survey.user.userIdList,
    });
  }

  const publishEvent = () => {
    const globalIndex = window as any;
    const { client } = getSFDCData(encodedstr || '');
    if (globalIndex.Sfdc && globalIndex.Sfdc.canvas && globalIndex.Sfdc.canvas.client
      && client && client.oauthToken) {
      globalIndex.Sfdc.canvas.client.publish(client, {
        name: 'cbrebrokerhub.exportPDF_cbreone',
        payload: {
          surveyId: survey.id,
        },
      });
      // console.log('Survey PDF Is Published');
    }
  };

  const handleGenerateSurveyPDF = () => {
    if (!loading) {
      setLoading(true);
      Analytics.send({ DownloadFromLocation: 'Builder' });
      const marketMapSection = survey.sections.find(
        (section) => section.name === 'market map',
      );
      if (`${marketMapSection?.isHidden}` === 'true') {
        generateSurveyPDF().then(() => publishEvent())
          .catch(() => setOpenErrorModal(true))
          .finally(() => setLoading(false));
      } else {
        const curMapSize = mapUtil.default.getMapEleBoxSize('marketmap', 0);
        let mStyle = mapUtil.default.getMapStyle(survey.marketMapStyle);
        if (mStyle === 'mapboxStreets') {
          mStyle = 'mapbox://styles/mapbox/streets-v10';
        }
        if (mStyle === 'mapboxGray') mStyle = 'mapbox://styles/mapbox/light-v9';
        if (mStyle === 'mapboxDark') mStyle = 'mapbox://styles/mapbox/dark-v9';

        const mapEle = document.getElementById('marketmap-0') as any;
        if (mapEle && typeof mapEle.getPoints === 'function') {
          // eslint-disable-next-line max-len
          Promise.all([mapUtil.default.getMapPointsOnly(survey), mapEle.getMapCenter(), mapEle.getMapZoom()]).then((data: any[]) => {
            const payload = {
              surveyId: survey.id,
              bamRequest: {
                map: {
                  mapStyle: mStyle,
                  mapCenter: data[1],
                  mapZoom: data[2],
                  mapPitch: 0,
                  mapBearing: 0,
                  mapWidth: curMapSize[0],
                  mapHeight: curMapSize[1],
                  fileType: 'png',
                },
                points: data && data[0] ? data[0].features : [],
              },
            };
            exportMarketMap(payload).then(() => {
              generateSurveyPDF().then(() => publishEvent())
                .catch(() => setOpenErrorModal(true))
                .finally(() => setLoading(false));
            }).catch((err) => {
              if (err.response === undefined) {
                setOpenErrorModal(true);
                setLoading(false);
              }
            });
          });
        }
      }
    }
  };

  const publishSurveyField = (key: string, value: any) => {
    const globalIndex = window as any;
    const { client } = getSFDCData(encodedstr || '');
    if (globalIndex?.Sfdc?.canvas?.client && client?.oauthToken) {
      globalIndex.Sfdc.canvas.client.publish(client, {
        name: 'cbrebrokerhub.updateSurvey_cbreone',
        payload: {
          surveyId: survey.id,
          fieldKey: key,
          fieldValue: value,
        },
      });
      // console.log(`Update Survey.${key} Is Published`);
    }
  };

  const handleSurveyNameChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    updateSurvey({ id: survey.id, [name]: value });
    publishSurveyField('surveyName', value);
  };

  const publishSurveyTitle = (e: any) => {
    // eslint-disable-next-line no-prototype-builtins
    if (e?.hasOwnProperty('coverTitle')) {
      publishSurveyField('surveyTitle', e.coverTitle || 'Property Survey');
    }
  };

  const clickFileUpload = () => {
    // Close the Drawer first since the drawer is impacting the flatfile dropdown.
    setRightDrawerOpen(false);
  };

  const updatePreviewPosition = (target: string, expanded: boolean) => {
    if (!expanded && position !== null) {
      window.location.href = `${encodeURI(window.location.origin)}${encodeURI(window.location.pathname)}#${target}`;
    }
  };

  const openTourPanel = () => {
    setTourPanelOp(true);
    setRightDrawerOpen(true);
  };

  return (
    <div className="survey-view">
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div>
            {/*
            <IconButton onClick={() => goHome()}>
              <ChevronLeftOutlinedIcon />
            </IconButton>
            */}
            <InlineEditTextField
              InputProps={{
                className: classes.inlineEdit,
              }}
              type="text"
              name="name"
              onChange={handleSurveyNameChange}
              value={survey.name}
              fallbackValue="Untitled Survey"
            />
          </div>
          <Button
            id="SC-OpenManageProperties"
            data-testid="OpenProperties"
            variant="contained"
            onClick={() => {
              setTourPanelOp(false);
              setRightDrawerOpen(true);
              dispatch(propertyAction.setDrawerOpenAction(true));
            }}
            startIcon={<ApartmentIcon />}
          >
            {`PROPERTIES (${survey.properties.length})`}
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor={isNotMobile ? 'left' : 'bottom'}
        open={isNotMobile ? true : leftDrawerOpen}
        variant="persistent"
        classes={{
          paper: classes.leftDrawerPaper,
        }}
      >
        <div className={classes.leftDrawerContent}>
          <SurveyForm
            ref={surveyFormRef}
            survey={survey}
            updateSurvey={(e) => {
              updateSurvey(e);
              publishSurveyTitle(e);
            }}
            onReorder={reorderSurveyAttribute}
            onSurveyContactAdd={addSurveyContact}
            onSurveyContactDelete={deleteSurveyContact}
            onSurveyContactUpdate={updateSurveyContact}
            onUpdateSurveySection={(e) => {
              const { surveySectionId } = e;
              updateSurveySection(e);
              const section = survey.sections.find((item) => item.surveySectionId === surveySectionId);
              if (section?.name === 'tour schedule') {
                publishSurveyField('tourBook', section.isHidden);
              }
            }}
            onAddSurveySection={addSurveySection}
            onDeleteSurveySection={deleteSurveySection}
            onUpdatePropertyFieldRule={updatePropertyFieldRule}
            onUpdateMedia={updateMedia}
            onUpdatePreviewPosition={updatePreviewPosition}
            onOpenTourPanel={openTourPanel}
            params={params}
            surveyApi={surveyApi}
          />
        </div>
        <div className={classes.leftDrawerFooter}>
          <PublishShareModal
            survey={survey}
            title={`Share ${survey.name}`}
            id="SC-ShareSurvey"
            testId="share-survey"
            shareButtonDisabled={loading}
          >

            <div className={classes.publishShareModalSection}>
              {!isNewSurvey && <Typography style={{ marginBottom: '16px', fontSize: '12px' }} variant='body2'>Your interactive deliverable reflects the latest changes.</Typography>}
              <Button
                id="SC-DownloadSurvey"
                data-testid="download-survey-pdf"
                disabled={loading}
                variant="contained"
                fullWidth
                onClick={handleGenerateSurveyPDF}
              >
                {`Generate ${isNewSurvey ? 'Deliverables' : 'new PDF link'} And Close`}
              </Button>
            </div>
            {/*
            <br></br>
            <div className={classes.publishShareModalSection}>
              <p>Copy link for interactive password-protected website.</p>
              <Button
                id="SC-InteractiveSurvey"
                data-testid="copylink-survey-pdf"
                disabled={linkCopied}
                variant="contained"
                fullWidth
                onClick={() => {
                  handleCopyLinkSurveyPDF();
                  sendCopyLinkData();
                }}
              >
                {linkCopied ? 'Copied' : 'Copy Link'}
              </Button>
            </div> */}
          </PublishShareModal>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </Drawer>
      <main className={classes.content} style={{ height: 'auto' }}>
        <SurveyPDFPreview
          survey={survey}
          updateSurvey={updateSurvey}
          onPropertySelect={(e) => {
            setTourPanelOp(false);
            setRightDrawerOpen(true);
            selectProperty(e);
          }}
          onUpdateProperty={updateProperty}
          updatePropertyCoordinates={updateProperty}
        />
      </main>
      {!isNotMobile && (
        <Fab
          color="default"
          aria-label="toggle"
          className={`${classes.leftDrawerToggle} ${leftDrawerOpen && classes.leftDrawerToggleOpen
            }`}
          onClick={() => setLeftDrawerOpen(!leftDrawerOpen)}
        >
          {leftDrawerOpen ? <CloseIcon /> : <EditIcon />}
        </Fab>
      )}
      <DrawerWrapper
        variant="temporary"
        anchor="right"
        open={rightDrawerOpen}
        hideBackdrop={!selectedProperty && tourPanelOp}
        onClose={() => {
          if (selectedProperty) {
            selectProperty();
          }
          setRightDrawerOpen(false);
          dispatch(propertyAction.setDrawerOpenAction(false));
        }}
        classes={{ paperAnchorRight: tourPanelOp ? classes.tourDrawer : classes.drawer }}
        onClickFileupload={clickFileUpload}
        showManagePropertyView={!selectedProperty && !tourPanelOp}
        showTourPanel={!selectedProperty && tourPanelOp}
        onDismiss={() => {
          setRightDrawerOpen(false);
          dispatch(propertyAction.setDrawerOpenAction(false));
        }}
        params={params}
        surveyApi={surveyApi}
      />

      <FlatfileButton
        className="hiddenFlatfileBtn"
        customer={{ userId: '12345' }}
        licenseKey="959d724b-b0c3-4ca5-92c5-2b5efa74813f"
        preload={false}
        settings={{
          allowCustom: true,
          managed: true,
          type: 'Property',
          fields: PropertyFields,
          devMode: EnvironmentType !== 'production',
        }}
        fieldHooks={ff_fieldHooks}
        onRecordInit={ff_onRecordInit}
        onRecordChange={ff_onRecordChange}
        onData={async (res) => {
          const ffdata = prepareImportPropertyAvailabilities(res);

          const data = await addPropertyFiles({
            ...ffdata,
            surveyId: survey.id,
            name: localStorage.getItem('displayName') || 'Unknown User',
          });
          if (data && data.id) {
            resetMapStateCallback(data);
          }

          // reopen the drawer once the data is processed.
          setRightDrawerOpen(true);
          return 'Done!';
        }}
        onCancel={() => {
          setRightDrawerOpen(true);
        }}
      />
      {openErrorModal && (
        <ErrorModal handleErrorModalClose={() => setOpenErrorModal(false)} />
      )}
    </div>
  );
};

export type Props = {
  match: match;
  position: SurveyAppProps['position'];
  mediaState?: MediaState;
};

const mapStateToProps = (state: any, ownProps: any) => ({
  mediaState: state.media,
  ...ownProps,
});

SurveyBuilder.displayName = 'SurveyBuilder';
export default React.memo(connect(mapStateToProps)(SurveyBuilder));
