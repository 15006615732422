import { mediaRootURL, mulesoftProxyUrl } from '../../config';

export function isSFurl(url: string) {
  if (!mulesoftProxyUrl) {
    return false;
  }
  return url.includes(mulesoftProxyUrl);
}

export function isS3url(url: string) {
  if (!mediaRootURL) {
    return false;
  }
  return url.includes(mediaRootURL);
}
