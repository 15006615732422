import { combineReducers } from 'redux';
import marketMapReducer from './marketMapReducer';
import mediaReducer from './mediaFileReducer';
import propertyReducer from './propertyReducer';
import surveyReducer from './surveyReducer';

const appReducer = combineReducers({
  media: mediaReducer,
  survey: surveyReducer,
  map: marketMapReducer,
  properties: propertyReducer,
});

export default appReducer;
