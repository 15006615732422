import React from 'react';
import TextField from '@cbreone/core-ui/dist/components/DebouncedTextField';
import { getPropertyValueForDisplay } from '../../utilities/property';
import { Property } from '../../types';

const FormCellRenderer: React.FC<CellProps> = ({
  name,
  label,
  property,
  handlePropertyUpdate,
}) => {
  const [isActive, setIsActive] = React.useState(false);

  // const value = property[name] || ''
  return (
    <TextField
      value={ getPropertyValueForDisplay(name, property)}
      onChange={(e) => {
        if (isActive) {
          handlePropertyUpdate(e);
        }
      }}
      fullWidth
      name={name}
      label={label}
      debounceTime={400}
      onFocus={() => setIsActive(true)}
      onBlur={(e) => {
        handlePropertyUpdate(e);
        setIsActive(false);
      }}
    />
  );
};

type CellProps = {
  name: string;
  label: string;
  property: Property & { [x: string]: any };
  handlePropertyUpdate: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
};

FormCellRenderer.displayName = 'FormCellRenderer';
export default FormCellRenderer;
