import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { match } from 'react-router';
import store from '../../redux/store';
import { PreviewRouteParams } from '../../routes/routes';
import { Survey } from '../../types';
import * as SurveyService from '../../services/survey-service';
import SurveySectionPreview from '../SurveySectionPreview';

export type Props = {
  match: match;
};

const SurveySectionPageWrapper: React.FC<Props> = ({ match: { params } }) => {
  const [survey, setSurvey] = useState({} as Survey);
  const { id, section, propertyId, index } = params as PreviewRouteParams;

  useEffect(() => {
    if (id) {
      SurveyService.getSurvey(id).then((res: any) => {
        setSurvey(res);
      });
    }
  }, [id]);

  if (!survey.id) {
    return null;
  }
  return (
    <Provider store={store}>
      <SurveySectionPreview
        survey={survey}
        section={section}
        propertyId={propertyId}
        floorPlanIndex={index}
      />
    </Provider>
  );
};

SurveySectionPageWrapper.displayName = 'SurveySectionPageWrapper';
export default SurveySectionPageWrapper;
