import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@cbreone/core-ui/dist/components/DebouncedTextField';
import { Property } from '../../types';
import FormCellRenderer from './FormCellRenderer';

const useStyles = makeStyles((theme: Theme) => ({
  v_spacer: {
    height: theme.spacing(3),
  },
}));

const AdditionalInfoForm: React.FC<Props> = ({
  property,
  handlePropertyUpdate,
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6">Additional Information</Typography>
      <div className={classes.v_spacer} />

      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <FormCellRenderer
            property={property}
            name="vacantSpace"
            label="Vacant Space (SF)"
            handlePropertyUpdate={handlePropertyUpdate}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormCellRenderer
            property={property}
            name="vacantPercentage"
            label="% Vacant"
            handlePropertyUpdate={handlePropertyUpdate}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormCellRenderer
            property={property}
            name="opex"
            label="OPEX"
            handlePropertyUpdate={handlePropertyUpdate}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={property.owner}
            onChange={handlePropertyUpdate}
            onBlur={handlePropertyUpdate}
            fullWidth
            name="owner"
            label="Property Owner"
            debounceTime={100}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={property.majorTenants}
            onChange={handlePropertyUpdate}
            onBlur={handlePropertyUpdate}
            fullWidth
            name="majorTenants"
            label="Major Tenants"
            debounceTime={100}
          />
        </Grid>
        {/* <Grid item sm={6} xs={12} /> */}
        <Grid item sm={6} xs={12}>
          <TextField
            multiline
            value={property.amenities}
            onChange={handlePropertyUpdate}
            onBlur={handlePropertyUpdate}
            // rows={3}
            fullWidth
            name="amenities"
            label="Amenities"
            debounceTime={100}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            multiline
            value={property.comments}
            onChange={handlePropertyUpdate}
            onBlur={handlePropertyUpdate}
            rows={3}
            fullWidth
            name="comments"
            label="Comments"
            debounceTime={100}
          />
        </Grid>
      </Grid>
    </>
  );
};

export type Props = {
  property: Property;
  handlePropertyUpdate: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
};

AdditionalInfoForm.displayName = 'AdditionalInfoForm';
export default React.memo(AdditionalInfoForm);
