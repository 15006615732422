import createQueryString, { QueryParamObj } from '@cbreone/utilities/dist/createQueryString';
import { axios } from '../internal-lib';
import { propertyServiceBaseURL, surveyServiceBaseURL } from '../config';
import {
  Survey,
  SurveyPost, SurveyPatch, JSONPatch, ReorderPatch, PropertyFiles, BHPropertyFiles, Property,
} from '../types';
import onSuccess from './onSuccess';
import { getProperties } from './property-service';
import { getCustomFields } from './survey-customField-service';
import { getPropertyFieldRule } from './survey-propertyField-rule-service';

export const getSurvey = (id: string) => new Promise((resolve, reject) => {
  Promise.all([
    getSCSurvey(id),
    getProperties(`/externalId/${id}/ownerType/SC_SURVEY`),
    getCustomFields(`/externalId/${id}/ownerType/SC_SURVEY`),
    getPropertyFieldRule(id),
  ]).then((data) => {
    const survey = data[0] || {};
    const properties = data[1] || [];
    const customFields = data[2] || [];
    const propertyFieldRule = data[3] || [];
    resolve({
      ...survey,
      customFields,
      surveyPropertyFieldRule: propertyFieldRule.propertyAttributes,
      surveyPropertyAvailabilityRule: propertyFieldRule.availabilityAttributes,
      properties: properties.map((p: Property) => ({
        ...p,
        isHidden: p.status === 'INACTIVE',
      })),
    },
    );
  }).catch(() => {
    reject();
  });
});

export const updateSurvey = (surveyReq: SurveyPatch) => new Promise((resolve, reject) => {
  updateSCSurvey(surveyReq).then((survey: any) => {
    Promise.all([
      getProperties(`/externalId/${survey.id}/ownerType/SC_SURVEY`),
      getCustomFields(`/externalId/${survey.id}/ownerType/SC_SURVEY`),
      getPropertyFieldRule(survey.id),
    ]).then((data) => {
      const properties = data[0] || [];
      const customFields = data[1] || [];
      const propertyFieldRule = data[2] || [];
      resolve({
        ...survey,
        customFields,
        surveyPropertyFieldRule: propertyFieldRule.propertyAttributes,
        surveyPropertyAvailabilityRule: propertyFieldRule.availabilityAttributes,
        updatedOn: new Date(Date.now()).toISOString(),
        properties: properties.map((p: Property) => ({
          ...p,
          isHidden: p.status === 'INACTIVE',
        })),
      },
      );
    }).catch(() => {
      reject();
    });
  }).catch(() => {
    reject();
  });
});

export const addProperties = (post: PropertyFiles) => new Promise((resolve, reject) => {
  addBHProperties({
    ...post,
    ownerType: 'SC_SURVEY',
    ownerId: post.surveyId,
  }).then((properties) => {
    Promise.all([
      getSurvey(post.surveyId),
      getProperties(`/externalId/${post.surveyId}/ownerType/SC_SURVEY`),
      getCustomFields(`/externalId/${post.surveyId}/ownerType/SC_SURVEY`),
      getPropertyFieldRule(post.surveyId),
    ]).then((data: any) => {
      if (data && Array.isArray(data)) {
        const currentProps = data[1].map((p: Property) => ({
          ...p,
          isHidden: p.status === 'INACTIVE',
        }));
        const customFields = data[2] || [];
        const propertyFieldRule = data[3] || [];
        resolve({
          ...data[0],
          customFields,
          surveyPropertyFieldRule: propertyFieldRule.propertyAttributes,
          surveyPropertyAvailabilityRule: propertyFieldRule.availabilityAttributes,
          updatedOn: new Date(Date.now()).toISOString(),
          properties: [...currentProps],
        });
      }
    }).catch(() => {
      reject();
    });
  }).catch(() => {
    reject();
  });
});

export const addSurvey = (survey: SurveyPost) => axios.post<Survey>(`${surveyServiceBaseURL}survey/`, survey).then(onSuccess);

export const copySurvey = (surveyId: string) => axios.get<Survey>(`${surveyServiceBaseURL}survey/copy/${surveyId}`).then(onSuccess);

export const getSurveys = (params?: QueryParamObj) => axios.get<{ surveys: Survey[] }>(`${surveyServiceBaseURL}survey${createQueryString(params)}`).then(onSuccess);

export const getSCSurvey = (id: string) => axios.get<Survey>(`${surveyServiceBaseURL}survey/${id}`).then(onSuccess);

export const updateSCSurvey = (survey: SurveyPatch) => axios.patch<Survey>(`${surveyServiceBaseURL}survey`, survey).then(onSuccess);

export const deleteSurvey = (id: string) => axios.delete<string>(`${surveyServiceBaseURL}survey/${id}`).then(onSuccess);

export const jsonPatch = (id: string, patch: JSONPatch) => axios.patch<Survey>(`${surveyServiceBaseURL}survey/${id}`, patch).then(onSuccess);

export const reorderPatch = (path: string, patch: ReorderPatch) => (
  axios.patch<Survey>(`${surveyServiceBaseURL}${path}`, patch).then(onSuccess)
);

export const addBHProperties = (post: BHPropertyFiles) => axios.post<Property[]>(`${propertyServiceBaseURL}propertyFile/create`, post).then(onSuccess);

export const eventAPI = (id: string) => axios.post<Survey>(`${surveyServiceBaseURL}event/survey/updated/${id}`).then(onSuccess);
