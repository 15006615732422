import React from 'react';
import { createStyles, makeStyles, SvgIcon } from '@material-ui/core';
import { Theme } from '@emotion/react';

type Props = {
  stroke: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '20.02px',
      width: '20px',
    },
  },
  ));

const MapStarIcon: React.FC<Props> = ({ stroke }) => {
  const classes = useStyles();
  return (
        <SvgIcon
            className={classes.root}
            width="24" height="24"
            viewBox="0 0 24 24"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="12" cy="12" r="12"
                fill={stroke} />
            <path d="M12 16.0168L16.944 19.0008L15.632 13.3768L20 9.59278L14.248 9.10478L12 3.80078L9.752 9.10478L4 9.59278L8.368 13.3768L7.056 19.0008L12 16.0168Z" fill="white" />
        </SvgIcon>
  );
};

MapStarIcon.displayName = 'MapStarIcon';
export default MapStarIcon;
