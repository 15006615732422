import { Typography } from '@cbreone/core-ui';
import {
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import DirectionsOutlined from '@material-ui/icons/DirectionsOutlined';
import React, {
  useEffect,
  useState,
} from 'react';
import { PDFPaperSize } from '../../data/constants';
import { Property, PropertyFieldRule, Survey } from '../../types';
import * as mapUtil from '../../utilities/map';
import Footer from '../Footer';
import Header from '../PDFCommon';
import SurveyPDFPage from '../SurveyPDFPage';

const modeMapper = {
  potrait: 19,
  landscape: 14,
};

const useStyles = makeStyles((theme) =>
  createStyles({
    tableCellStopNumber: {
      borderBottom: 'none',
      fontSize: '12px !important',
      padding: '4px 6px 4px 6px',
      whiteSpace: 'pre-line',
      width: '40px',
      wordWrap: 'break-word',
      '&:first-child': {
        paddingLeft: '18px',
      },
      '&:last-child': {
        paddingRight: '18px',
      },
    },
    pinNumbers: {
      color: 'white',
      border: '2px solid white',
      borderRadius: '50%',
      width: '23px',
      height: '23px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 8px',
    },
    tableCellRoot1: {
      borderBottom: 'none',
      fontSize: '12px',
      padding: '4px 6px 4px 6px',
      whiteSpace: 'pre-line',
      wordWrap: 'break-word',
      width: '50px',
      '&:first-child': {
        paddingLeft: '18px',
      },
      '&:last-child': {
        paddingRight: '18px',
      },
    },
    tableCellRoot: {
      borderBottom: 'none',
      fontSize: '12px',
      padding: '4px 6px 4px 6px',
      whiteSpace: 'pre-line',
      wordWrap: 'break-word',
      '&:first-child': {
        paddingLeft: '18px',
      },
      '&:last-child': {
        paddingRight: '18px',
      },
    },
    tableCellLink: {
      borderBottom: 'none',
      cursor: 'pointer',
      width: '100px',
      color: '#003F2D',
      fontSize: '12px',
      padding: '4px 6px 4px 6px',
      whiteSpace: 'pre-line',
      wordWrap: 'break-word',
      '&:first-child': {
        paddingLeft: '18px',
      },
      '&:last-child': {
        paddingRight: '18px',
      },
    },
    container: {
      boxShadow: 'none',
      borderRadius: '0px',
    },
    additionalContainer: {
      boxShadow: 'none',
      borderRadius: '0px',
      paddingTop: '24px',
    },
    imgCell: {
      paddingTop: '16px',
    },
    oddRow: {
      background: theme.palette.common.white,
    },
    evenRow: {
      background: theme.palette.grey['100'],
    },
    nameCell: {
      fontWeight: 400,
      fontSize: '14px !important',
    },
    captionText: {
      color: 'rgba(26, 26, 26, 0.6)',
      fontSize: '12px',
      fontWeight: 400,
    },
    pageContainerLandscapeNoHeaderHeight: {
      height: '8in',
    },
    pageContainerLandscapeHeaderHeight: {
      height: '7in',
    },
    pageContainePortraitNoHeaderHeight: {
      height: '9.5in',
    },
    pageContainerPortraitHeaderHeight: {
      height: '10.5in',
    },
    portraitTxtStyle: {
      fontSize: '12px',
    },
    landscapeTxtStyle: {
      fontSize: '14px',
    },
    footer: {
      width: '100%',
      backgroundColor: '#cccccc',
      height: '0.5in',
    },
    link: {
      fontStyle: 'normal',
      fontFamily: 'Calibre-R',
      letterSpacing: '0.1px',
      wordWrap: 'break-word',
    },
    emptyStateMessage: {
      margin: '20px',
    },
    goLinkFont: {
      fontSize: '16px',
      fontWeight: 500,
      color: '#003F2D',
    },
  }),
);

const TourScheduleSheet: React.FC<Props> = ({
  firstPage,
  pageMode,
  survey,
}) => {
  const classes = useStyles();
  const { getIconData } = mapUtil.default;
  let curHeight = 94; // header height
  const landscapeMode = survey.pdfPaperSize === PDFPaperSize.Landscape;
  const maxPageHeight = landscapeMode ? 710 : 950; // pdf page height
  let triggerCount = 0;
  const [isLoading, setIsLoading] = useState(true);
  const [propertyList, setPropertyList] = useState<Array<Property>>([]);

  useEffect(() => {
    setPropertyList(survey.properties.filter((property: Property) =>
      (!property.isHidden === undefined || !property.isHidden) && !property.isCurrentLocation));
  }, [survey.properties]);

  const openGoogleMap = (
    latitude: string,
    longitude: string,
    address1: string,
    city: string,
    state: string,
    postalCode: string,
  ) => {
    const googleUrl = `https://www.google.com/maps/dir//${address1},${city},+${state}+${postalCode}/@${latitude},${longitude}`;
    return <Link href={`${googleUrl}`} target='_blank'><DirectionsOutlined /></Link>;
  };

  const extraFieldsPagesTmp: any[][] = [];
  let currentPageIdx = 0;
  useEffect(() => {
    setIsLoading(true);
  }, [survey]);

  const addToRefs = (el: HTMLTableRowElement, row?: PropertyFieldRule) => {
    if (!isLoading) {
      return;
    }
    triggerCount += 1;
    if (el) {
      const index = Math.trunc(
        (el.offsetHeight + curHeight + 24) / maxPageHeight,
      );
      if (row) {
        // the first row of the next page
        if (index > currentPageIdx) {
          // calculate the remaining space of the first page.
          curHeight = index * maxPageHeight;
        }

        const currentLoop: any[] = extraFieldsPagesTmp[index] || [];
        extraFieldsPagesTmp[index] = [...currentLoop, row];
        currentPageIdx = index;
      }
      if (index <= currentPageIdx) {
        curHeight += el.offsetHeight;
      }
    }
  };

  const pageHeightClassName = landscapeMode
    ? classes.pageContainerLandscapeHeaderHeight
    : classes.pageContainePortraitNoHeaderHeight;
  const textClass = landscapeMode
    ? classes.landscapeTxtStyle
    : classes.portraitTxtStyle;

  const getCurrentDate = () => {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const jsDate = new Date();

    const day = days[jsDate.getDay()];
    const month = months[jsDate.getMonth()];
    const date = jsDate.getDate();
    const year = jsDate.getFullYear();

    return `${day} ${month} ${date}, ${year}`;
  };

  const Surveypage: React.FC<SurveyPageProps> = ({ propList, index }) => (

    <SurveyPDFPage mode={pageMode} paperSize={survey.pdfPaperSize}>
      <Header
        title={
          firstPage
            ? `Tour Schedule - ${survey.tourDetails || getCurrentDate()
            }`
            : ''
        }
      />
      <TableContainer
        className={`${classes.container} ${pageHeightClassName}`}
        component={Paper}
      >
        <Table size="medium">
          <TableBody>
            {!propList?.length ? (
              <Typography
                variant="body2"
                className={classes.emptyStateMessage}
              >
                Your schedule will populate here with visible properties
              </Typography>
            ) : (
              <React.Fragment>
                {propList?.map((scheduleItem: Property, order: number) => (
                  <TableRow
                    key={`${scheduleItem.id}_order_${order}`}
                    className={`${order % 2 ? classes.oddRow : classes.evenRow
                      }`}
                    style={{ height: '46px' }}
                    ref={(node) => (node ? addToRefs(node) : null)}
                  >
                    <TableCell
                      classes={{ root: classes.tableCellRoot1 }}
                      scope="row"
                    >
                      <Typography
                        variant="body2"
                        className={`${classes.pinNumbers} ${textClass}`}
                        style={{ backgroundColor: scheduleItem.isClientShortlist ? '#003F2D ' : getIconData(scheduleItem).iconColor }}
                      >
                        {order + (index * view) + 1}
                      </Typography>
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableCellRoot }}
                      scope="row"
                    >
                      <Typography
                        variant="body2"
                        className={`${classes.nameCell} ${textClass}`}
                      >
                        {scheduleItem.name || '—'} {scheduleItem.address1 && scheduleItem.address1 !== scheduleItem.name && '-'} {(scheduleItem.address1 && scheduleItem.address1 !== scheduleItem.name && scheduleItem.address1) || ''}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.captionText}
                      >
                        {scheduleItem.stopDetails || ''}
                      </Typography>
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableCellLink }}
                      scope="row"
                    >
                      <Grid
                        container
                        justifyContent="flex-end"
                      >
                        {
                          openGoogleMap(
                            scheduleItem.latitude,
                            scheduleItem.longitude,
                            scheduleItem.address1,
                            scheduleItem.city,
                            scheduleItem.state,
                            scheduleItem.postalCode,
                          )
                        }
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.footer}>
        <Footer></Footer>
      </div>
    </SurveyPDFPage>);
  const tsPages = [];
  const view = landscapeMode ? modeMapper.landscape : modeMapper.potrait;
  if (propertyList.length > view) {
    for (let count = 0; count < Math.ceil(propertyList.length / view); count += 1) {
      tsPages.push(propertyList.slice((count * view), ((count + 1) * view)));
    }
  } else {
    tsPages.push(propertyList);
  }
  return (
    <>
      {
        tsPages.map((page, index) => <Surveypage propList={page} key={`surveypage_tour_${index}`} index={index} />)
      }    </>
  );
};

export type Props = {
  firstPage: boolean;
  pageMode?: string;
  survey: Survey;
};

TourScheduleSheet.displayName = 'TourScheduleSheet';
export default TourScheduleSheet;

export type SurveyPageProps = {
  propList: Array<Property>,
  index: number;
};
