import { any } from 'prop-types';
import { propertyGetCollateralsURL, propertyServiceBaseURL } from '../config';
import { axios } from '../internal-lib';
import {
  Property,
  PropertyCopy,
  PropertyDelete,
  PropertyPatch,
  PropertyPost,
  PropertyReorderPatch,
  PropertyStatusPatch,
} from '../types';
import { BHPropertyCustomFieldValuePatch } from '../types/bh-property-custom-field';
import onSuccess from './onSuccess';
import { getCustomFields } from './survey-customField-service';
import { getPropertyFieldRule } from './survey-propertyField-rule-service';
import { getSCSurvey } from './survey-service';

export const reorderProperty = (post: PropertyReorderPatch) =>
  new Promise((resolve, reject) => {
    reorderSCProperty(post)
      .then((properties) => {
        Promise.all([
          getSCSurvey(post.ownerId),
          getCustomFields(`/externalId/${post.ownerId}/ownerType/SC_SURVEY`),
          getPropertyFieldRule(post.ownerId),
        ]).then((data: any) => {
          if (data && Array.isArray(data)) {
            const customFields = data[1] || [];
            const propertyFieldRule = data[2] || [];
            resolve({
              ...data[0],
              customFields,
              surveyPropertyFieldRule: propertyFieldRule.propertyAttributes,
              surveyPropertyAvailabilityRule: propertyFieldRule.availabilityAttributes,
              updatedOn: new Date(Date.now()).toISOString(),
              properties: properties.map((p: Property) => ({
                ...p,
                isHidden: p.status === 'INACTIVE',
              })),
            });
          }
        }).catch(() => {
          reject();
        });
      })
      .catch(() => {
        reject();
      });
  });

export const addProperty = (property: PropertyPost) =>
  axios
    .post<Property>(`${propertyServiceBaseURL}property/`, property)
    .then(onSuccess);

export const getProperty = (id: string) =>
  axios
    .get<Property>(`${propertyServiceBaseURL}property/${id}`)
    .then(onSuccess);

export const getProperties = (params?: string) =>
  axios
    .get<Property[]>(`${propertyServiceBaseURL}property${params}`)
    .then(onSuccess);

export const updateProperty = (property: PropertyPatch) =>
  axios
    .patch<Property>(`${propertyServiceBaseURL}property`, property)
    .then(onSuccess);

export const updatePropertyStatus = (property: PropertyStatusPatch) =>
  axios
    .patch<Property>(`${propertyServiceBaseURL}property/status`, property)
    .then(onSuccess);

export const updatePropertyCustomField = (
  request: BHPropertyCustomFieldValuePatch,
) =>
  axios
    .patch<Property>(`${propertyServiceBaseURL}property/${request.propertyId}/customField`, request.customField)
    .then(onSuccess);

export const deleteProperty = (propertyDelete: PropertyDelete) =>
  axios
    .delete<string>(`${propertyServiceBaseURL}property`, {
      data: propertyDelete,
    })
    .then(onSuccess);

export const deleteAvailabilityService = (availabilityId: string) =>
  axios
    .delete<string>(`${propertyServiceBaseURL}propertyAvailability/availabilityId/${availabilityId}`)
    .then(onSuccess);

export const reorderSCProperty = (propertyReorder: PropertyReorderPatch) =>
  axios
    .patch<Property[]>(
      `${propertyServiceBaseURL}property/reorder`,
      propertyReorder,
    )
    .then(onSuccess);

export const getPropertyAPIState = () => axios.get<any>(`${propertyServiceBaseURL}property`).then(onSuccess);

export const copyProperty = (surveyId: string, properties: any) => axios.post<any>(`${propertyServiceBaseURL}property/copyTo/externalId/${surveyId}/ownerType/SC_SURVEY`, properties, {
  headers: {
    'Content-Type': 'application/json',
  }
}).then(onSuccess);

export const copyProperties = (propertyCopy: PropertyCopy) => axios.post<any>(`${propertyServiceBaseURL}property/copyProperties`, propertyCopy).then(onSuccess);

export function getCollaterals({
  opportunityId = '', companyName = '', properties = [], projectContacts = [],
  client = { oauthToken: '' },

  onSuccess = (result: string) => { },
  onFailure = () => { },
}) {
  const body = JSON.stringify({
    opportunityId,
    companyName,
    properties,
    projectContacts,
  });

  // console.log('ds: getCollateral ajax call');

  const xhr = new XMLHttpRequest();
  xhr.onreadystatechange = () => {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        const response = xhr.responseText;
        // console.log('ds: getCollateral ajax response', response);

        if (onSuccess) {
          onSuccess(response);
        }
      } else {
        // eslint-disable-next-line no-console
        console.log('ds: getCollateral ajax fail');
        if (onFailure) {
          onFailure();
        }
      }
    }
  };
  xhr.open('POST', propertyGetCollateralsURL, true);

  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.setRequestHeader('Accept', 'application/json');
  xhr.setRequestHeader('Authorization', `OAuth ${client.oauthToken}`);

  xhr.send(body);
}
