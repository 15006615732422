import React from 'react';
import {
  Theme,
  createStyles,
  makeStyles,
  Button,
  Popover,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from '@material-ui/core';
import MapSettingMenuIcon from '../Icons/MapSettingMenuIcon';
import {
  BAM_MAP_STYLES,
  BAM_MAP_STYLE_LIST,
  BAM_MAP_STYLE_DISPLAY_NAMES,
} from '../constants/MapStyles';
import { Survey } from '../../types';
import { MarketMapStatePatch } from '../../redux/types/dataTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-block',
      position: 'absolute',
      zIndex: 1,
      right: 11,
      bottom: 12,
    },
    settingMenu: {
      background: 'white',
      boxShadow: '0 0 0 2px rgb(0 0 0 / 10%)',
      borderRadius: 4,
      height: 30,
      width: 30,
      padding: 4,
      cursor: 'pointer',
      display: 'block',
      minWidth: 30,
      '&:hover': {
        background: '#f1f0f0',
      },
    },
    mapStyleClass: {
      width: 160,
      height: 240,
    },
    mapStyleTitle: {
      margin: '12px 16px 10px 16px',
      lineHeight: 1.25,
    },
    mapStyleList: {
      height: 200,
      paddingLeft: '16px',
      overflowY: 'auto',
      '& label': {
        marginRight: 0,
        height: 40,
        '& span': {
          fontSize: '0.8rem',
          lineHeight: 1,
        },
        '& svg': {
          fontSize: '1.2rem',
        },
      },
    },
  }));

const MapStyles: React.FC<Props> = (props) => {
  const { onMapStyleChange, survey } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(survey.marketMapStyle
     && BAM_MAP_STYLE_LIST.includes(survey.marketMapStyle) ?
    survey.marketMapStyle : BAM_MAP_STYLES.cbreLight);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    onMapStyleChange((event.target as HTMLInputElement).value);
  };
  const [left, setLeft] = React.useState(0);
  const [top, setTop] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const mapStyles = BAM_MAP_STYLES;
  const displayNames = BAM_MAP_STYLE_DISPLAY_NAMES;
  const toggleStylessVisible = (event: React.MouseEvent<HTMLButtonElement>) => {
    const currentTargetRect = event.currentTarget.getBoundingClientRect();
    setLeft(currentTargetRect.left - 50);
    setTop(currentTargetRect.top - 260);
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
    setLeft(0);
    setTop(0);
  };
  return (
    <section className={classes.root}>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorReference="anchorPosition"
        onClose={handleClose}
        anchorPosition={{ top, left }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.mapStyleClass}>
          <Typography className={classes.mapStyleTitle}>
            Map Style
          </Typography>
          <div className={classes.mapStyleList}>
            <RadioGroup value={value} onChange={handleChange}>
              {Object.keys(mapStyles).map((style) => (
                <FormControlLabel
                  key={style}
                  value={style}
                  id={`SC-SelectMapStyle-${displayNames[style]}`}
                  control={<Radio />}
                  label={displayNames[style]}
                />
              ))}
            </RadioGroup>
          </div>
        </div>
      </Popover>
      <Button className={classes.settingMenu} onClick={toggleStylessVisible}>
        <MapSettingMenuIcon height={24} width={24}></MapSettingMenuIcon>
      </Button>
    </section>
  );
};

type Props = {
  onMapStyleChange: (e: any) => void;
  survey: Survey | MarketMapStatePatch,
};

MapStyles.displayName = 'MapStyles';
export default MapStyles;
