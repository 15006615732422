import React from 'react';
import { Typography } from '@material-ui/core';
import { SurveySection } from '../../types';

const CustomSectionPreview: React.FC<Props> = ({ section, order, anchore }) => (
  <div className="custom-section-preview" id={`${order}`} style={anchore}>
    <Typography variant="h5">{section.name}</Typography>
  </div>
);

export type Props = {
  section: SurveySection;
  order: string;
  anchore: object;
};

CustomSectionPreview.displayName = 'CustomSectionPreview';
export default React.memo(CustomSectionPreview);
