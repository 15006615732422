import {
  Checkbox, FormControlLabel,
  FormGroup,
  Link,
  Theme,
  Typography,
  createStyles, makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import DrawerWrapper from '../../pages/SurveyBuilder/DrawerWrapper';
import { UseSurveyEditorAPI } from '../../pages/SurveyBuilder/useSurveyEditorAPI';
import propertyAction from '../../redux/actions/propertyAction';
import { RouteParams } from '../../routes/routes';
import { Survey } from '../../types';

const useStyles = makeStyles((theme: Theme) => createStyles({
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  link: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  drawer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '75%',
    },
  },
  tourDrawer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '330px',
    },
  },
}));
const PropertyComparisonSectionForm: React.FC<Props> = ({
  survey,
  onSurveyUpdate,
  onOpenEditColumnsDrawer,
  params,
  surveyApi,
}) => {
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
  const [tourPanelOp, setTourPanelOp] = useState(false);

  const {
    selectedProperty,
    selectProperty,
  } = surveyApi;
  const clickFileUpload = () => {
    // Close the Drawer first since the drawer is impacting the flatfile dropdown.
    setRightDrawerOpen(false);
  };

  return (
    <section>
      <Typography
        variant="caption" align="center" className={(classes.marginBottom)}
        component="p">
        Change the order and visibility of property attributes
        that display on this page
        <Link
          data-testid="comparison-drawer-link"
          onClick={() => onOpenEditColumnsDrawer()}
          className={classes.link}>Manage Attributes
        </Link>
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={survey.showCurrentLocation}
              name="showCurrentLocation"
              onChange={onSurveyUpdate}
              disabled={survey.properties.filter((item) => item.isCurrentLocation).length === 0}
            />
          }
          label="Show Current Location"
        />
      </FormGroup>
      <Typography
        variant="caption" align="center"
        component="p">To designate or add current location, open your
      </Typography>
      <Typography
        variant="caption" align="center" className={(classes.marginBottom)}
        component="p">
        <Link
          data-testid="comparison-drawer-link"
          onClick={() => {
            setRightDrawerOpen(true);
            dispatch(propertyAction.setDrawerOpenAction(true));
          }}
          className={classes.link}>property details.
        </Link>
      </Typography>
      {/* @Todo "post-pilot" work to reference for later.
      <FormControl
        fullWidth
        className={classes.marginBottom}
        disabled
        variant="outlined"
      >
        <InputLabel id="pdf-layout-label">PDF Layout</InputLabel>
        <Select
          labelId="pdf-layout-label"
          id="pdf-layout-select"
          value={survey.propertyComparisonLayout}
          name="propertyComparisonLayout"
          onChange={onSurveyUpdate}
          label="PDF Layout"
        >
          <MenuItem value="Portrait">Comfortable (Portrait)</MenuItem>
          <MenuItem value="Landscape">Compact (Landscape)</MenuItem>
        </Select>
      </FormControl>
      <FormControl
        fullWidth
        className={classes.marginBottom}
        disabled
        variant="outlined"
      >
        <InputLabel id="properties-per-page-label">Properties Per Page</InputLabel>
        <Select
          labelId="properties-per-page-label"
          id="properties-per-page-select"
          value={survey.propertyPerPortraitComparisonPage}
          name="propertyPerPortraitComparisonPage"
          onChange={onSurveyUpdate}
          label="Properties Per Page"
        >
          <MenuItem value="3">3</MenuItem>
          <MenuItem value="4">4</MenuItem>
          <MenuItem value="5">5</MenuItem>
        </Select>
      </FormControl>
      */}
      <DrawerWrapper
        variant="temporary"
        anchor="right"
        open={rightDrawerOpen}
        classes={{ paperAnchorRight: tourPanelOp ? classes.tourDrawer : classes.drawer }}
        onClickFileupload={clickFileUpload}
        onDismiss={() => {
          setRightDrawerOpen(false);
          dispatch(propertyAction.setDrawerOpenAction(false));
        }}
        params={params}
        hideBackdrop={!selectedProperty && tourPanelOp}
        onClose={() => {
          if (selectedProperty) {
            selectProperty();
          }
          setRightDrawerOpen(false);
          dispatch(propertyAction.setDrawerOpenAction(false));
        }}
        showManagePropertyView={!selectedProperty && !tourPanelOp}
        showTourPanel={!selectedProperty && tourPanelOp}
        surveyApi={surveyApi}
      />
    </section>
  );
};

export type Props = {
  onSurveyUpdate: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | {
    name: string | undefined;
    value: unknown;
  }>, checkedOrChild?: boolean | React.ReactNode) => void;
  survey: Survey;
  onOpenEditColumnsDrawer: () => void;
  params: RouteParams,
  surveyApi: UseSurveyEditorAPI,
};

PropertyComparisonSectionForm.displayName = 'PropertyComparisonSectionForm';
export default React.memo(PropertyComparisonSectionForm);
