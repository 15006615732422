import React, { useState } from 'react';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Edit from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@cbreone/core-ui/dist/components/DebouncedTextField';
import { UseSurveyEditorAPI } from '../../pages/SurveyBuilder/useSurveyEditorAPI';
import { Property, Survey } from '../../types';

const useStyles = makeStyles((theme: Theme) => ({
  v_spacer: {
    height: theme.spacing(3),
  },
  paddingTop: {
    marginTop: theme.spacing(2),
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
  subForm: {
    backgroundColor: theme.palette.grey['100'],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
  capitalize: {
    '& label': {
      textTransform: 'capitalize',
    },
  },
}));

const CustomFieldsForm: React.FC<Props> = ({
  property,
  survey,
  onAddSurveyCustomField,
  onDeleteSurveyCustomField,
  onUpdateSurveyCustomField,
  onUpdatePropertyCustomField,
}) => {
  const [customFieldLabel, setCustomFieldLabel] = useState<string>();
  const [customFieldLabels, setCustomFieldLabels] = useState<{ [id: string]: string; }>({});
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6">Custom Fields</Typography>
      <div className={classes.v_spacer} />

      <Grid container spacing={2}>
        {survey.customFields.map((field) => ({
          fieldKey: field.fieldKey,
          label: field.fieldLabel,
          value: property.customFields?.find((f) => f.fieldKey === field.fieldKey)?.value || '',
        })).map((p) => (
          <Grid item xs={12} sm={6} key={p.fieldKey}>
            {p.fieldKey in customFieldLabels ?
              (
                <Grid container className={classes.subForm}>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      value={p.label}
                      onChange={(e) => {
                        setCustomFieldLabels({
                          ...customFieldLabels, [p.fieldKey]: e.target.value,
                        });
                      }}
                      fullWidth
                      label="Custom Field Label"
                    />
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Button
                      className={classes.paddingTop}
                      variant="text"
                      onClick={() => {
                        onDeleteSurveyCustomField({
                          ownerId: survey.id,
                          ownerType: 'SC_SURVEY',
                          fieldKey: p.fieldKey,
                          fieldLabel: customFieldLabels[p.fieldKey],
                        });
                      }}>
                      Delete
                    </Button>
                    <span>
                      <Button
                        variant="text"
                        className={classes.paddingTop}
                        onClick={() => {
                          const {
                            [p.fieldKey]: remove,
                            ...updatedCustomFields
                          } = customFieldLabels;
                          setCustomFieldLabels(updatedCustomFields);
                        }}>
                        Cancel
                      </Button>
                      <Button
                        className={`${classes.paddingTop} ${classes.marginLeft}`}
                        onClick={() => {
                          onUpdateSurveyCustomField({
                            ownerId: survey.id,
                            ownerType: 'SC_SURVEY',
                            fieldKey: p.fieldKey,
                            fieldLabel: customFieldLabels[p.fieldKey],
                          });
                          const updatedCustomFields = customFieldLabels;
                          delete updatedCustomFields[p.fieldKey];
                          setCustomFieldLabels(updatedCustomFields);
                        }}>
                        Save
                      </Button>
                    </span>
                  </Grid>
                </Grid>
              )
              :
              (
                <Grid container justifyContent="space-between">
                  <Grid item xs={11}>
                    <TextField
                      type="text"
                      debounceTime={400}
                      value={p.value}
                      onChange={(e) => {
                        onUpdatePropertyCustomField(property.id, {
                          fieldKey: p.fieldKey,
                          value: e.target.value,
                        });
                      }}
                      fullWidth
                      label={p.label}
                      name={p.label}
                      classes={{
                        root: classes.capitalize,
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => (
                        setCustomFieldLabels({ ...customFieldLabels, [p.fieldKey]: p.label })
                      )}
                    >
                      <Edit />
                    </IconButton>
                  </Grid>
                </Grid>
              )
            }
          </Grid>
        ))}
        <Grid item xs={12} sm={6}>
          {customFieldLabel === undefined
            ? (
              <Grid container>
                <Button
                  variant="text"
                  onClick={() => setCustomFieldLabel('')}
                  startIcon={<AddIcon />}
                >
                  Add Custom Field
                </Button>
              </Grid>
            )
            : (
              <Grid
                container
                className={classes.subForm}
              >
                <TextField
                  inputProps={{ 'aria-label': 'Custom Field Label' }}
                  type="text"
                  value={customFieldLabel}
                  onChange={(e) => setCustomFieldLabel(e.target.value)}
                  fullWidth
                  label="Custom Field Label"
                />
                <Grid container justifyContent="flex-end">
                  <Button
                    variant="text"
                    className={classes.paddingTop}
                    onClick={() => setCustomFieldLabel(undefined)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={`${classes.paddingTop} ${classes.marginLeft}`}
                    onClick={() => {
                      onAddSurveyCustomField({ fieldLabel: customFieldLabel, ownerType: 'SC_SURVEY', ownerId: survey.id });
                      setCustomFieldLabel(undefined);
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Grid>
    </>
  );
};

export type Props = {
  survey: Survey;
  property: Property;
  onAddSurveyCustomField: UseSurveyEditorAPI['addCustomField'];
  onDeleteSurveyCustomField: UseSurveyEditorAPI['deleteCustomField'];
  onUpdateSurveyCustomField: UseSurveyEditorAPI['updateCustomField'];
  onUpdatePropertyCustomField: UseSurveyEditorAPI['updatePropertyCustomField'];
};

export default React.memo(CustomFieldsForm);
