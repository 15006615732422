/* eslint-disable max-len */
import React, { useEffect, useRef } from 'react';
import { Button, IconButton, Box, Typography, Tabs, Tab } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import InlineEditTextField from '@cbreone/core-ui/dist/components/InlineEditTextField';
import { parseInputChange } from '@cbreone/utilities';
import { Property, Survey } from '../../types';
import { UseSurveyEditorAPI } from '../../pages/SurveyBuilder/useSurveyEditorAPI';
import PropertyDetails from '../PropertyDetail/PropertyDetails';
import PropertyCollateral from '../PropertyCollateral';
import PropertyAvailabilities from '../PropertyAvailabilities';
import { UsePropertyAPI } from '../../pages/SurveyBuilder/usePropertiesAPI';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    padding: `${theme.spacing(3.5)}px ${theme.spacing(6)}px`,
  },
}));

const useTabsStyles = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
}));
const useTabStyles = makeStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
  },
}));

const PropertyTabs = {
  details: {
    label: 'Property Details',
    value: 'details',
  },
  availabilities: {
    label: 'Availabilities',
    value: 'availabilities',
  },
  collateral: {
    label: 'Collateral',
    value: 'collateral',
  },
};

const PropertyEdit: React.FC<Props> = ({
  survey,
  property,
  onBack,
  onUpdateProperty,
  onAddAvailability,
  onDeleteAvailability,
  onUpdateAvailability,
  onAddCustomField,
  onDeleteCustomField,
  onUpdateCustomField,
  onReorder,
  onUpdateAvailabilityStatus,
  onUpdatePropertyCustomField,
  onUpdateMedia,
  deleteAvailabilities,
}) => {
  const classes = useStyles();
  const tabsClasses = useTabsStyles();
  const tabClasses = useTabStyles();

  const [activeTab, setActiveTab] = React.useState(PropertyTabs.details);

  const handlePropertyUpdate =
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { name, value } = parseInputChange(event.target);
      onUpdateProperty({
        id: property.id,
        [name]: value,
      });
    };

  const containerRef = useRef<any>();
  useEffect(() => {
    containerRef.current.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
  }, []);

  return (
    <div ref={containerRef} className={classes.root}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <IconButton onClick={onBack} autoFocus>
            <ChevronLeftOutlinedIcon />
          </IconButton>
          <InlineEditTextField
            type="text"
            name="name"
            onChange={handlePropertyUpdate}
            value={property.name}
            debounceTime={400}
            style={{ width: 560 }}
          />
        </div>
        <div style={{ alignSelf: 'center' }}>
          <Button
            variant="text"
            onClick={onBack}
          >
            See all properties
          </Button>
        </div>
      </div>
      <Box
        border={1} borderColor="orange" display="flex"
        alignItems="center" mt={3} p={1}>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
          }}>
          <ReportProblemOutlinedIcon style={{ color: 'orange' }} />
          <Typography style={{ paddingLeft: 10, fontSize: '0.9rem' }}>
            Edits made to property fields below will display in this survey only. Edits will not sync back to Broker Hub.
            Uploaded photos, floor plans and flyers will not sync back to the project's properties or CBRE data in Broker Hub.
          </Typography>
        </div>
      </Box>
      <div style={{ marginTop: 24 }}>
        <Tabs
          value={activeTab} indicatorColor="primary" textColor="primary"
          classes={{ root: tabsClasses.root }}
          onChange={(_: React.ChangeEvent<{}>, tab: any) => {
            setActiveTab(tab);
          }}
        >
          {
            Object.values(PropertyTabs).map((tab) => (
              <Tab
                key={tab.value} label={tab.label} value={tab}
                classes={{ root: tabClasses.root }} disableRipple />
            ))
          }
        </Tabs>
        <div hidden={activeTab !== PropertyTabs.details}>
          {/* <PropertyDetailsLegacy
                        survey={survey}
                        property={property}
                        onUpdateProperty={onUpdateProperty}
                        onAddAvailability={onAddAvailability}
                        onDeleteAvailability={onDeleteAvailability}
                        onUpdateAvailability={onUpdateAvailability}
                        onAddCustomField={onAddCustomField}
                        onDeleteCustomField={onDeleteCustomField}
                        onUpdateCustomField={onUpdateCustomField}
                        onUpdatePropertyCustomField={onUpdatePropertyCustomField}
                        onUpdateMedia={onUpdateMedia}
                    /> */}
          <PropertyDetails
            survey={survey}
            property={property}
            onUpdateProperty={onUpdateProperty}
            onAddAvailability={onAddAvailability}
            onDeleteAvailability={onDeleteAvailability}
            onUpdateAvailability={onUpdateAvailability}
            onAddCustomField={onAddCustomField}
            onDeleteCustomField={onDeleteCustomField}
            onUpdateCustomField={onUpdateCustomField}
            onUpdatePropertyCustomField={onUpdatePropertyCustomField}
          />
        </div>
        <div hidden={activeTab !== PropertyTabs.availabilities}>
          <PropertyAvailabilities
            survey={survey}
            onReorder={onReorder}
            onUpdateAvailabilityStatus={onUpdateAvailabilityStatus}
            onUpdateAvailability={onUpdateAvailability}
            deleteAvailabilities={deleteAvailabilities}
            property={property}
            onUpdateProperty={onUpdateProperty}
            onAddAvailability={onAddAvailability} />
        </div>
        <div hidden={activeTab !== PropertyTabs.collateral}>
          <PropertyCollateral
            property={property}
            onUpdateMedia={onUpdateMedia}
          />
        </div>
      </div>
    </div>
  );
};

export type Props = {
  survey: Survey;
  property: Property;
  onUpdateProperty: UseSurveyEditorAPI['updateProperty'];
  onAddAvailability: UseSurveyEditorAPI['addAvailability'];
  onDeleteAvailability: UseSurveyEditorAPI['deleteAvailability'];
  onUpdateAvailability: UseSurveyEditorAPI['updateAvailability'];
  onBack: () => void;
  onAddCustomField: UseSurveyEditorAPI['addCustomField'];
  onDeleteCustomField: UseSurveyEditorAPI['deleteCustomField'];
  onUpdateCustomField: UseSurveyEditorAPI['updateCustomField'];
  onReorder: UsePropertyAPI['reorderAvailability'];
  onUpdateAvailabilityStatus: UsePropertyAPI['updateAvailabilityStatus'];
  deleteAvailabilities: UsePropertyAPI['deleteAvailabilities'];
  onUpdatePropertyCustomField: UseSurveyEditorAPI['updatePropertyCustomField'];
  onUpdateMedia: UseSurveyEditorAPI['updateMedia'];

};

PropertyEdit.displayName = 'PropertyEdit';
export default React.memo(PropertyEdit);
