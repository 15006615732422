import AppConfig from '../../config';

export const defaultZoomLevel = 4;
export const defaultMapTextSize = 28;
export const defaultMapCenter = [-95, 40];
export const iconColorYellow = '#F9DD4B';
export const iconColorBlack = '#012A2D';
export const iconColorActive = '#103f60';
export const iconColorInactive = '#0976C3';
export const starIcon = 'starIcon';
export const emptyIcon = 'emptyIcon';

export const BAM_MAP_STYLES = {
  cbreLight: 'cbreLight',
  cbreDark: 'cbreDark',
  esriHybrid: 'esriHybrid',
  bingHybrid: 'bingHybrid',
  mapboxStreets: 'mapboxStreets',
  mapboxGray: 'mapboxGray',
  mapboxDark: 'mapboxDark',
};

export const mapIgnoredActionStyles = ['map-center-icon',
  'color-selector-section',
  'style-map-container',
  'style-map-header',
  'style-map-body',
  'primary-color--selector',
  'teritary-color-selector',
  'neutral-color-selector',
  'custom-color-selector',
  'tooltip-action',
  'popover-base-padding',
  'tooltip-img',
  'tooltip-address1',
  'tooltip-address2',
  'mapboxgl-popup-close-button',
  'slide-left', 'group-input-container'];

export const BAM_MAP_STYLE_LIST = [
  'cbreLight',
  'cbreDark',
  'esriHybrid',
  'bingHybrid',
  'mapboxStreets',
  'mapboxGray',
  'mapboxDark',
];

export const BAM_MAP_STYLE_DISPLAY_NAMES = {
  [BAM_MAP_STYLES.cbreLight]: 'CBRE Light',
  [BAM_MAP_STYLES.cbreDark]: 'CBRE Dark',
  [BAM_MAP_STYLES.esriHybrid]: 'Esri Hybrid',
  [BAM_MAP_STYLES.bingHybrid]: 'Bing Hybrid',
  [BAM_MAP_STYLES.mapboxStreets]: 'Mapbox Streets',
  [BAM_MAP_STYLES.mapboxGray]: 'Mapbox Gray',
  [BAM_MAP_STYLES.mapboxDark]: 'Mapbox Dark',
};

export const BAM_MAP_STYLE_SOURCES = {
  [BAM_MAP_STYLES.cbreLight]: 'cbreLight',
  [BAM_MAP_STYLES.cbreDark]: 'cbreDark',
  [BAM_MAP_STYLES.esriHybrid]: `${AppConfig.appRootURL}/assets/map-styles/esri-hybrid.json`,
  [BAM_MAP_STYLES.bingHybrid]: 'bingHybrid',
  [BAM_MAP_STYLES.mapboxStreets]: 'mapbox://styles/mapbox/streets-v10',
  [BAM_MAP_STYLES.mapboxGray]: 'mapbox://styles/mapbox/light-v9',
  [BAM_MAP_STYLES.mapboxDark]: 'mapbox://styles/mapbox/dark-v9',
};

export const BAM_MAP_EVENT_TYPE: { [x: string]: any } = {
  zoomEnd: 'mapZoomLevel',
  dragEnd: 'mapCentroid',
  reset: 'reset',
};
