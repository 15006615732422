/*eslint-disable*/
import { isFinite } from 'lodash';
import { Property, PropertyAvailabilitiesTypes } from '../../types';

/// //////// sub utility ///////////
function checkValidNumber(value: string) {
  const fvalue = parseFloat(value);
  if (!isNaN(fvalue) && isFinite(fvalue)) {
    return true;
  }
  return false;
}

function getDecorator(attrKey: string) {
  let prefix = '';
  let suffix = '';
  let possibleInteger = true;

  if (['askingRent', 'askingPrice'].includes(attrKey)) {
    prefix = '$';
    suffix = '/SF';
    possibleInteger = false;
  } else if ([
    'minTotalAvailableSpace',
    'maxTotalAvailableSpace',
    'totalAvailableSpace',
    'propertySize',
    'netRentableArea',
    'typicalFloorSize',
    'vacantSpace',
  ].includes(attrKey)) {
    suffix = ' SF';
  } else if (['vacantPercentage'].includes(attrKey)) {
    suffix = '%';
    possibleInteger = false;
  } else if (['opex'].includes(attrKey)) {
    prefix = '$';
    possibleInteger = false;
  } else {
    return;
  }

  return {
    prefix,
    suffix,
    possibleInteger,
    fractionDigits: possibleInteger ? 1 : 2,
  };
}

function refineNumberString(value: string, possibleInteger: boolean, fractionDigits: number): string {
  const fvalue = parseFloat(value);

  if (possibleInteger) {
    const nvalue = parseInt(value, 10);
    if (fvalue === nvalue) {
      return nvalue.toLocaleString('en-US');
    }
  }

  const localeStringOpts = {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  };
  return fvalue.toLocaleString('en-US', localeStringOpts);
}

export function getValidNumberString(_value: string, possibleInteger: boolean, fractionDigits: number): string {
  const value = (_value || '').trim();

  const others = value.match(/[^0-9.,]/g) || [];
  if (others.length > 0) {
    return '';
  }

  const svalue = value.replace(/[^0-9.,]/g, '');
  if (!checkValidNumber(svalue)) {
    return '';
  }
  return refineNumberString(svalue, possibleInteger, fractionDigits);
}

const getSingletonValue = (attrKey: string, value: string) => {
  const decorator = getDecorator(attrKey);
  if (!decorator) {
    return value;
  }
  const { prefix, suffix, possibleInteger, fractionDigits } = decorator;

  if (value) {
    // value = value ? value?.replace(/,/g, '') : '';
    const svalue = getValidNumberString(value ? value?.replace(/,/g, '') : '', possibleInteger, fractionDigits);
    if (svalue) {
      return `${prefix}${svalue}${suffix}`;
    }
    return value;
  }

  return '';
};

export const getCommas = (data: string, isPossibleFloat?: boolean) => {
  const options = {
    minimumFractionDigits: isPossibleFloat ? 2 : 0,
    maximumFractionDigits: isPossibleFloat ? 2 : 0,

  };
  if (data?.trim() && data.match(/^[0-9]+$/) != null) { return (parseFloat(data)?.toLocaleString('en-US', options)); }
  return data || '';
};

export const getCommasAndSymbol = (data: string) => {
  if (data?.trim() && data.match(/^[0-9.]+$/) != null) {
    return `$${parseFloat(data)?.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,

    })}`;
  }
  return data || '';
};

export function getAreaValueWithSF(value:any, unit:string) {
  return !isNaN(value?.trim()?.replace(/,/g, '')) ? (`${getCommas(value)?.trim()} ${value?.trim() ? unit || 'SF' : ''}`) : value?.trim() || '';
}

export function getAvailableSFValue(min:any, max:any) {
  return (getCommas(min)?.trim() || '') + (min && max ? ' - ' : '') +
    (max ? getCommas(max)?.trim() : '') + ((!isNaN(min?.trim()?.replace(/,/g, '')) || max?.trim()) && (min?.trim() || max?.trim()) ? ' SF' : '');
}

export function getAskingPriceValue(min:any, max:any, units:string) {
  return (units?.trim() && units?.trim() === '$' ? ((min?.trim() ? (getCommasAndSymbol(min?.trim())) : '') +
    (min?.trim() && max?.trim() ? ' - ' : '') +
    (max?.trim() ? `${getCommasAndSymbol(max?.trim())} ` : '')) :
    (min?.trim() && units?.trim() ? (getCommasAndSymbol(min?.trim())) : getCommas(min?.trim(), true))
    + (min?.trim() && max?.trim() ? ' - ' : '')
    + (max?.trim() && units?.trim() ? getCommasAndSymbol(max?.trim()) : getCommas(max?.trim(), true)) + (((!isNaN(min?.trim()) || max?.trim()) && units?.trim()) ? '/SF' : ''));
}

export function getAskingRentValue(min:any, max:any, services:string, units:string) {
  return ((min?.trim() && units?.trim() ? getCommasAndSymbol(min?.trim()) : getCommas(min?.trim(), true))
    + (min?.trim() && max?.trim() ? ' - ' : '') +
    (max?.trim() && units?.trim() ? getCommasAndSymbol(max?.trim()) : getCommas(max?.trim(), true)))
    + ((min?.trim() || max?.trim()) && units?.trim() ? ` ${units} ` : '')
    + ((min?.trim() || max?.trim()) && services?.trim() ? ` ${services}` : '');
}

export function getTotalAvailableValue(min: string, max: string, value: string) {
  const attrKey = 'totalAvailableSpace';

  let svalue = getSingletonValue(attrKey, value);
  if (svalue) {
    return svalue;
  }

  // formula based generation
  const decorator = getDecorator(attrKey)!;
  const { prefix, suffix, possibleInteger, fractionDigits } = decorator;
  min = min ? min?.replace(/,/g, '') : '';
  max = max ? max?.replace(/,/g, '') : '';

  const smin = getValidNumberString(min, possibleInteger, fractionDigits);
  const smax = getValidNumberString(max, possibleInteger, fractionDigits);

  svalue = '';

  if (smin) {
    if (smax) {
      svalue = `${smin} - ${smax}`;
    } else {
      svalue = `${smin}`;
    }
  } else if (smax) {
    svalue = `${smax}`;
  }

  if (svalue) {
    return `${prefix}${svalue}${suffix}`;
  }
  return '';
}

export function getLocaleStringForValue(value: string, possibleInteger: boolean, fractionDigits: number): string {
  const tempVal = value ? value?.replace(/,/g, '') : '';
  const refinedString = getValidNumberString(tempVal, possibleInteger, fractionDigits);
  return refinedString || value;
}

function getAllProposedSpacesForDispay(availabilities: PropertyAvailabilitiesTypes[]) {
  const svalue = availabilities
    .sort((a: PropertyAvailabilitiesTypes, b: PropertyAvailabilitiesTypes) => (Number(a.order) - Number(b.order)))
    .reduce((acc, availability: PropertyAvailabilitiesTypes, idx) => {
      let item = availability.proposedSpace?.trim() || '';
      item = addNewLine(item);
      if (idx > 0) {
        item = `\n\n${item}`;
      }
      return acc + item;
    }, '');
  return svalue;
}

/// ////////
export function getPropertyValueForDisplay(attrKey: string, property: Property & { [x: string]: any }) {
  if (!property) return '';
  const value = property[attrKey];

  let svalue = '';

  if (attrKey === 'totalAvailableSpace') {
    const min = property.minTotalAvailableSpace;
    const max = property.maxTotalAvailableSpace;

    svalue = getTotalAvailableValue(min, max, value);
    // ex: svalue = getTotalAvailableValue('125000', '150000', value)
  } else {
    svalue = getSingletonValue(attrKey, value);
  }

  return svalue;
}

export function getPropertyValueForDisplay2(attrKey: string, property: Property) {
  if (attrKey === 'propertyProposedSpace') {
    const availabilities = property.propertyAvailabilities || [];
    const filtered = availabilities.filter((availability: PropertyAvailabilitiesTypes) => availability.status === 'ACTIVE');
    return getAllProposedSpacesForDispay(filtered);
  }
  return getPropertyValueForDisplay(attrKey, property);
}

export function getAvailabilityFieldValues(fieldKey: string, availability: any) {
  if (fieldKey === 'askingRent') {
    return (
      `${(availability.minAskingRent?.trim() && availability.askingRentUnit?.trim() ? '$' : '') + (availability?.minAskingRent?.trim() ? getLocaleStringForValue(availability?.minAskingRent?.trim(), false, 2) : '') +
      (availability.maxAskingRent?.trim() && availability.minAskingRent?.trim() ? ' - ' : '') +
      (availability.maxAskingRent?.trim() && availability.askingRentUnit?.trim() ? '$' : '') + (availability.maxAskingRent?.trim() ? getLocaleStringForValue(availability?.maxAskingRent?.trim(), false, 2)
        : '')

      } ${availability?.askingRentUnit?.trim() ? `${availability?.askingRentUnit?.trim()} ` : ''
      }${availability?.askingRentServices?.trim() || ''}`
    );
  } if (fieldKey === 'availableSF') {
    const minValue = availability?.minAvailableSF?.trim()?.replace(/,/g, '');
    const maxValue = availability?.maxAvailableSF?.trim()?.replace(/,/g, '');

    const value = (
      (minValue ? getLocaleStringForValue(availability?.minAvailableSF?.trim(), true, 2) : '') +
      (maxValue && minValue ? ' - ' : '') +
      (maxValue ? getLocaleStringForValue(availability?.maxAvailableSF?.trim(), true, 2)
        : '') + (minValue && !isNaN(minValue) || (maxValue && !isNaN(maxValue)) ? ' SF' : '')
    );
    return value;
  } if (fieldKey === 'askingPrice') {
    return `${((availability?.minAskingPrice?.trim() && availability?.askingPriceUnit?.trim() ? '$' : '') + (availability?.minAskingPrice?.trim() ? getLocaleStringForValue(availability?.minAskingPrice?.trim(), false, 2) : '')) +
      (availability?.minAskingPrice?.trim() && availability?.askingPriceUnit?.trim() && availability?.maxAskingPrice?.trim() ? ' - ' : '') +
      (availability?.maxAskingPrice?.trim() && availability?.askingPriceUnit?.trim() ? '$' : '') + (availability?.maxAskingPrice?.trim() ? getLocaleStringForValue(availability?.maxAskingPrice?.trim(), false, 2) : '')}${(availability?.minAskingPrice?.trim() || availability?.maxAskingPrice?.trim()) && availability?.askingPriceUnit?.trim() && availability?.askingPriceUnit !== '$' ? '/SF' : ''}`;
  } if (fieldKey === 'availableDate' || fieldKey === 'dateAvailable') {
    return dateFormatter(availability[fieldKey]) || '-';
  } if (fieldKey === 'minDivisible' || fieldKey === 'maxContiguous') {
    return availability[fieldKey]?.trim() && !isNaN(availability[fieldKey]?.trim()) ? `${getLocaleStringForValue(availability[fieldKey]?.trim(), true, 2)} SF` : (availability[fieldKey] || '-');
  }
  if (fieldKey === 'proposedSpace') {
    return addNewLine(availability[fieldKey]);
  } if (fieldKey === 'parkingSpacesNum') {
    return availability[fieldKey]?.trim() && !isNaN(availability[fieldKey]?.trim()) ? getLocaleStringForValue(availability[fieldKey]?.trim(), true, 2) : (availability[fieldKey] || '-');
  }
  return availability[fieldKey] || '-';
}

export function addNewLine(value: string) {
  if (!value) return '';
  const match: any = value.match(/, \d/);
  if (match && match.index !== -1 && value.endsWith('SF')) {
    return `${value.substring(0, match.index + 1)}\r\n${value.substring(match.index + 1, value.length)}`;
  }
  return value;
}

export function dateFormatter(data: any) {
  if (data?.includes('T') && data?.includes('Z')) {
    const Value = data?.slice(0, data.indexOf('T'));
    const dateParts = Value?.split('-');
    const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
    const dateFormat = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

    data = dateFormat;
    return data;
  }

  // return strings those are not date strings
  return data;
}

export function getPropertyIds(propertiesMap: any) {
  const propertyIdList  = Object.keys(propertiesMap);
  console.log('getPropertyIds - propertyIdList::: ', propertyIdList);
  return propertyIdList;
}
