import React from 'react';
import CreateSurveyLoader from '../pages/CreateSurveyLoader';
import SurveyList from '../pages/SurveyList';
import SurveySummary from '../pages/SurveySummary';
import SurveySectionPageWrapper from '../pages/SurveySectionPageWrapper';
import SurveyBuilderWrapper from '../pages/SurveyBuilder/SurveyBuilderWrapper';
import BHCreateSurveyLoader from '../brokerhub/BHCreateSurveyLoader';
import Callback from '../brokerhub/Callback/Callback';

type Route = {
  path?: string;
  exact?: boolean;
  component: React.FC<any>;
}
export type RouteParams = {
  id?: string;
  data?: string;
  encodedstr?: string;
}

export type PreviewRouteParams = {
  id?: string;
  section?: string;
  propertyId?: string;
  index?: number;
}

const routes: Route[] = [
  {
    path: '/',
    exact: true,
    component: SurveyList,
  },
  {
    path: '/build',
    exact: true,
    component: CreateSurveyLoader,
  },
  {
    path: '/:id',
    exact: true,
    component: SurveySummary,
  },
  {
    path: '/:id/preview/:section',
    exact: true,
    component: SurveySectionPageWrapper,
  },
  {
    path: '/:id/preview/:section/:propertyId',
    exact: true,
    component: SurveySectionPageWrapper,
  },
  {
    path: '/:id/preview/:section/:propertyId/floorPlanImage/:index',
    exact: true,
    component: SurveySectionPageWrapper,
  },
  {
    path: '/:id/builder',
    exact: true,
    component: SurveyBuilderWrapper,
  },
  {
    path: '/:id/builder/data/:encodedstr',
    exact: true,
    component: SurveyBuilderWrapper,
  },
  {
    path: '/sfdc/callback/:id',
    exact: true,
    component: Callback,
  },
  {
    path: '/sfdc/callback/:id/data/:data',
    exact: true,
    component: Callback,
  },
  {
    path: '/sfdc/callback',
    exact: true,
    component: Callback,
  },
  {
    path: '/bh/build',
    exact: true,
    component: BHCreateSurveyLoader,
  },
  {
    path: '/bh/build/data/:data',
    exact: true,
    component: BHCreateSurveyLoader,
  },
];

export default routes;
