import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { chunk } from 'lodash';
import { Property, Survey } from '../../types';
import PropertyComparisonSheet from './PropertyComparisonSheet';
import { PDFPaperSize } from '../../data/constants';

let MAX_PROPERTIES_PER_PAGE = 7;

const PropertyComparisonPreview: React.FC<Props> = ({
  survey,
  pageMode,
  order,
  anchore,
}) => {
  if (survey && survey.pdfPaperSize !== PDFPaperSize.Landscape) {
    MAX_PROPERTIES_PER_PAGE = 4;
  } else {
    MAX_PROPERTIES_PER_PAGE = 7;
  }
  let properties: Property[] = [];
  if (survey && survey.properties) {
    properties = survey.showCurrentLocation ? (survey.properties
      .filter((property) => !property.isHidden)) :
      (survey.properties
        .filter((property) =>
          !property.isHidden && !property.isCurrentLocation));
  }
  const addressKeys = ['address1', 'city', 'state', 'postalCode'];
  const activeAddressKeys = survey?.surveyPropertyFieldRule?.filter((rule) =>
    addressKeys.includes(rule.fieldKey))
    .map((rule) => {
      if (rule.status !== 'ACTIVE') {
        return '';
      }
      return rule.fieldKey;
    });
  const activePropertyFieldRuleList = survey?.surveyPropertyFieldRule?.filter((rule) => rule.fieldKey !== 'name' && !addressKeys.includes(rule.fieldKey) && rule.status === 'ACTIVE');

  const isNameHidden = survey?.surveyPropertyFieldRule?.filter((rule) => rule.fieldKey === 'name')
    .map((rule) => rule.status !== 'ACTIVE')[0];

  let firstChunkedProperties;
  let chunkedProperties;
  if (properties.length > MAX_PROPERTIES_PER_PAGE) {
    firstChunkedProperties = properties.splice(0, MAX_PROPERTIES_PER_PAGE);
    chunkedProperties = (properties.length
      && chunk(properties, (MAX_PROPERTIES_PER_PAGE))) || [];
  } else {
    firstChunkedProperties = properties;
  }

  return (
    <div id={`${order}`} style={anchore}>
      <PropertyComparisonSheet
        activeAddressKeys={activeAddressKeys || []}
        activePropertyFieldRuleList={activePropertyFieldRuleList || []}
        firstPage={true}
        key={`PropertyComparisonSheet-${order}`}
        pageMode={pageMode}
        properties={firstChunkedProperties}
        propertiesPerPage={MAX_PROPERTIES_PER_PAGE}
        survey={survey}
        isNameHidden={isNameHidden}
      />
      {
        chunkedProperties?.map((perPageProperties: Property[], index: number) => (
          <PropertyComparisonSheet
            activeAddressKeys={activeAddressKeys || []}
            activePropertyFieldRuleList={activePropertyFieldRuleList || []}
            firstPage={true}
            key={`Page-PropertyComparisonSheet-${index + 1}`}
            pageMode={pageMode}
            properties={perPageProperties}
            propertiesPerPage={MAX_PROPERTIES_PER_PAGE}
            survey={survey}
            isNameHidden={isNameHidden}
          />
        ))
      }
    </div>
  );
};

export type Props = {
  survey: Survey;
  pageMode?: string;
  order: string;
  anchore?: object;
};

PropertyComparisonPreview.displayName = 'PropertyComparisonPreview';
export default React.memo(PropertyComparisonPreview);
