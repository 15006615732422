import React, { useState, useEffect } from 'react';
import { Survey } from '../../types';
import MarketMapPDFPreview from './MarketMapPDFPreview';
import { getArrayChunks } from '../../utilities';
import { PDFPaperSize } from '../../data/constants';

const MarketMapPDFSectionPreview: React.FC<Props> = ({
  survey,
  anchore,
  order,
}) => {
  const [legendCheckBox, setLegendCheckBox] = useState<boolean>(
    survey.isIncludeLegend,
  );
  const [firstChunks, setPropertyChunks] = useState<any[]>([]);
  const [remainingChunks, setRemainingChunks] = useState<any[]>([]);
  const [legendHight, setlegendHight] = useState<number>();

  const updateLegendHeight = (legendSize: number) => {
    setlegendHight(legendSize);
  };
  const updateSurvey = () => { };
  useEffect(() => {
    let propertyChunks: any[] = [];
    const surveyProperties = survey.properties.filter((item) => !item.isHidden);
    if (survey.properties.length === 0) {
      setLegendCheckBox(false);
    } else if (survey.properties.length === 1 && survey.properties[0].isHidden === true) {
      setLegendCheckBox(false);
    } else {
      setLegendCheckBox(survey.isIncludeLegend);
    }
    if (survey.pdfPaperSize === PDFPaperSize.Landscape) {
      if (survey.isIncludeHeader) {
        propertyChunks = getArrayChunks(surveyProperties, 14);
      } else {
        propertyChunks = getArrayChunks(surveyProperties, 16);
      }
    }
    if (survey.pdfPaperSize === PDFPaperSize.Portrait) {
      if (survey.isIncludeHeader) {
        propertyChunks = getArrayChunks(surveyProperties, 14);
      } else {
        propertyChunks = getArrayChunks(surveyProperties, 18);
      }
    }
    setPropertyChunks(propertyChunks.splice(0, 1));
    setRemainingChunks(propertyChunks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [survey.properties, survey.isIncludeLegend]);

  return (
    <div key={order}>
      {legendCheckBox === true &&
        firstChunks.map((chunk: any[]) => (
          <MarketMapPDFPreview
            order={`${survey.sections[1].order}`}
            key={0}
            index={0}
            survey={survey}
            anchore={anchore}
            updateSurvey={updateSurvey}
            legendHight={legendHight}
            updateLegendHeight={updateLegendHeight}
            properties={chunk} pageMode={'PDF'}
          />
        ))}
      {legendCheckBox === true &&
        remainingChunks.map((chunk: any[], index: any) => (
          <MarketMapPDFPreview
            order={`${survey.sections[1].order}`}
            key={index + 1}
            index={index + 1}
            survey={survey}
            anchore={anchore}
            updateSurvey={updateSurvey}
            legendHight={legendHight}
            properties={chunk} pageMode={'PDF'}
          />
        ))}
      {legendCheckBox === false &&
        <MarketMapPDFPreview
          order={`${survey.sections[1].order}`}
          key={0}
          index={0}
          survey={survey}
          anchore={anchore}
          legendHight={legendHight}
          updateSurvey={updateSurvey} pageMode={'PDF'}
        />
      }
    </div>
  );
};

export type Props = {
  survey: Survey;
  anchore?: object;
  order?: string;
};

MarketMapPDFSectionPreview.displayName = 'MarketMapPDFSectionPreview';
export default React.memo(MarketMapPDFSectionPreview);
