import DragAndDropList from '@cbreone/core-ui/dist/components/DragAndDropList';
import DraggableListItem from '@cbreone/core-ui/dist/components/DraggableListItem';
import {
  Box,
  createStyles,
  Drawer,
  Grid,
  IconButton,
  Switch,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { DragResult } from '../../pages/SurveyBuilder/useSurveyAPI';
import { UseSurveyEditorAPI } from '../../pages/SurveyBuilder/useSurveyEditorAPI';
import { Survey } from '../../types';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
const TabPanel: React.FC<TabPanelProps> = (props) => {
  const {
    children, value, index, ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          {children}
        </Box>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    overflow: 'auto',
  },

  header: {
    paddingLeft: theme.spacing(2.5),
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paper: {
    width: 400,
  },
  disableSvg: {
    '& svg': {
      display: 'none',
    },
  },
  tab: {
    minWidth: '120px',
    padding: '6px 22px',
  },
  tabInfo: {
    fontSize: '14px',
    color: 'rgba(26, 26, 26, 0.6)',
    fontFamily: 'Calibre-R',
    lineHeight: '21px',
    fontStyle: 'normal',
  },
}));

const EditColumns: React.FC<Props> = ({
  onReorder,
  onUpdatePropertyFieldRule,
  survey,
  onToggleDrawer,
  rightDrawerOpen,
}) => {
  const classes = useStyles();
  const dragDisabledItems = ['address1', 'city', 'state', 'postalCode', 'name'];
  const toggleDisabledItems = ['proposedSpace'];
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    if (newValue === 1) {
      const elementPosition = document.querySelectorAll('.availabilities-section')[0].getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - 150;

      // window.location.href = `${window.location.origin}${window.location.pathname}#3`;
      window.scrollTo(0, offsetPosition);
    } else {
      window.location.href = `${encodeURI((window.location.origin))}${encodeURI((window.location.pathname))}#2`;
    }
  };

  const onDragEnd = (result: DragResult) => {
    if (result.destination.index === 0) {
      return;
    }
    onReorder(result);
  };

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={rightDrawerOpen}
      onClose={() => onToggleDrawer(false)}
      classes={{ paper: classes.paper }}
    >
      <div className={classes.root}>
        <Grid container className={classes.header}>
          <Grid item xs={10}>
            <Typography variant="h6" component="h2">
              Manage Attributes
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => onToggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Box sx={{ borderBottom: '1px solid #ddd' }}>

          <Tabs
            value={value}
            TabIndicatorProps={{ style: { minWidth: '120px' } }}
            onChange={handleChange}
            aria-label="Manage Columns Tabs"
          >
            <Tab label="Property" className={classes.tab} />
            <Tab label="Availability" disabled={!survey || !survey.properties || survey.properties.every((item) => !item.propertyAvailabilities || item.propertyAvailabilities.length === 0)} className={classes.tab} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Typography className={classes.tabInfo} align="center" component="p">
            Change the order or visibility of attributes that appear on each page.
          </Typography>

          <DragAndDropList droppableId="surveyPropertyFieldRule" onDragEnd={onReorder}>
            {survey.surveyPropertyFieldRule
              && survey.surveyPropertyFieldRule.map((rule, index) => (
                <DraggableListItem
                  key={rule.fieldKey}
                  index={index}
                  draggableId={dragDisabledItems.includes(rule.fieldKey) ? `${index}` : `${rule.fieldKey}-${index}`}
                  isDragDisabled={dragDisabledItems.includes(rule.fieldKey)}
                  className={dragDisabledItems.includes(rule.fieldKey) ? classes.disableSvg : ''}
                >
                  <Grid container>
                    <Grid item xs={10}>
                      {rule.fieldLabel}
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        checked={rule.status === 'ACTIVE'}
                        onChange={(e) => {
                          onUpdatePropertyFieldRule({
                            fieldKey: rule.fieldKey,
                            attributeType: 'Property',
                            status: rule.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
                            ownerId: survey.id,
                            ownerType: 'SC_SURVEY',
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                </DraggableListItem>
              ))}
          </DragAndDropList>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Typography className={classes.tabInfo} align="center" component="p">
            Change the order or visibility of attributes that appear in the Property Detail pages.
          </Typography>
          <DragAndDropList droppableId="surveyPropertyAvailabilityRule" onDragEnd={onDragEnd}>
            {survey.surveyPropertyAvailabilityRule
              && survey.surveyPropertyAvailabilityRule.map((rule, index) => (
                <DraggableListItem
                  key={rule.fieldKey}
                  index={index}
                  draggableId={toggleDisabledItems.includes(rule.fieldKey) ? `${index}` : `${rule.fieldKey}-${index}`}
                  isDragDisabled={toggleDisabledItems.includes(rule.fieldKey)}
                  className={toggleDisabledItems.includes(rule.fieldKey) ? classes.disableSvg : ''}
                >
                  <Grid container>
                    <Grid item xs={10}>
                      {rule.fieldLabel}
                    </Grid>
                    <Grid item xs={2}>
                      {!toggleDisabledItems.includes(rule.fieldKey) && <Switch
                        checked={rule.status === 'ACTIVE'}
                        onChange={(e) => {
                          onUpdatePropertyFieldRule({
                            fieldKey: rule.fieldKey,
                            status: rule.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
                            ownerId: survey.id,
                            attributeType: 'Availability',
                            ownerType: 'SC_SURVEY',
                          });
                        }}
                      />}
                    </Grid>
                  </Grid>
                </DraggableListItem>
              ))}
          </DragAndDropList>
        </TabPanel>
      </div>
    </Drawer>
  );
};

export type Props = {
  onReorder: UseSurveyEditorAPI['reorderSurveyAttribute'];
  onUpdatePropertyFieldRule: UseSurveyEditorAPI['updatePropertyFieldRule'];
  onToggleDrawer: (isOpen: boolean) => void;
  rightDrawerOpen: boolean;
  survey: Survey;
};

EditColumns.displayName = 'EditColumns';
export default React.memo(EditColumns);
