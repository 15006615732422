/* eslint-disable comma-dangle */
import { CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { PDFPaperSize } from '../../data/constants';
import SurveyPDFPage from '../SurveyPDFPage';
import * as mediaService from '../../services/media-service';
import { MediaFile } from '../../types';

const useStyles = makeStyles((theme) =>
  createStyles({
    landscapeLayout: {
      width: 1056,
    },
    portraitLayout: {
      width: 816,
    },
    spinnerBox: {
      display: 'flex',
      height: '8.5in',
    },
  })
);

const PDFPreview: React.FC<Props> = ({
  pdfUrl,
  originalUrl,
  paperSize,
  mediaId,
}) => {
  const classes = useStyles();
  const [thumbnailUrl, setThumbnailUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [pdfPages, setNumPages] = useState(0);
  const [pdfWidth, setPdfWidth] = useState(816);
  const onPageLoadSuccess = (page: any) => {
    setIsLoading(false);
    setPdfWidth(paperSize === PDFPaperSize.Landscape ? 1056 : 816);
  };

  useEffect(() => {
    if (pdfUrl) {
      setThumbnailUrl(pdfUrl);
    } else if (mediaId) {
      mediaService
        .reduceMedia(mediaId)
        .then((data: MediaFile) => {
          if (data && (data.thumbnailUrl || data.cloudfrontUrl)) {
            const url = data.thumbnailUrl || data.cloudfrontUrl;
            setThumbnailUrl(url);
          } else {
            setThumbnailUrl(originalUrl);
          }
        })
        .catch(() => {
          setThumbnailUrl(originalUrl);
        });
    }
  }, [pdfUrl]);

  return (
    <>
      {thumbnailUrl ? (
        <>
          {isLoading && (
            <>
              <SurveyPDFPage
                mode="FlyerPreview"
                key={`document_page_${mediaId}`}
              >
                <div className={classes.spinnerBox}>
                  <CircularProgress style={{ margin: 'auto auto' }} />
                </div>
              </SurveyPDFPage>
            </>
          )}
          <Document
            file={thumbnailUrl}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            {Array.from(Array(pdfPages), (i, index) => (
              <SurveyPDFPage
                mode="FlyerPreview"
                key={`document_page_${index + 1}`}
              >
                <Page
                  className={
                    paperSize === PDFPaperSize.Landscape
                      ? classes.landscapeLayout
                      : classes.portraitLayout
                  }
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={pdfWidth}
                  onLoadSuccess={onPageLoadSuccess}
                ></Page>
              </SurveyPDFPage>
            ))}
          </Document>
        </>
      ) : (
        <>
          <SurveyPDFPage mode="FlyerPreview" key={`document_page_${mediaId}`}>
            <div className={classes.spinnerBox}>
              <CircularProgress style={{ margin: 'auto auto' }} />
            </div>
          </SurveyPDFPage>
        </>
      )}
    </>
  );
};

export type Props = {
  pdfUrl: string;
  originalUrl: string;
  paperSize?: string;
  mediaId?: string;
};

PDFPreview.displayName = 'PDFPreview';
export default React.memo(PDFPreview);
