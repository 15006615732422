import { Survey } from '../../types';
import { SURVEY_ACTION } from '../types/actionTypes';
import { SurveyAction } from '../types/dataTypes';

const initialSurveyState = {} as Survey;

const surveyReducer = (
  state: Survey = initialSurveyState,
  action: SurveyAction,
): Survey => {
  switch (action.type) {
    case SURVEY_ACTION.SET_SURVEY:
    case SURVEY_ACTION.UPDATE_SURVEY:
    default:
      return {
        ...state,
        ...action.survey,
      };
  }
};

export default surveyReducer;
