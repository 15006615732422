import React from 'react';
import {
  Theme,
  createStyles,
  makeStyles,
  Button,
  Tooltip,
  Typography,
} from '@material-ui/core';
import MapSettingRestoreIcon from '../Icons/MapSettingRestoreIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-block',
      position: 'absolute',
      zIndex: 1,
      right: 11,
      top: 148,
    },
    settingMenu: {
      background: 'white',
      boxShadow: '0 0 0 2px rgb(0 0 0 / 10%)',
      borderRadius: 4,
      height: 30,
      width: 30,
      padding: 4,
      cursor: 'pointer',
      display: 'block',
      minWidth: 30,
      '&:hover': {
        background: '#f1f0f0',
      },
    },
    mapStyleClass: {
      width: 155,
      height: 240,
    },
    mapStyleTitle: {
      margin: '12px 16px 10px 16px',
    },
    mapStyleList: {
      height: 200,
      paddingLeft: '16px',
      overflowY: 'auto',
      '& label': {
        marginRight: 0,
        height: 40,
        '& span': {
          fontSize: '0.875rem',
        },
      },
    },
  }));

const MapRestore: React.FC<Props> = (props) => {
  const { onRestoreChange } = props;
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Tooltip title={<Typography>Reset pins to original coordinates.</Typography>} arrow placement="bottom-end">
        <Button className={`${classes.settingMenu}`} onClick={onRestoreChange}>
          <MapSettingRestoreIcon height={42} width={42}></MapSettingRestoreIcon>
        </Button>
      </Tooltip>

    </section>
  );
};

type Props = {
  onRestoreChange: (e: any) => void;
};

MapRestore.displayName = 'MapRestore';
export default MapRestore;
