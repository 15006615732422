import { useEffect, useMemo, useRef, useState } from 'react';
import * as availabilityService from '../../services/availability-service';
import * as propertyService from '../../services/property-service';
import {
  AvailabilityPatch,
  AvailabilityStatusPatch,
  Property,
  PropertyAvailabilitiesTypes,
  PropertyDelete,
  PropertyPatch,
  PropertyPost,
  Survey,
} from '../../types';
import {
  BHPropertyCustomFieldPatch,
  BHPropertyCustomFieldValuePatch,
} from '../../types/bh-property-custom-field';
import {
  getSurveyWithMapData,
  isChangingMapDataSetting,
} from '../../utilities';
import { DragResult, UseSurveyAPI } from './useSurveyAPI';

export type UsePropertyAPI = {
  addAvailability: (propertyId: string, availabilityUpdateCallback: Function) => void;
  addProperty: (post?: PropertyPost) => void;
  deleteAvailability: (id: string) => void;
  deleteAvailabilities: (availability: string) => void;
  deleteProperty: (deleteProperty: PropertyDelete) => void;
  selectProperty: (id?: string) => void;
  selectedProperty?: Property;
  updateAvailability: (updateAvailability: AvailabilityPatch, callback: any
  ) => void;
  updateProperty: (patch: PropertyPatch) => void;
  reorderAvailability: (patch: DragResult) => void;
  updateAvailabilityStatus: (propertyAvailabilityStatus: AvailabilityStatusPatch) => void,
  updatePropertyCustomField: (
    propertyId: string,
    patch: BHPropertyCustomFieldPatch
  ) => void;
};

export type UsePropertiesAPIProps = {
  setSurvey: UseSurveyAPI['setSurvey'];
  survey: Survey;
  resetMarketMap: Function;
  resetMapStateCallback: Function;
};

// eslint-disable-next-line no-console
const logError = (message?: string) => (error: Error) =>
  console.error(message || 'Error', error);

export default function usePropertyAPI({
  setSurvey,
  survey,
  resetMarketMap,
  resetMapStateCallback,
}: UsePropertiesAPIProps): UsePropertyAPI {
  const [selectedPropertyId, setSelectedPropertyId] = useState<
    string | undefined
  >();
  const surveyRef = useRef<Survey>(survey);
  useEffect(() => {
    surveyRef.current = survey;
  }, [survey]);

  const addProperty = (
    post: PropertyPost = {
      address1: '',
      amenities: '',
      askingRent: '',
      askingPrice: '',
      auditVersion: '',
      bamMapData: '',
      buildingClass: '',
      cachedName: '',
      capacity: '',
      centerType: '',
      city: '',
      clearHeight: '',
      columnSpacing: '',
      comments: '',
      crossStreets: '',
      customFields: [],
      dockHighDoors: '',
      floors: '',
      isHidden: false,
      isClientShortlist: false,
      latitude: '',
      longitude: '',
      lotSize: '',
      market: '',
      name: '',
      officeSpace: '',
      // @ts-ignore
      order: surveyRef.current.properties.length,
      owner: '',
      parkingRatio: '',
      parkingSpaces: '',
      parkingSpacesCars: '',
      parkingSpacesTrailers: '',
      postalCode: '',
      powerAmps: '',
      powerVolts: '',
      propertySize: '',
      sprinklers: '',
      state: '',
      submarket: '',
      stopDetails: '',
      // @ts-ignore
      surveyId: surveyRef.current.id,
      ownerId: surveyRef.current.id,
      ownerType: 'SC_SURVEY',
      totalAvailableSpace: '',
      uri: '',
      virtualTour: '',
      yearBuilt: '',
      yearRenovated: '',
      zoning: '',
    },
    redirectToDetails = true,
  ) => {
    propertyService
      .addProperty(post)
      .then((property) => {
        setSurvey({
          ...survey,
          properties: [...surveyRef.current.properties, property],
        });
        if (redirectToDetails) {
          setSelectedPropertyId(property.id);
        }
      })
      .catch(logError('Failed to Add Property'));
  };

  const updateProperty = (patch: PropertyPatch) => {
    const oldProperties = [...surveyRef.current.properties];
    const mapData = getSurveyWithMapData(surveyRef.current, patch);

    let newSurvey = {
      ...surveyRef.current,
    };
    if (mapData) {
      newSurvey = {
        ...surveyRef.current,
        marketMapBamData: mapData,
      };
    }
    const $propertyPatchCall = Object.prototype.hasOwnProperty.call(
      patch,
      'isHidden',
    )
      ? propertyService.updatePropertyStatus({
        propertyId: patch.id,
        ownerId: surveyRef.current.id,
        ownerType: 'SC_SURVEY',
        status: patch.isHidden ? 'INACTIVE' : 'ACTIVE',
      })
      : propertyService.updateProperty(patch);
    $propertyPatchCall
      .then((property: any) => {
        const updatedSurvey = {
          ...newSurvey,
          properties: newSurvey.properties.map((p) =>
            p.id !== patch.id
              ? p
              : {
                ...property,
              },
          ),
        };
        setSurvey(updatedSurvey);
        if (mapData) {
          resetMarketMap(resetMapStateCallback);
        } else {
          const isPropertyMapData = isChangingMapDataSetting(patch);
          if (isPropertyMapData) {
            resetMapStateCallback(updatedSurvey, false, patch);
          }
        }
      })
      .catch((error) => {
        logError('Failed to update property')(error);
        setSurvey({
          ...surveyRef.current,
          properties: oldProperties,
        });
      });
  };

  const deleteProperty = (deletePropertyParam: PropertyDelete) => {
    const oldProperties = [...surveyRef.current.properties];
    setSurvey({
      ...surveyRef.current,
      marketMapBamData: '{"mapReset":true}',
      properties: surveyRef.current.properties.filter(
        (property) => property.id !== deletePropertyParam.propertyId,
      ),
    });
    propertyService
      .deleteProperty(deletePropertyParam)
      .then(() => {
        resetMarketMap(resetMapStateCallback);
      })
      .catch((err) => {
        logError('Failed to delete property')(err);
        setSurvey({
          ...surveyRef.current,
          properties: oldProperties,
        });
      });
  };

  const deleteAvailabilities = (deleteAvailabilityParam: string) => {
    // const oldProperties = [...surveyRef.current.properties.filter((property) => property.id === selectedPropertyId)];
    const oldProperty = surveyRef.current.properties.filter(
      (property: any) => property.id === selectedPropertyId,
    )[0];

    availabilityService.deleteAvailabilityService(deleteAvailabilityParam).then((res) => {
      const skippedAvailabilities =
        oldProperty.propertyAvailabilities.filter((availability: PropertyAvailabilitiesTypes) =>
          availability.id !== deleteAvailabilityParam,
        );

      const newAvailabilities = [...skippedAvailabilities];
      oldProperty.propertyAvailabilities = newAvailabilities as any;
      //  let newProperty: any = { ...oldProperty, propertyAvailabilities: newAvailabilities };

      setSurvey({
        ...surveyRef.current,
        properties: [...surveyRef.current.properties],
      });
    }).catch((err: any) => {
      logError('Failed to update property  availability order value')(err);
      setSurvey({
        ...surveyRef.current,
        properties: surveyRef.current.properties,
      });
    });
  };
  // eslint-disable-next-line no-unused-vars
  const addAvailability = (
    propertyId: string,
    callback: Function,
  ) => {
    const currentProperty = surveyRef.current.properties.filter(
      (property: any) => property.id === selectedPropertyId,
    )[0];

    availabilityService.addAvailabilities(propertyId).then((res) => {
      const currentAvailabilities: any = [...currentProperty.propertyAvailabilities, res];
      currentProperty.propertyAvailabilities = currentAvailabilities;
      //  let newProperty: any = { ...currentProperty, propertyAvailabilities: currentAvailabilities };
      setSurvey({
        ...surveyRef.current,
        properties: [...surveyRef.current.properties],
      });
      callback(res);
    }).catch((err: any) => {
      logError('Failed to update property  availability order value')(err);
      setSurvey({
        ...surveyRef.current,
        properties: surveyRef.current.properties,
      });
    });
  };

  const updateAvailability = (updateAvailability: AvailabilityPatch, callback: any,
  ) => {
    const currentProperty = surveyRef.current.properties.filter(
      (property: any) => property.id === selectedPropertyId,
    )[0];

    availabilityService.updateAvailability(updateAvailability).then((res) => {
      const currentAvailabilities: any = [...currentProperty.propertyAvailabilities];
      const targetAvailability = currentProperty.propertyAvailabilities
        .findIndex((availability: PropertyAvailabilitiesTypes) => availability.id === updateAvailability.id);
      currentAvailabilities[targetAvailability] = res;
      currentProperty.propertyAvailabilities = currentAvailabilities;
      //   let newProperty: any = { ...currentProperty, propertyAvailabilities: currentAvailabilities };
      setSurvey({
        ...surveyRef.current,
        properties: [...surveyRef.current.properties],
      });
      callback(res);
      // console.log('res', res);
    }).catch((err: any) => {
      logError('Failed to update property  availability order value')(err);
      setSurvey({
        ...surveyRef.current,
        properties: surveyRef.current.properties,
      });
    });
  };

  // eslint-disable-next-line no-unused-vars
  const deleteAvailability = (id: string) => {
    // TODO: Uncomment and refactor when Availabilities service is available
    // setProperties(properties.map((property) => ({
    //   ...property,
    //   availabilities: property.availabilities?.filter((availability) => (
    //     availability.id !== id)) || [],
    // })));
    // availabilityService.deleteAvailability(id);
    // where is the availability order being kept? will need to update that
  };

  // eslint-disable-next-line no-unused-vars
  // const updateAvailability = (propertyId: string, patch: AvailabilityPatch) => {
  // TODO: Uncomment and refactor when Availabilities service is available
  // setProperties(properties.map((property) => (
  //   property.id !== propertyId ? property : {
  //     ...property,
  //     availabilities: property.availabilities?.map((availability) => (
  //       availability.id !== patch.id ? availability : {
  //         ...availability,
  //         ...patch,
  //         updated: new Date(Date.now()).toISOString(),
  //       }
  //     ) || []),
  //   }
  // )));
  // availabilityService.updateAvailability(patch).then((res) => {
  //   setProperties((current) => current.map((property) => {
  //     if (property.id !== res.id) {
  //       return property;
  //     }
  //     return {
  //       ...property,
  //       availabilities: property.availabilities?.map((availability) => (
  //         availability.id === res.id && res.updated >= availability.updated
  //           ? res : availability
  //       )) || [],
  //     };
  //   }));
  // });
  // };

  const updatePropertyCustomField = (
    propertyId: string,
    patch: BHPropertyCustomFieldPatch,
  ) => {
    const oldProperties = [...surveyRef.current.properties];
    const updatedOn = new Date(Date.now()).toISOString();
    setSurvey({
      ...surveyRef.current,
      updatedOn,
      properties: surveyRef.current.properties.map((property) => {
        if (property.id !== propertyId) {
          return property;
        }
        const customFields = property.customFields || [];
        const found = customFields.find(
          (field) => field.fieldKey === patch.fieldKey,
        );
        if (found) {
          return {
            ...property,
            customFields: customFields.map((field) =>
              field.fieldKey !== patch.fieldKey
                ? field
                : {
                  ...field,
                  ...patch,
                  updatedOn,
                },
            ),
          };
        }
        const surveyCustomField = surveyRef.current.customFields.find(
          (field) => field.fieldKey === patch.fieldKey,
        );
        if (surveyCustomField) {
          return {
            ...property,
            customFields: [
              ...customFields,
              {
                ...patch,
              },
            ],
          };
        }
        return property;
      }),
    });
    const request = {
      propertyId,
      customField: {
        fieldKey: patch.fieldKey,
        value: patch.value,
      },
    } as BHPropertyCustomFieldValuePatch;
    propertyService
      .updatePropertyCustomField(request)
      .then((property) => {
        if (property.updatedOn > updatedOn) {
          setSurvey({
            ...surveyRef.current,
            properties: surveyRef.current.properties.map((p) =>
              p.id !== property.id
                ? p
                : {
                  ...property,
                },
            ),
          });
        }
        // TODO: Fix the field rule
        /*
        const fieldRuleData = getCustomFieldRuleData(surveyRef.current, patch, propertyId);
        if (fieldRuleData && Object.keys(fieldRuleData).length) {
          const { propertyFieldRule, isHidden } = fieldRuleData;
          updatePropertyFieldRule({
            fieldId: propertyFieldRule?.fieldId,
            isHidden,
            order: propertyFieldRule?.order,
          });
        } */
      })
      .catch((err) => {
        logError('Failed to update property custom field value')(err);
        setSurvey({
          ...surveyRef.current,
          properties: oldProperties,
        });
      });
  };

  const selectProperty = (id?: string) => setSelectedPropertyId(id);

  const selectedProperty = useMemo(
    () =>
      (survey?.properties || []).find(
        (property) => property.id === selectedPropertyId,
      ),
    [survey, selectedPropertyId],
  );

  const reorderAvailability = ({
    source,
    destination,
    draggableId,
  }: DragResult) => {
    // console.log(source,destination,draggableId,'..............survey issue');

    const oldProperty = surveyRef.current.properties.filter(
      (property: any) => property.id === selectedPropertyId,
    )[0];

    availabilityService
      .reorderPropertyAvailability({
        propertyAvailabilityId: draggableId,
        order: destination.index,
        propertyId: selectedPropertyId as string,
      })
      .then((res) => {
        //  let newProperty: any = { ...oldProperty, propertyAvailabilities: res };
        oldProperty.propertyAvailabilities = res as any;
        setSelectedPropertyId(oldProperty.id);
        setSurvey({
          ...surveyRef.current,
          properties: [...surveyRef.current.properties],
        });
      })
      .catch((err: any) => {
        logError('Failed to update property  availability order value')(err);
        setSurvey({
          ...surveyRef.current,
          properties: surveyRef.current.properties,
        });
      });
  };

  const updateAvailabilityStatus = (availabilStatus: AvailabilityStatusPatch) => {
    const oldProperty = surveyRef.current.properties.filter(
      (property: any) => property.id === selectedPropertyId,
    )[0];

    availabilityService.updateAvailabilityStatus(availabilStatus).then((res) => {
      const currentAvailabilities: any = [...oldProperty.propertyAvailabilities];
      const targetAvailability = oldProperty.propertyAvailabilities
        .findIndex((availability: PropertyAvailabilitiesTypes) =>
          availability.id === availabilStatus.propertyAvailabilityId);
      currentAvailabilities[targetAvailability] = res;
      //   let newProperty: any = { ...oldProperty, propertyAvailabilities: currentAvailabilities };
      oldProperty.propertyAvailabilities = currentAvailabilities;
      setSurvey({
        ...surveyRef.current,
        properties: [...surveyRef.current.properties],
      });
    }).catch((err: any) => {
      logError('Failed to update property  availability order value')(err);
      setSurvey({
        ...surveyRef.current,
        properties: surveyRef.current.properties,
      });
    });
  };

  return {
    addAvailability,
    addProperty,
    deleteAvailability,
    deleteAvailabilities,
    deleteProperty,
    selectProperty,
    reorderAvailability,
    selectedProperty,
    updateAvailability,
    updateAvailabilityStatus,
    updateProperty,
    updatePropertyCustomField,
  };
}
