import { MEDIA_ACTION } from '../types/actionTypes';
import { MediaAction, MediaFiles, MediaState } from '../types/dataTypes';

const initialMediaState: MediaFiles = {};

const mediaReducer = (
  state: MediaState = initialMediaState,
  action: MediaAction,
): MediaState => {
  switch (action.type) {
    case MEDIA_ACTION.SET_MEDIA: {
      const { mediaFile } = action;
      return {
        ...state,
        ...mediaFile,
      };
    }
    case MEDIA_ACTION.UPDATE_MEDIA: {
      const { mediaFile } = action;
      return {
        ...state,
        ...mediaFile,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default mediaReducer;
