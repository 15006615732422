/* eslint-disable no-console */
import * as mediaService from '../../services/media-service';
import { GalleryFile } from '../../types/media-file';
import { MEDIA_ACTION } from '../types/actionTypes';
import { MediaAction, MediaDispatchType, MediaFiles } from '../types/dataTypes';

const resopnseHandler = (data: any, mediaType: string, ownerId: string) => {
  const mediaFiles: GalleryFile[] = data.media.map((propertyImg: any) => ({
    id: propertyImg.mediaId,
    url: propertyImg.cloudfrontUrl,
    displayText: propertyImg.displayName,
    active: !propertyImg.isHidden,
    primary: false,
    mediaType,
    ownerId,
  }));
  return mediaFiles;
};

const addMediaFilesAction = (payload: any) => {
  const { ids } = payload;
  return (dispatch: MediaDispatchType) => {
    mediaService
      .getActiveMediaFilesForSurvey({
        ids,
      })
      .then((data: any) => {
        const mediaFile: MediaFiles = data.propertyMedia ? data.propertyMedia : {};
        const action: MediaAction = {
          type: MEDIA_ACTION.SET_MEDIA,
          mediaFile,
        };
        dispatch(action);
      })
      .catch((error: any) => console.log(error));
  };
};

const updateMediaFilesAction = (payload: any) => {
  const { ids } = payload;
  return (dispatch: MediaDispatchType) => {
    mediaService
      .getActiveMediaFilesForSurvey({
        ids,
      })
      .then((data: any) => {
        let mediaFile: MediaFiles = {};
        if (Object.keys(data.propertyMedia).length > 0) {
          mediaFile = data.propertyMedia;
        } else {
          // eslint-disable-next-line no-unused-expressions
          Array.isArray(ids) && ids.forEach((item: string) => {
            mediaFile = {
              ...mediaFile,
              [item]: {
                images: [],
                floorPlans: [],
                fliers: [],
              },
            };
          });
        }
        const action: MediaAction = {
          type: MEDIA_ACTION.UPDATE_MEDIA,
          mediaFile,
        };
        dispatch(action);
      })
      .catch((error: any) => console.log(error));
  };
};

export default {
  addMediaFilesAction,
  updateMediaFilesAction,
};
