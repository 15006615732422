import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { match } from 'react-router';
import { useHistory } from 'react-router-dom';
/* eslint-disable-next-line */
import * as SurveyService from '../../services/survey-service';
import { PDFPaperSize } from '../../data/constants';
import { copyProperty, getCollaterals, getPropertyAPIState } from '../../services/property-service';
import { deliverableRootURL, mulesoftProxyUrl, mulesoftProxyId } from '../../config';
import { RouteParams } from '../../routes/routes';
import { getSFDCData } from '../../utilities';
import { PropertyId, RegisterMedia } from '../../types';
import * as mediaService from '../../services/media-service';
import BHSCAnalytics, { SC_EVENT, EVENT_TITLES } from '../../services/BHSCAnalytics';
import { getPropertyIds } from '../../utilities/property/propertyUtil';
import { any } from 'prop-types';

const BHCreateSurveyLoader: React.FC<Props> = ({ match: {
  params,
} }) => {
  const history = useHistory();
  const { data } = params as RouteParams;
  const globalIndex = window as any;

  useEffect(() => {
    const { payload, client } = getSFDCData(data || '');

    if (payload && client.oauthToken) {
      console.log('ds: canvasEventPayload', payload);
      const { opportunityId, companyName = '', properties, projectContacts = [], propertyType = '' } = payload;
      const contacts = projectContacts.map((item: string, order: number) => {
        const contact = JSON.parse(item);
        const { User: { Email: email = '', Name: name = '', Phone: phoneNumber = '', Title: title = '' } = {} } = contact;
        return { email, name, phoneNumber, title, order };
      });

      let { surveyId } = payload;

      globalIndex.Sfdc.canvas.client.publish(client, {
        name: 'cbrebrokerhub.app_loaded',
        payload: {},
      });
      const goNextPage = () => {
        if (data) {
          history.push(`/${surveyId}/builder/data/${data}`);
        } else {
          history.push(`/${surveyId}/builder`);
        }
      };
      const logTime = (startTime: any, endTime: any, survey: any) => {
        if (survey) {
          BHSCAnalytics.trackEvent(SC_EVENT, EVENT_TITLES.SC_SURVEY_CREATE_LOAD, {
            startTime,
            endTime,
            surveyId,
            projectId: survey.projectId,
            surveyName: survey.name,
            source: 'Button'
          });
        }
      };
      const copyProperties = (properties: any, startTime: string, survey: any) => {
        copyProperty(surveyId, properties).then((propNew: PropertyId[]) => {
          // copy media files
          getCollaterals({
            opportunityId,
            companyName,
            properties: getPropertyIds(properties) as any,
            projectContacts: contacts,
            client,
            onSuccess: (response: string) => {
              const result = JSON.parse(JSON.parse(response));
              console.log('ds: getCollateral ajax result', result);

              const { media } = result;
              console.log('ds: getCollateral ajax media', media);

              if (Array.isArray(media) && media.length > 0) {
                const registerMedia = media.map((images: RegisterMedia) => {
                  const { mediaType, displayName } = images;

                  const propertyId = propNew.find((item) => item.copyFromPropertyId === images.propertyId)?.propertyId;

                  const { S3url = '', SFurl = '' } = images;
                  let url = S3url;
                  if (!url) {
                    if (mediaType === 'FLOORPLANS' && displayName.includes('.pdf')) {
                      url = `${mulesoftProxyUrl}${btoa(SFurl)}${mulesoftProxyId}&file_type=.pdf`;
                    } else {
                      url = `${mulesoftProxyUrl}${btoa(SFurl)}${mulesoftProxyId}`;
                    }
                  }

                  return {
                    surveyId,
                    propertyId,
                    mediaType,
                    displayName,
                    url,
                    origin: 'BH',
                    order: images.order - 1,
                    orderType: 'hardOrder',
                  } as RegisterMedia;
                });

                console.log('ds: registerMediaFiles start with', registerMedia);
                mediaService.registerMediaFiles(registerMedia)
                  .then((res) => {
                    console.log('ds: registerMediaFiles end with', res);
                  })
                  .finally(() => {
                    logTime(startTime, new Date().toISOString(), survey);
                    goNextPage();
                  });
              } else {
                logTime(startTime, new Date().toISOString(), survey);
                goNextPage();
              }
            },
          });
        });
      };

      getPropertyAPIState().then(async () => {
        const startTime = new Date().toISOString();

        const survey = await addSurvey(companyName, contacts, propertyType);

        surveyId = survey.id as string;

        globalIndex.sfdcClient = client;
        globalIndex.Sfdc.canvas.client.publish(client, {
          name: 'cbrebrokerhub.saveSurvey_cbreone',
          payload: {
            surveyId,
            surveyTitle: 'Property Survey',
            surveyName: 'Untitled Survey',
            tourBook: false,
            passcode: survey.passcode,
            surveyUrl: `${deliverableRootURL}/${surveyId}`,
            oppId: opportunityId,
          },
        });
        copyProperties(JSON.parse(properties), startTime, survey);
      });
    } else {
      console.log('SFDC Oauth Client can not be empty!');
    }
  }, [data]);

  const addSurvey = (companyName: any, contacts: any, propertyType: any) => SurveyService.addSurvey({
    clientLogo: '',
    clientName: companyName,
    contacts,
    coverBackgroundImage: {
      mediaId: '',
      s3Url: '',
      cloudfrontUrl: '',
      displayName: '',
    },
    coverDate: '',
    coverSubTitle: '',
    coverTitle: '',
    customFields: [],
    customLegends: {
      hasDisplayLegendOnSeparatePage: false,
      displayDensity: 'small',
      displayFields: {
        hasIcon: true,
        hasPropertyName: true,
        hasAddressLine1: true,
        hasAddressLine2: true,
        hasCity: true,
        hasState: true,
        hasZipCode: true,
        hasGroupName: false,
        hasGroupIconOnlyAvailable: false,
      },
    },
    isDisplayClientLogo: false,
    isDisplayClientName: true,
    isIncludeFlyers: true,
    isIncludePhotos: true,
    isIncludeSitePlans: true,
    isIncludeHeader: true,
    isIncludeLegend: false,
    isClientShortlist: true,
    showCurrentLocation: true,
    marketMapName: 'Market Map',
    marketMapStyle: 'cbreLight',
    marketMapZoom: 100,
    name: 'Untitled Survey',
    passcode: '',
    marketMapBamData: '{"mapReset":true,"mapZoomLevel":4,"mapCentroid":[-95,40]}',
    pdfPaperSize: PDFPaperSize.Portrait,
    propertyComparisonLayout: 'Landscape',
    propertyDetailHighlightStats: [],
    propertyDetailMapZoom: 100,
    propertyMapStyle: 'cbreLight',
    propertyTableConfig: [],
    propertyPerPortraitComparisonPage: 4,
    properties: [],
    propertyType,
    sections: [],
    tourDetails: '',
    projectCreatedAt: '',
    user: {
      userIdList: [],
      authorIdList: [],
    },
    projectId: '',
    teamId: '',
    origin: 'BrokerHub',
  });

  return (
    <div>
      <h1 style={{ marginTop: 200, textAlign: 'center' }}><CircularProgress /></h1>
    </div>
  );
};

export type Props = {
  match: match;
};
export default BHCreateSurveyLoader;
