import { Property } from '../../types';
import { PROPERTY_ACTION } from '../types/actionTypes';
import { PropertyAction, PropertyBatchAction, PropertyBatchDispatchType, PropertyDispatchType, PropertyStatePatch, SetPropertyDispatchType, SetPropertyAction } from '../types/dataTypes';

const setPropertiesAction = (properties: Property[]) => (dispatch: PropertyBatchDispatchType) => {
  const action: PropertyBatchAction = {
    type: PROPERTY_ACTION.SET_ALL_PROPERTY,
    properties,
  };
  dispatch(action);
};

const setDrawerOpenAction = (payload: boolean,
) => (dispatch: SetPropertyDispatchType) => {
  const action: SetPropertyAction = {
    type: PROPERTY_ACTION.SET_DRAWER_OPEN,
    payload,
  };
  dispatch(action);
};

const updatePropertyAction = (property: PropertyStatePatch) => (dispatch: PropertyDispatchType) => {
  const action: PropertyAction = {
    type: PROPERTY_ACTION.UPDATE_PROPERTY,
    property,
  };
  dispatch(action);
};

const deletePropertyAction = (property: PropertyStatePatch) => (dispatch: PropertyDispatchType) => {
  const action: PropertyAction = {
    type: PROPERTY_ACTION.REMOVE_PROPERTY,
    property,
  };
  dispatch(action);
};

const reorderPropertyAction = (property: PropertyStatePatch) => (dispatch: PropertyDispatchType) => {
  const action: PropertyAction = {
    type: PROPERTY_ACTION.REORDER_PROPERTY,
    property,
  };
  dispatch(action);
};

export default {
  setPropertiesAction,
  updatePropertyAction,
  deletePropertyAction,
  reorderPropertyAction,
  setDrawerOpenAction,
};
