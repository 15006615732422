// this is not being used since the contacts list is returend as part of the survey object.
// import createQueryString, { QueryParamObj } from '@cbreone/utilities/dist/createQueryString';
import { axios } from '../internal-lib';
import { surveyServiceBaseURL } from '../config';
import { SurveyContactPost, SurveyContact, SurveyContactPatch } from '../types';
import onSuccess from './onSuccess';

export const addContact = (contact: SurveyContactPost) => axios.post<SurveyContact>(`${surveyServiceBaseURL}contacts/`, contact).then(onSuccess);

// this is not being used since the contacts list is returend as part of the survey object.
// export const getContacts = (surveyId: string, params?: QueryParamObj) => surveyAxios.get<SurveyContact[]>(`contacts/survey/${surveyId}/${createQueryString(params)}`).then(onSuccess);

// this is not being used since the contacts list is returend as part of the survey object.
// export const getContact = (contactId: string) => surveyAxios.get<SurveyContact>(`contacts/${contactId}`).then(onSuccess);

export const updateContact = (contactId: string, contact: SurveyContactPatch) => axios.patch<SurveyContact>(`${surveyServiceBaseURL}contacts/${contactId}`, contact).then(onSuccess);

export const deleteContact = (contactId: string) => axios.delete<string>(`${surveyServiceBaseURL}contacts/${contactId}`).then(onSuccess);
