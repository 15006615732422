import React, { useEffect, useState } from 'react';
import { match } from 'react-router';
import {
  IconButton, makeStyles, createStyles, Theme,
  Button, AppBar, Toolbar,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import * as SurveyService from '../../services/survey-service';
import { Props as SurveyAppProps } from '../../apps/SurveyApp';

import { RouteParams } from '../../routes/routes';
import { Survey } from '../../types';

const useStyles = ({
  top = 0,
  right = 0,
  left = 0,
} = {}) => makeStyles((theme: Theme) => createStyles({
  appBar: {
    background: theme.palette.grey[50],
    color: theme.palette.grey[800],
    marginTop: top,
    marginLeft: left,
    marginRight: right,
    width: `calc(100% - ${left}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: theme.spacing(1),
  },
}));
const SurveySummary: React.FC<Props> = ({
  match: {
    params,
  },
  position,
}) => {
  const classes = useStyles(position)();
  const [survey, setSurvey] = useState({} as Survey);
  const { id } = params as RouteParams;

  useEffect(() => {
    if (id) {
      SurveyService.getSurvey(id).then((res: any) => setSurvey(res));
    }
  }, [id]);

  if (!survey.id) {
    return null;
  }

  return (
    <div className="survey-summary">
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div>
            <IconButton onClick={() => window.history.back()}>
              <ChevronLeftOutlinedIcon />
            </IconButton>
          </div>
          {survey.name}
          <Button
            id="SC-OpenSurveyBuilder"
            variant="text"
            component={Link}
            to={`/${survey.id}/builder`}
          >
            Edit
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export type Props = {
  match: match;
  position: SurveyAppProps['position'];
};

SurveySummary.displayName = 'SurveySummary';
export default SurveySummary;
