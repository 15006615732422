import { internalTheme, Typography } from '@cbreone/core-ui';
import {
  createStyles, Grid, makeStyles, Theme,
} from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import React from 'react';
import { PDFPaperSize } from '../../data/constants';
import { Survey, SurveyContact } from '../../types';

export type Props = {
  surveyContact: SurveyContact;
  survey?: Survey;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  contactCard: {
    backgroundColor: theme.palette.grey['400'],
    color: theme.palette.grey['800'],
  },
  contactCardBottomBorder: {
    paddingBottom: theme.spacing(2),
    marginBottom: '8px',
  },
  contactName: {
    color: theme.palette.primary.main,
  },
  contactIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    fontSize: '0.8rem',
  },
  contactIconText: {
    alignItems: 'center',
  },
  portraitContactCardBottomBorder: {
    paddingBottom: '0px',
    marginBottom: '20px',
  },
  portraitContactName: {
    fontSize: '19px',
  },
  portraitTitleName: {
    fontSize: '14px',
    color: internalTheme.palette.text.secondary,
  },
  portraitEmail: {
    fontSize: '12px',
    color: internalTheme.palette.text.secondary,
  },
  seperator: {
    marginBlock: '0px',
    marginLeft: '-2px',
    marginRight: '-2px',
    border: '0px',
    borderBottom: `1px solid ${theme.palette.info.main}`,
    paddingTop: '20px',
  },
}));

const SurveyContactPreview: React.FC<Props> = ({ surveyContact, survey }) => {
  const isLandscapeMode = survey && survey.pdfPaperSize === PDFPaperSize.Landscape;
  const classes = useStyles();
  return (
    <div className={`${classes.contactCardBottomBorder} ${!isLandscapeMode ? classes.portraitContactCardBottomBorder : ''}`}>
      {surveyContact && (
        <Grid
          container direction="column" justifyContent="flex-start"
          alignItems="flex-start" spacing={1}
          className={classes.contactCard}
        >
          {surveyContact.name ? <Grid item xs={12}>
            <Typography variant="subtitle2" className={`${classes.contactName} ${!isLandscapeMode ? classes.portraitContactName : ''}`}>
              {surveyContact.name}
            </Typography>
          </Grid>
            : null}
          {surveyContact.title ? <Grid item xs={12} >
            <Typography variant="body3" className={`${!isLandscapeMode ? classes.portraitTitleName : ''}`}>
              {surveyContact.title}
            </Typography>
          </Grid>
            : null}
          {surveyContact.phoneNumber ? <Grid
            className={classes.contactIconText}
            item container direction="row"
            xs={12}
          ><CallIcon className={classes.contactIcon} />
            <Typography variant="body4" className={`${!isLandscapeMode ? classes.portraitEmail : ''}`}>
              {surveyContact.phoneNumber}
            </Typography>
          </Grid>
            : null}

          {surveyContact.email ? <Grid
            className={classes.contactIconText}
            item container direction="row"
            xs={12}><EmailIcon className={classes.contactIcon} />
            <Typography variant="body4" className={`${!isLandscapeMode ? classes.portraitEmail : ''}`}>
              {surveyContact.email}
            </Typography>
          </Grid>
            : null}

        </Grid>
      )}
      <hr className={`${!isLandscapeMode ? classes.seperator : ''}`} />
    </div>
  );
};

SurveyContactPreview.displayName = 'ContactPreview';
export default React.memo(SurveyContactPreview);
