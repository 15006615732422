import React from 'react';
import { parseInputChange } from '@cbreone/utilities';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Property, Survey } from '../../types';
import PropertyInfoForm from './PropertyInfoForm';
import PropertyAddressForm from './PropertyAddressForm';
import AdditionalInfoForm from './AdditionalInfoForm';
import CustomFieldsForm from './CustomFieldsForm';
import { UseSurveyEditorAPI } from '../../pages/SurveyBuilder/useSurveyEditorAPI';

const useStyles = makeStyles((theme: Theme) => ({
  v_spacer: {
    height: theme.spacing(3),
  },
}));

// new component for property details
const PropertyDetails: React.FC<Props> = ({
  survey,
  property,
  onUpdateProperty,
  onAddCustomField,
  onDeleteCustomField,
  onUpdateAvailability,
  onUpdateCustomField,
  onUpdatePropertyCustomField,
}) => {
  const classes = useStyles();

  const handlePropertyUpdate = (event: React.ChangeEvent<HTMLTextAreaElement
     | HTMLInputElement>) => {
    const { name, value } = parseInputChange(event.target);
    onUpdateProperty({
      id: property.id,
      [name]: value,
    });
  };

  const handleCoordinatesUpdate = (latitude: string, longitude: string) => {
    onUpdateProperty({
      id: property.id,
      latitude,
      longitude,
    });
  };

  const handleZoomAndCentroidUpdate = (bamMapData: any) => {
    if (bamMapData) {
      onUpdateProperty({
        id: property.id,
        bamMapData: JSON.stringify(bamMapData),
      });
    }
  };

  return (
    <>
      <div className={classes.v_spacer} />
      <PropertyAddressForm
        property={property}
        handlePropertyUpdate={handlePropertyUpdate}
        handleCoordinatesUpdate={handleCoordinatesUpdate}
        handleZoomAndCentroidUpdate={handleZoomAndCentroidUpdate}
      />
      <div className={classes.v_spacer} />
      <PropertyInfoForm
        property={property}
        handlePropertyUpdate={handlePropertyUpdate}
      />
      <div className={classes.v_spacer} />
      <AdditionalInfoForm
        property={property}
        handlePropertyUpdate={handlePropertyUpdate}
      />
      <div className={classes.v_spacer} />
      <CustomFieldsForm
        property={property}
        survey={survey}
        onAddSurveyCustomField={onAddCustomField}
        onDeleteSurveyCustomField={onDeleteCustomField}
        onUpdateSurveyCustomField={onUpdateCustomField}
        onUpdatePropertyCustomField={onUpdatePropertyCustomField}
      />
    </>
  );
};

export type Props = {
  survey: Survey;
  property: Property;
  onUpdateProperty: UseSurveyEditorAPI['updateProperty'];
  onAddAvailability: UseSurveyEditorAPI['addAvailability'];
  onDeleteAvailability: UseSurveyEditorAPI['deleteAvailability'];
  onUpdateAvailability: UseSurveyEditorAPI['updateAvailability'];
  onAddCustomField: UseSurveyEditorAPI['addCustomField']
  onDeleteCustomField: UseSurveyEditorAPI['deleteCustomField']
  onUpdateCustomField: UseSurveyEditorAPI['updateCustomField']
  onUpdatePropertyCustomField: UseSurveyEditorAPI['updatePropertyCustomField']
};

PropertyDetails.displayName = 'PropertyDetails';
export default PropertyDetails;
