import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';
import { Property, Survey } from '../../types';
import { MediaState } from '../../redux/types/dataTypes';
import PropertyPreview from '.';
import PropertyImagePreview from './PropertyImagePreview';
import PropertySitePlanPreview from './PropertySitePlanPreview';
import PropertyFlyerPreview from './PropertyFlyerPreview';
import { checkValueIsUnavailable } from '../../utilities';

const PropertyPreviewWrapper: React.FC<Props> = ({
  survey,
  propertyIdPreview,
  order,
  anchore,
  pageMode,
  isDrawerOpen,
  floorPlanIndex,
}) => {
  const [properties, setProperties] = useState<Property[]>([]);
  const [idMap, setIdMap] = useState<any>({});
  const loadedIdMap = useRef<any>({});
  useEffect(() => {
    let propertyList = [];
    if (propertyIdPreview) {
      propertyList = survey.properties.filter(
        (item: Property) =>
          (item.isHidden === undefined || item.isHidden === false) &&
          item.id === propertyIdPreview,
      );
    } else {
      propertyList = survey.properties.filter(
        (item: Property) =>
          (item.isHidden === undefined || item.isHidden === false) && !item.isCurrentLocation,
      );
    }
    if (!isDrawerOpen) setProperties(propertyList);
  }, [survey.properties, propertyIdPreview, isDrawerOpen]);

  const getPropertyOrder = () => {
    if (!checkValueIsUnavailable(propertyIdPreview)) {
      const newOrder =
        survey.properties
          .filter((item) => !item.isHidden && !item.isCurrentLocation)
          .sort((first, second) => first.order - second.order)
          .findIndex((item) => item.id === propertyIdPreview) + 1;

      return newOrder.toString();
    }
    return '1';
  };

  const getOrder = (i: number) =>
    checkValueIsUnavailable(propertyIdPreview)
      ? (i + 1).toString()
      : getPropertyOrder();

  const onChange = (e: boolean, id: string) => {
    if (e) {
      const ids = {
        ...loadedIdMap.current,
        [id]: true,
      };
      loadedIdMap.current = ids;
      setIdMap(loadedIdMap.current);
    }
  };
  return (
    <>
      {properties.map((property: Property, index: number) => !floorPlanIndex ?
        <React.Fragment key={`${property.id}-siteplan-${index}`}>
          <VisibilitySensor onChange={(e) => onChange(e, property.id)}>
            <div style={{ visibility: 'hidden', height: propertyIdPreview ? 0 : 10 }}>-</div>
          </VisibilitySensor>
          <PropertyPreview
            propertyOrder={getOrder(index)}
            key={`${property.id}-detail-${index}`}
            survey={survey}
            pageMode={pageMode}
            property={property}
            anchore={anchore}
            order={order}
            isDrawerOpen={isDrawerOpen}
          />
          {survey.isIncludePhotos && (
            <PropertyImagePreview
              key={`${property.id}-images-${index}`}
              property={property}
              pageMode={pageMode}
              propertyOrder={getOrder(index)}
              pdfPaperSize={survey.pdfPaperSize}
              isVisible={propertyIdPreview ? true : idMap[property.id]}
            />
          )}
          {survey.isIncludeSitePlans && !propertyIdPreview && (
            loadedIdMap.current[property.id] && <PropertySitePlanPreview
              key={`${property.id}-siteplan-${index}`}
              property={property}
              pageMode={pageMode}
              propertyOrder={getOrder(index)}
              pdfPaperSize={survey.pdfPaperSize}
              isVisible={propertyIdPreview ? true : idMap[property.id]}
            />
          )}
          {survey.isIncludeFlyers && !propertyIdPreview && (
            loadedIdMap.current[property.id] && <PropertyFlyerPreview
              key={`${property.id}-flyer-${index}`}
              property={property}
              propertyOrder={getOrder(index)}
              pdfPaperSize={survey.pdfPaperSize}
              isVisible={propertyIdPreview ? true : idMap[property.id]}
            />
          )}
        </React.Fragment>
        :
        <PropertySitePlanPreview
          key={`${property.id}-siteplan-${index}`}
          property={property}
          pageMode={pageMode}
          propertyOrder={getOrder(index)}
          pdfPaperSize={survey.pdfPaperSize}
          isVisible={propertyIdPreview ? true : idMap[property.id]}
          floorPlanIndex={floorPlanIndex}
        />)}
    </>
  );
};

export type Props = {
  survey: Survey;
  pageMode?: string;
  propertyIdPreview?: string;
  order: string;
  anchore?: object;
  surveyState: Survey;
  mediaState: MediaState;
  isDrawerOpen: boolean;
  floorPlanIndex?: number;
};

const mapStateToProps = (state: any, ownProps: any) => ({
  surveyState: state.survey,
  mediaState: state.media,
  isDrawerOpen: state.properties.isDrawerOpen,
  ...ownProps,
});

PropertyPreviewWrapper.displayName = 'PropertyPreviewWrapper';
export default React.memo(connect(mapStateToProps)(PropertyPreviewWrapper));
