import { Property, PropertyPatch, Survey } from '../../types';
import { PROPERTY_ACTION } from '../types/actionTypes';
import { PropertyAction, PropertyBatchAction, SetPropertyAction } from '../types/dataTypes';
import * as propertyService from '../../services/property-service';
import * as surveyService from '../../services/survey-service';

const initialPropertyState = {
  properties: [] as Property[],
  isDrawerOpen: false,
};

const propertyReducer = (
  state = initialPropertyState,
  action: PropertyAction & PropertyBatchAction & SetPropertyAction,
) => {
  switch (action.type) {
    case PROPERTY_ACTION.SET_DRAWER_OPEN: {
      return {
        ...state,
        isDrawerOpen: action.payload,
      };
    }
    case PROPERTY_ACTION.SET_ALL_PROPERTY: {
      const { properties } = action;
      return {
        ...state,
        properties,
      };
    }
    case PROPERTY_ACTION.UPDATE_PROPERTY: {
      const newProperties = state.properties.map((property: Property) => {
        if (property.id === action.property.id) {
          return {
            ...property,
            ...action.property,
          };
        }
        return property;
      });
      const oldProperties = [...state.properties];
      propertyService.updateProperty({
        ...action.property,
      } as PropertyPatch).then().catch(() =>
        ({ ...state, oldProperties }),
      );
      return {
        ...state,
        properties: [...state.properties, newProperties],
      };
    }
    case PROPERTY_ACTION.REMOVE_PROPERTY: {
      const oldProperties = [...state.properties];
      // eslint-disable-next-line max-len
      const newProperties = state.properties.filter((property: Property) => property.id !== action.property.id);
      if (action.property.id) {
        propertyService.deleteProperty({
          propertyId: action.property.id,
          ownerId: action.property.ownerId || '',
          ownerType: action.property.ownerType || '',
        }).then().catch(() => [
          ({ ...state, oldProperties }),
        ]);
      }
      return {
        ...state,
        properties: [...state.properties, newProperties],
      };
    }
    case PROPERTY_ACTION.REORDER_PROPERTY: {
      const oldProperties = [...state.properties];
      // eslint-disable-next-line max-len
      if (action.property.order !== undefined && action.property.order >= 0) {
        surveyService.reorderPatch('property/reorder', {
          order: action.property.order,
          surveyId: `${action.property.surveyId}`,
        // eslint-disable-next-line arrow-body-style
        }).then((res: Survey) => (
          {
            ...state,
            properties: [...state.properties, res.properties],
          }),
        ).catch(() => ({ ...state, properties: [...state.properties, oldProperties],
        }),
        );
      } else {
        return {
          ...state,
          properties: [...state.properties, oldProperties],
        };
      }
    }

    // eslint-disable-next-line no-fallthrough
    default: {
      return {
        ...state,
      };
    }
  }
};

export default propertyReducer;
