import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Property } from '../../types';
import FileGallery from '../PropertyDetail/FileGallery';
import { UseSurveyEditorAPI } from '../../pages/SurveyBuilder/useSurveyEditorAPI';
import { MediaFileType } from '../../data/constants';

const useStyles = makeStyles((theme: Theme) => ({
  v_spacer: {
    height: theme.spacing(3),
  },
}));

const PropertyCollateral: React.FC<Props> = ({
  property,
  onUpdateMedia,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.v_spacer} />
      <FileGallery
        property={property}
        fileType={MediaFileType.images}
        acceptedFiles='.jpg,.jpeg,.png'
        galleryTitle='Photos'
        addFilePrompt='Add Photo'
        onUpdateMedia={onUpdateMedia}
      />
      <div className={classes.v_spacer} />
      <FileGallery
        property={property}
        fileType={MediaFileType.floorplans}
        acceptedFiles='.jpg,.jpeg,.png,.pdf'
        galleryTitle='Floorplans'
        addFilePrompt='Add Floorplan'
        onUpdateMedia={onUpdateMedia}
      />
      <div className={classes.v_spacer} />
      <FileGallery
        property={property}
        fileType={MediaFileType.fliers}
        acceptedFiles='.pdf'
        galleryTitle='Flyers'
        addFilePrompt='Add Flyers'
        onUpdateMedia={onUpdateMedia}
      />
    </>
  );
};

export type Props = {
  property: Property;
  onUpdateMedia: UseSurveyEditorAPI['updateMedia'];
};

PropertyCollateral.displayName = 'PropertyCollateral';
export default React.memo(PropertyCollateral);
