import { externalTheme } from '@cbreone/core-ui';
import {
  Checkbox,
  FormControlLabel, FormGroup,
  Link,
  Theme,
  Typography,
  createStyles, makeStyles,
} from '@material-ui/core';
import React from 'react';
import { Survey } from '../../types';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  link: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  icon: {
    color: externalTheme.palette.primary.main,
    verticalAlign: 'middle',
    marginLeft: '-10px',
  },
  customWidth: {
    maxWidth: 200,
    backgroundColor: theme.palette.common.black,
    fontFamily: 'Calibre-R',
    fontSize: '14px',
    lineHeight: '21px',
    alignItems: 'center',
  },
  arrow: {
    color: theme.palette.common.black,
  },
}));
const PropertiesSectionForm: React.FC<Props> = ({
  survey,
  onSurveyUpdate,
  onOpenEditColumnsDrawer,
}) => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Typography
        variant="caption" align="center" className={(classes.marginBottom)}
        component="div">
        Change the order and visibility of property and
        availability attributes that display on this page
        <Link
          data-testid="properties-drawer-link"
          onClick={() => onOpenEditColumnsDrawer()}
          className={classes.link}>Manage Attributes</Link>

      </Typography>
      {/* @Todo "post-pilot" work to reference for later. */}

      {/*
      <FormControl component="fieldset">
        <FormLabel component="legend">Map Style</FormLabel>
        <RadioGroup
          aria-label="property-map-style-label"
          name="propertyMapStyle"
          value={survey.propertyMapStyle}
          onChange={onSurveyUpdate}
        >
          <FormControlLabel value="Aerial" control={<Radio />} label="Aerial" />
          <FormControlLabel value="Road" control={<Radio />} label="Road" />
        </RadioGroup>
      </FormControl>  */}

      {/* @Todo "post-pilot" work to reference for later. */}
      {/*
      <FormControl
        fullWidth
        className={classes.marginBottom}
        variant="outlined"
      >
        <InputLabel id="property-detail-map-zoom-label">Map Zoom</InputLabel>
        <Select
          labelId="property-detail-map-zoom-label"
          id="property-detail-map-zoom-select"
          value={survey.propertyDetailMapZoom}
          name="propertyDetailMapZoom"
          onChange={onSurveyUpdate}
          label="Map Zoom"
        >
          <MenuItem value="100">Best Fit</MenuItem>
        </Select>
      </FormControl>
      */}

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={survey.isIncludePhotos}
              name="isIncludePhotos"
              onChange={onSurveyUpdate}
            />
          }
          label="Show Extra Photos"
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={survey.isIncludeSitePlans}
              name="isIncludeSitePlans"
              onChange={onSurveyUpdate}
            />
          }
          label="Show Floorplan"
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={survey.isIncludeFlyers}
              name="isIncludeFlyers"
              onChange={onSurveyUpdate}
            />
          }
          label="Show Property Flyer"
        />
      </FormGroup>

      {/* @Todo "post-pilot" work to reference for later. */}
      {/*  <Button variant="text" startIcon={<AddIcon />}>
        Featured Statistic
      </Button> */}
    </section>
  );
};

export type Props = {
  onSurveyUpdate: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | {
    name: string | undefined;
    value: unknown;
  }>, checkedOrChild?: boolean | React.ReactNode) => void;
  survey: Survey;
  onOpenEditColumnsDrawer: () => void;
};

PropertiesSectionForm.displayName = 'PropertiesSectionForm';
export default React.memo(PropertiesSectionForm);
