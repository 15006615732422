/* eslint-disable no-lonely-if */
import React, { useEffect, useState, ReactText, useMemo, ReactElement } from 'react';
import {
  createStyles, makeStyles,
  Paper,
  Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Link,
} from '@material-ui/core';
import { Typography } from '@cbreone/core-ui';
import { Property, Survey } from '../../types';
import Header from '../PDFCommon';
import PropertyFeatureImage from '../PropertyFeatureImage';
import SurveyPDFPage from '../SurveyPDFPage';
import { getFullAddressStr } from '../../utilities';
import { PDFPaperSize, linkAttributes } from '../../data/constants';
import Footer from '../Footer';
import { BHPropertyFieldRule } from '../../types/bh-property-field-rule';
import { getPropertyValueForDisplay2 } from '../../utilities/property';

const useStyles = makeStyles((theme) => createStyles({
  tableCellRoot: {
    borderBottom: 'none',
    fontSize: '9.5px',
    padding: '4px 6px 4px 6px',
    whiteSpace: 'pre-line',
    width: '95px',
    maxWidth: '95px',
    wordWrap: 'break-word',
    '&:first-child': {
      paddingLeft: '18px',
    },
    '&:last-child': {
      paddingRight: '18px',
    },
    verticalAlign: 'top',
  },
  container: {
    boxShadow: 'none',
    borderRadius: '0px',
    width: 'auto',
    marginLeft: '31px',
    marginRight: '31px',
  },
  additionalContainer: {
    boxShadow: 'none',
    borderRadius: '0px',
    paddingTop: '24px',
    width: 'auto',
    marginLeft: '31px',
    marginRight: '31px',
  },
  imgCell: {
    paddingTop: '16px',
  },
  oddRow: {
    background: theme.palette.common.white,
  },
  evenRow: {
    background: theme.palette.grey['100'],
  },
  nameCell: {
    fontWeight: 'bold',
  },
  pageContainerLandscapeNoHeaderHeight: {
    height: '8in',
  },
  pageContainerLandscapeHeaderHeight: {
    height: '7in',
  },
  pageContainePortraitNoHeaderHeight: {
    height: '9.5in',
  },
  pageContainerPortraitHeaderHeight: {
    height: '10.5in',
  },
  portraitTxtStyle: {
    fontSize: '10px',
  },
  landscapeTxtStyle: {
    fontSize: '8px',
  },
  footer: {
    width: '100%',
    backgroundColor: '#cccccc',
    height: '0.5in',
  },
  link: {
    fontStyle: 'normal',
    fontFamily: 'Calibre-R',
    letterSpacing: '0.1px',
    wordWrap: 'break-word',
  },
  emptyStateMessage: {
    margin: '20px',
  },
}));

const PropertyComparisonSheet: React.FC<Props> = ({
  activeAddressKeys,
  activePropertyFieldRuleList,
  firstPage,
  pageMode,
  properties,
  propertiesPerPage,
  survey,
  isNameHidden,
}) => {
  const classes = useStyles();
  let curHeight = 94; // header height
  const landscapeMode = survey.pdfPaperSize === PDFPaperSize.Landscape;
  const maxPageHeight = landscapeMode ? 710 : 950; // pdf page height
  let triggerCount = 0;
  const [isLoading, setIsLoading] = useState(true);
  const [extraFieldsPages, setExtraFieldsPages] = useState([] as any[][]);

  const extraFieldsPagesTmp: any[][] = [];
  let currentPageIdx = 0;
  useEffect(() => {
    setIsLoading(true);
  }, [survey]);

  const propertyOrders = useMemo(() => {
    let index = 0;
    return survey.properties.reduce((acc: any, property: any) => {
      if (property.isHidden || property.isCurrentLocation) {
        return { ...acc };
      }
      index += 1;
      return {
        ...acc,
        [property.id]: index,
      };
    }, {} as { [x: string]: ReactText | ReactElement; });
  }, [survey.properties]);

  const addToRefs = (el: HTMLTableRowElement, row?: BHPropertyFieldRule) => {
    if (!isLoading) {
      return;
    }
    triggerCount += 1;
    if (el) {
      const index = Math.trunc((el.offsetHeight + curHeight + 24) / maxPageHeight);
      if (row) {
        // the first row of the next page
        if (index > currentPageIdx) {
          // calculate the remaining space of the first page.
          curHeight = index * maxPageHeight;
        }

        const currentLoop: any[] = extraFieldsPagesTmp[index] || [];
        extraFieldsPagesTmp[index] = [...currentLoop, row];
        currentPageIdx = index;
      }
      if (index <= currentPageIdx) {
        curHeight += el.offsetHeight;
      }
    }
    if (activePropertyFieldRuleList.length === triggerCount) {
      setIsLoading(false);
      setExtraFieldsPages(extraFieldsPagesTmp);
    }
  };

  const displayPropertyFieldRuleRow = (row: BHPropertyFieldRule, index: number) => (
    <>
      {<TableRow key={`${row.fieldKey}_fieldrow_${index}`} ref={(node) => (node ? addToRefs(node, row) : null)} className={`${index % 2 ? classes.evenRow : classes.oddRow}`}>
        {firstPage
          && <TableCell component="th" classes={{ root: classes.tableCellRoot }}>
            <Typography variant="body4" className={textClass}>
              {row.fieldLabel}
            </Typography>
          </TableCell>
        }
        {
          properties.map((property) => {
            const attrKey = row.fieldKey;
            const data = property as Property & { [x: string]: any; };
            const { customFields } = data;
            let attrValue = '';
            let customField = null;
            if (Array.isArray(customFields) && customFields.length > 0) {
              customField = customFields.find((field) => (
                field.fieldKey === row.fieldKey));
            }
            if (customField) {
              attrValue = customField?.value || '';
            } else {
              // Property Attribute Formatting
              attrValue = getPropertyValueForDisplay2(attrKey, data);
            }
            return (
              <TableCell
                key={`${property.id}_attribute_${row.fieldLabel}`} classes={{ root: classes.tableCellRoot }} component="th"
                scope="row"
              >
                {(linkAttributes.includes(row.fieldLabel) && !!attrValue) ?
                  <Link href={attrValue} className={`${classes.link} ${textClass}`}>
                    {attrValue}
                  </Link> :
                  <Typography variant="body4" className={textClass}>
                    {attrValue || '—'}
                  </Typography>}
              </TableCell>
            );
          })
        }
        {emptyCells}
      </TableRow>}
    </>
  );

  const numEmptyCol = (properties.length % propertiesPerPage === 0) ? '' : propertiesPerPage
    - (properties.length % propertiesPerPage);

  const emptyCells = (
    numEmptyCol ? [...Array(numEmptyCol)].map((item, i) => (
      <TableCell key={`empty${i}_${currentPageIdx}`} classes={{ root: classes.tableCellRoot }} />
    )) : null
  );

  const pageHeightClassName = landscapeMode ? classes.pageContainerLandscapeHeaderHeight
    : classes.pageContainePortraitNoHeaderHeight;
  const extraPageHeightClassName = landscapeMode ?
    classes.pageContainerLandscapeNoHeaderHeight : classes.pageContainerPortraitHeaderHeight;
  const textClass = landscapeMode ? classes.landscapeTxtStyle : classes.portraitTxtStyle;
  const hiddenAddressKey = activeAddressKeys?.filter((item) => item !== '').length === 0;

  return (
    <>
      <SurveyPDFPage mode={pageMode} paperSize={survey.pdfPaperSize} key={`Comparison-Preview-${currentPageIdx}`}>
        <Header title={firstPage ? 'Property Comparison' : ''} />
        <TableContainer className={`${classes.container} ${pageHeightClassName}`} component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow id='firstRow' ref={(node) => (node ? addToRefs(node) : null)}>
                {firstPage && <TableCell classes={{ root: classes.tableCellRoot }} />}
                {
                  // Display property feature image row
                  properties.map((property) => (
                    <TableCell
                      key={`${property.id}_feature_row`}
                      classes={{ root: classes.tableCellRoot }}
                      className={classes.imgCell}
                    >
                      <PropertyFeatureImage
                        isShowIcon={true}
                        order={propertyOrders[property.id]}
                        propertyId={property.id}
                        survey={survey}
                        size="thumbnail"
                      />
                    </TableCell>))
                }
                {emptyCells}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                !properties.length ?
                  <Typography variant="body2" className={classes.emptyStateMessage} >
                    In order to see property attributes,
                    please add a property with information associated.
                  </Typography> :
                  <React.Fragment>{
                    !isNameHidden ?
                      <TableRow key={'name'} className={classes.oddRow} ref={(node) => (node ? addToRefs(node) : null)}>
                        {firstPage
                          && <TableCell component="th" classes={{ root: classes.tableCellRoot }}>
                            <Typography variant="body4" className={textClass}>
                              Name
                            </Typography>
                          </TableCell>
                        }
                        {
                          // Display property name row
                          properties.map((property) => (
                            <TableCell
                              key={`${property.id}_name_row`}
                              classes={{ root: classes.tableCellRoot }}
                              component="th"
                              scope="row"
                            >
                              <Typography variant="body4" className={`${classes.nameCell} ${textClass}`}>
                                {property.name || '—'}
                              </Typography>
                            </TableCell>
                          ))
                        }
                        {emptyCells}
                      </TableRow> : null}
                    {!hiddenAddressKey &&
                      <TableRow key={'address'} className={classes.evenRow} ref={(node) => (node ? addToRefs(node) : null)}>
                        {firstPage
                          && <TableCell component="th" classes={{ root: classes.tableCellRoot }}>
                            <Typography variant="body4" className={textClass}>
                              Address
                            </Typography>
                          </TableCell>
                        }
                        {
                          // Display property address row
                          properties.map((property) => {
                            const propValues: [string, string, string, string] = [
                              property[activeAddressKeys[0] as keyof Property] as string,
                              property[activeAddressKeys[1] as keyof Property] as string,
                              property[activeAddressKeys[2] as keyof Property] as string,
                              property[activeAddressKeys[3] as keyof Property] as string,
                            ];
                            const fullAddressStr = getFullAddressStr(...propValues);
                            return (
                              <TableCell
                                key={`${property.id  }_address_row`}
                                classes={{ root: classes.tableCellRoot }}
                                component="th"
                                scope="row"
                              >
                                <Typography variant="body4" className={textClass}>
                                  {fullAddressStr || '—'}
                                </Typography>
                              </TableCell>
                            );
                          })
                        }
                        {emptyCells}
                      </TableRow>
                    }
                    { // Display the rest of the property attribute rows
                      isLoading && activePropertyFieldRuleList
                        .map((row, index) => (
                          displayPropertyFieldRuleRow(row, index)
                        ))
                    }
                    { // Display first page 's attributes
                      !isLoading && extraFieldsPages[0] && extraFieldsPages[0].length > 0
                      && extraFieldsPages[0].map((row, index) => (
                        displayPropertyFieldRuleRow(row, index)
                      ))
                    }
                  </React.Fragment>
              }
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.footer} ><Footer></Footer></div>
      </SurveyPDFPage>
      {
        !isLoading && extraFieldsPages.length > 0 && extraFieldsPages.map(
          (rows, pageIndex) => {
            if (pageIndex !== 0) {
              return (
                <SurveyPDFPage mode={pageMode} paperSize={survey.pdfPaperSize}>
                  <TableContainer className={`${classes.additionalContainer} ${extraPageHeightClassName}`} component={Paper}>
                    <Table size="small">
                      <TableBody>
                        {
                          rows
                            .map((row, index) => (
                              displayPropertyFieldRuleRow(row, (index - 1))
                            ))
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className={classes.footer} ><Footer></Footer></div>
                </SurveyPDFPage>);
            }
            return (<></>);
          },
        )
      }
    </>
  );
};

export type Props = {
  activePropertyFieldRuleList: BHPropertyFieldRule[];
  activeAddressKeys: string[];
  firstPage: boolean;
  pageMode?: string;
  properties: Property[];
  propertiesPerPage: number;
  survey: Survey;
  isNameHidden: any;
};

PropertyComparisonSheet.displayName = 'PropertyComparisonSheet';
export default React.memo(PropertyComparisonSheet);
