import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Survey } from '../../types';
import TourScheduleSheet from './TourScheduleSheet';

const TourSchedulePreview: React.FC<Props> = ({
  survey,
  pageMode,
  order,
  anchore,
}) =>
  // let schedule: ScheduleItems[] = [];
  // TODO: populate schedule from whatever structure needs to be for schedule items from Survey
  (
    <div id={`${order}`} style={anchore}>
      <TourScheduleSheet
        firstPage={true}
        key={order}
        pageMode={pageMode}
        survey={survey}
      />
    </div>
  );
export type Props = {
  survey: Survey;
  pageMode?: string;
  order: string;
  anchore?: object;
};

TourSchedulePreview.displayName = 'TourSchedulePreview';
export default TourSchedulePreview;
