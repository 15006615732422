/* eslint-disable @typescript-eslint/no-unused-expressions */
import TextField from '@cbreone/core-ui/dist/components/DebouncedTextField';
import DragAndDropList from '@cbreone/core-ui/dist/components/DragAndDropList';
import DraggableListItem from '@cbreone/core-ui/dist/components/DraggableListItem';
import { parseInputChange } from '@cbreone/utilities';
import { Accordion, AccordionDetails, AccordionSummary, Button, FormControlLabel, FormGroup, Grid, IconButton, Switch, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { debounce } from 'lodash';
import React, { ReactElement, ReactText, useEffect, useMemo, useState } from 'react';
import { UsePropertyAPI } from '../../pages/SurveyBuilder/usePropertiesAPI';
import { UseSurveyEditorAPI } from '../../pages/SurveyBuilder/useSurveyEditorAPI';
import { Property, PropertyAvailabilitiesTypes, Survey } from '../../types';
import AvailabilitiesInformation from './AvailabilitiesInformation';

const commonSectionStyles = {
  marginTop: '0 !important',
  marginBottom: '0 !important',
  boxShadow: 'none',
  minHeight: 'initial !important',
  transitionDuration: '0ms',
  '&:before': {
    top: 0,
    height: 0,
    backgroundColor: 'transparent',
    transition: 'none',
  },
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  v_spacer: {
    height: theme.spacing(2),
  },
  header: {
    padding: `0 ${theme.spacing(1)}px`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  propertyCardName: {
    alignSelf: 'center',
    cursor: 'pointer',
  },
  propertyIndex: {
    marginRight: '12px',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '28px',
  },
  propertyName: {
    fontSize: '16px',
    fontweight: 400,
    lineHeight: '24px',
    color: '#003F2D',
  },
  propertyCardControls: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
    alignItems: 'center',
  },
  FormLabel: {
    '& .MuiTypography-body1': {
      marginRight: '12px',
    },
    '& .MuiSwitch-sizeSmall': {
      marginRight: '15px',
    },
  },
  floorName: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#003F2D',
    width: '100%',

  },
  sectionRoot: commonSectionStyles,
  sectionExpanded: {
    ...commonSectionStyles,
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
  },
  sectionSummaryRoot: {
    padding: 0,
    transition: 'none',
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },

  },
  expandIcon: {
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
  },
  sectionSummaryContent: {
    margin: 0,
  },
  sectionDetails: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    paddingLeft: 0,
  },
  changeAvailabilityTitle: {
    // width: '100%',
    borderBottom: '1px solid rgba(26, 26, 26, 0.6)',
    '& .MuiFilledInput-input': {
      padding: 0,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      height: '24px',
      width: '100%',
    },
    '& .MuiFilledInput-inputMarginDense': {
      padding: 0,
    },
    '& label': {
      // marginTop: "-11px"
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: 'none',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-marginDense': {
      transform: 'none',
    },
    '& .MuiSwitch-sizeSmall': {
      marginRight: '15px',
    },
  },
  ToggleButton: {
    '& .MuiSwitch-sizeSmall': {
      marginRight: '15px',
    },
  },

}));

const PropertyAvailabilities: React.FC<Props> = ({
  survey,
  property,
  onReorder,
  onUpdateProperty,
  onUpdateAvailabilityStatus,
  onUpdateAvailability,
  deleteAvailabilities,
  onAddAvailability,

}) => {
  const [openSections, setOpenSections] = useState<{ [x: string]: boolean; }>({});
  const [open, setOpen] = useState(false);
  const [currentAccId, setCurrentAccId] = useState();
  const classes = useStyles();
  const [availabilities, setAvailabilities]: any =
    useState(property?.propertyAvailabilities?.sort((first: any, second: any) =>
      first.order - second.order) || []);

  useEffect(() => {
    setAvailabilities(property?.propertyAvailabilities?.sort((first: any, second: any) =>
      first.order - second.order) || []);
  }, [property.propertyAvailabilities]);

  // handles accordion expanded state since drag and drop causes them to rerender and lose the state internally
  const handleAccordionExpandChange = (id: string | any) => (
    e: React.ChangeEvent<{}>, expanded: boolean,
  ) => {
    setOpenSections({ ...openSections, [id]: expanded });
  };

  const [availabilityStatus, setAvailabilityStatus] = useState<any>(
    property.propertyAvailabilities?.reduce((acc, availability: any) => (
      { ...acc, [availability.id]: availability.status === 'ACTIVE' }), {} as { [x: string]: any; },
    ));

  const Clicked = (e: any) => {
    setCurrentAccId(e);
    setOpen(!open);
    return open;
  };

  const propertyIndexMap = useMemo(() => {
    let index = 0;
    return property.propertyAvailabilities.reduce((acc, property: PropertyAvailabilitiesTypes) => {
      if (property.status === 'INACTIVE') {
        return { ...acc, [property.id]: <>&#8211;</> };
      }
      index += 1;
      return { ...acc, [property.id]: index };
    }, {} as { [x: string]: ReactText | ReactElement; });
  }, [property.propertyAvailabilities]);

  const stopAccordionPropagation = (e: React.MouseEvent | React.FocusEvent) => e.stopPropagation();

  const [newAvailability, setNewAvailability] = useState<PropertyAvailabilitiesTypes>();

  const availabilityUpdateCallback = (res: any) => {
    setOpen(true);
    setCurrentAccId(res.id);
    setNewAvailability(res);
  };
  const handleAddAvailability = (properties: Property) => {
    let newAvailabilities: any;
    let newPropertyId: string;
    let target: any;
    if (properties?.propertyAvailabilities?.length > 0) {
      newAvailabilities = [...properties.propertyAvailabilities];
      target = newAvailabilities[newAvailabilities.length - 1];
      onAddAvailability(target.propertyId, availabilityUpdateCallback);
    } else {
      newPropertyId = properties.id;
      onAddAvailability(newPropertyId, availabilityUpdateCallback);
    }

    // setNewAddedAvailability(target)
  };

  let debounced: any = null;

  const handleUpdateAvailability = (event: React.ChangeEvent<HTMLTextAreaElement
    | HTMLInputElement>, availability: PropertyAvailabilitiesTypes) => {
    const { name, value } = parseInputChange(event.target);
    const { propertyId, id } = availability;
    onUpdateAvailability({
      propertyId,
      id,
      name,
      value,
    }, (res: any) => { },
    );
  };

  debounced = debounce((event, availability: PropertyAvailabilitiesTypes) => {
    if (event) {
      handleUpdateAvailability(event, availability);
    }
  }, 0);

  const onValueChange = (event: React.ChangeEvent<HTMLTextAreaElement
    | HTMLInputElement>, availability: PropertyAvailabilitiesTypes) => {
    const args = { target: { name: event.target.name, value: event.target.value } };
    if (debounced) {
      debounced.cancel();
    }
    debounced(args, availability);
  };

  return (
    <>
      <div className={classes.v_spacer} />
      <div className={classes.header}>
        <Typography variant="h6">Edit Availabilities</Typography>
        <Button
          variant="text" startIcon={<AddIcon />}
          onClick={() => {
            handleAddAvailability(property);
          }}
        >
          New Availability
        </Button>
      </div>

      <DragAndDropList
        droppableId="propertyAvailability"
        onDragEnd={onReorder}
      >
        {
          availabilities?.map((availability: PropertyAvailabilitiesTypes, propIndex: number) => (
            <Accordion
              key={propIndex}
              expanded={currentAccId === availability.id ? open : false}
              onChange={handleAccordionExpandChange(availability.id)}
              onClick={() => { Clicked(availability.id); setNewAvailability(availability); }}
              classes={{
                root: classes.sectionRoot,
                expanded: classes.sectionExpanded,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{
                  expanded: classes.sectionExpanded,
                  content: classes.sectionSummaryContent,
                  root: classes.sectionSummaryRoot,
                  expandIcon: classes.expandIcon,
                }}
              >
                <DraggableListItem
                  id="SC-DragHandle"
                  key={availability.id}
                  index={propIndex}
                  draggableId={availability.id}
                  className={classes.propertyCardName}
                >
                  <div className={classes.propertyIndex}>{propertyIndexMap[availability.id]}</div>
                  <Typography variant='body1' className={classes.floorName}>
                    {open === true && currentAccId === availability.id ?
                      <Grid>
                        <TextField
                          fullWidth
                          variant='filled'
                          onFocus={stopAccordionPropagation}
                          onClick={(e) => {
                            stopAccordionPropagation(e);
                          }}
                          onChange={(e) => {
                            onValueChange(e, availability);
                          }}
                          onBlur={(e) => onValueChange(e, availability)}
                          label={availability.proposedSpace?.length === 0 ? 'Proposed Space' : ''}
                          value={availability.proposedSpace}
                          className={classes.changeAvailabilityTitle}
                          name='proposedSpace'
                        />
                      </Grid>
                      : availability.proposedSpace}
                  </Typography>
                  <div className={classes.propertyCardControls}>
                    <FormGroup row>
                      <FormControlLabel
                        className={classes.FormLabel}
                        control={
                          <Switch
                            id="SC-ToggleShowHide"
                            className={classes.ToggleButton}
                            data-testid={`switch-${property.id}`}
                            onFocus={stopAccordionPropagation}
                            onClick={(e) => {
                              stopAccordionPropagation(e);
                            }}
                            checked={availability.status === 'ACTIVE'}
                            onChange={
                              (e) => {
                                onUpdateAvailabilityStatus({
                                  propertyAvailabilityId: availability.id,
                                  status: e.target.checked ? 'ACTIVE' : 'INACTIVE',
                                });
                                setAvailabilityStatus({
                                  ...availabilityStatus,
                                  [availability.id]: e.target.checked,
                                });
                              }
                            }
                            color="primary"
                          />
                        }
                        label="Show"
                        labelPlacement="start"
                      />
                    </FormGroup>
                    <IconButton
                      id="SC-DeleteProperty"
                      onClick={(e) => {
                        stopAccordionPropagation(e);
                        deleteAvailabilities(availability.id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>

                  </div>
                </DraggableListItem>
              </AccordionSummary>
              <AccordionDetails
                className={classes.sectionDetails}
                onFocus={stopAccordionPropagation}
                onClick={(e) => {
                  stopAccordionPropagation(e);
                }}
              >
                <AvailabilitiesInformation
                  propertyData={availability}
                  newAddedAvailability={newAvailability}
                  onUpdateAvailability={onUpdateAvailability} />
              </AccordionDetails>
            </Accordion>
          ))

        }

      </DragAndDropList >
    </>

  );
};

export type Props = {
  survey: Survey;
  property: Property;
  onUpdateProperty: UsePropertyAPI['updateProperty'];
  onUpdateAvailabilityStatus: UsePropertyAPI['updateAvailabilityStatus'];
  //  onUpdatePreviewPosition: (target: string, expand: boolean) => (e: React.MouseEvent) => void;
  onReorder: UsePropertyAPI['reorderAvailability'];
  deleteAvailabilities: UsePropertyAPI['deleteAvailabilities'];
  onAddAvailability: UseSurveyEditorAPI['addAvailability'];
  onUpdateAvailability: UseSurveyEditorAPI['updateAvailability'];

};

PropertyAvailabilities.displayName = 'PropertyAvailabilities';
export default React.memo(PropertyAvailabilities);
