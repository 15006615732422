import { axios } from '../internal-lib';
import { surveyServiceBaseURL } from '../config';
import {
  SurveySection, SurveySectionPatch, SurveySectionPost,
} from '../types';
import onSuccess from './onSuccess';

export const addSection = (post: SurveySectionPost) => axios.post<SurveySection>(`${surveyServiceBaseURL}section`, post).then(onSuccess);

export const getSection = (id: string) => axios.get<SurveySection>(`${surveyServiceBaseURL}section/${id}`).then(onSuccess);

export const updateSection = (patch: SurveySectionPatch) => axios.patch<SurveySection>(`${surveyServiceBaseURL}section`, patch).then(onSuccess);

export const deleteSection = (id: string) => axios.delete<string>(`${surveyServiceBaseURL}section/${id}`).then(onSuccess);
