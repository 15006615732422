/* eslint-disable react-hooks/exhaustive-deps */
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { Typography } from '@cbreone/core-ui';
import { useResizeDetector } from 'react-resize-detector';
import { Dispatch } from 'redux';
import { Survey } from '../../types';
import { getFormattedDate } from '../../utilities';
import { getSurveyMediaFile } from '../../services/media-service';
import { PDFPaperSize } from '../../data/constants';
import setSurveySummaryAction from '../../redux/actions/surveyAction';

const TITLE_CLIENT_WIDTH = 470; // Will need to be resize if the Title font size changes
const TITLE_ClIENT_HEIGHT = 86; // Will need to be resize if the Title font size changes

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '36px 30px 20px',
      color: theme.palette.common.white,
      width: '91.2%',
      // padding: '32px',
    },
    name: {
      marginBottom: '54px',
    },
    title: {
      wordBreak: 'keep-all',
      display: 'inline-block',
      whiteSpace: 'break-spaces',
      fontSize: 72,
    },
    smTitle: {
      fontSize: '42px !important',
    },
    titleHidden: {
      wordBreak: 'keep-all',
      display: 'inline-block',
      whiteSpace: 'break-spaces',
      visibility: 'hidden',
      zIndex: -99,
      height: 0,
      position: 'fixed',
    },
    subtitle: {
      borderLeft: `1px solid ${theme.palette.primary.light}`,
      paddingLeft: '25px',
      marginTop: '60px',
      zIndex: 1,
      wordBreak: 'keep-all',
    },
    line1: {
      // One Off Font Styles
      fontFamily: 'Calibre-R',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: '-0.01em',
      lineHeight: '20px',
    },
    clientName: {
      display: 'inline-block',
      marginRight: theme.spacing(2),
    },
    surveyDt: {
      display: 'inline-block',
    },
    imgBox: {
      width: '60px',
      height: '60px',
      marginTop: theme.spacing(4),
      background: theme.palette.common.white,
      position: 'relative',
    },
    innerImg: {
      width: '100%',
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      margin: 'auto auto',
    },
    cbreLogo: {
      width: '48px',
      height: '11.93px',
      color: theme.palette.primary.contrastText,
      marginBottom: '54px',
    },
    separator: {
      marginRight: '16px',
      display: 'inline-block',
      fontSize: '16px',
    },
    potraitRoot: {
      margin: '0px',
      padding: '32px 32px 20px',
      width: '100%',
    },
    potraitSubtitle: {
      minHeight: '25px',
      borderWidth: '2px',
      marginTop: '0px',
      paddingLeft: '16px',
    },
    potraitCbreLogo: {
      marginBottom: '32px',
    },
    coverTitle: {
      marginBottom: '16px',
    },
    potraitLine1: {
      marginBottom: '4px',
    },
    potraitSeparator: {
      marginRight: '20px',
    },
    potraitClientName: {
      marginRight: '20px',
    },
    clientLogoInnerImage: {
      backgroundColor: theme.palette.common.white,
      height: 'auto',
      padding: '4px',
      maxWidth: '125px',
      maxHeight: '125px',
      width: 'auto',
    },
    logoMargin: {
      margin: '20px 20px 0',
    },
  }),
);

const SurveyTitleEle: React.FC<any> = ({ coverTitle }) => {
  const [titleVariant, setTitleVariant] = useState('h2');
  const classes = useStyles();
  const { width, height, ref } = useResizeDetector();
  useEffect(() => {
    if (
      (width && width <= TITLE_CLIENT_WIDTH) ||
      (height && height <= TITLE_ClIENT_HEIGHT)
    ) {
      setTitleVariant('h2');
    } else {
      setTitleVariant('h4');
    }
  }, [width, height, ref]);
  return (
    <>
      <Typography
        variant="h2"
        className={titleVariant === 'h2' ? classes.title : classes.smTitle}
      >
        {coverTitle || 'Property Survey'}
      </Typography>

      <Typography ref={ref} variant="h2" className={classes.titleHidden}>
        {coverTitle || 'Property Survey'}
      </Typography>
    </>
  );
};

const SummaryDomElements = (survey: Survey) => {
  const classes = useStyles();
  const isLandscapeMode = survey.pdfPaperSize === PDFPaperSize.Landscape;
  const dispatch: Dispatch<any> = useDispatch();
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [clientLogo, setClientLogo] = useState('');

  useEffect(() => {
    if (survey?.id) {
      getSurveyMediaFile(
        'CLIENTLOGO', survey?.id,
      ).then((res) => {
        const findMedia = res.media.find((eachMedia: any) => eachMedia.surveyId === survey?.id);
        dispatch(setSurveySummaryAction({ clientLogo: findMedia?.cloudfrontUrl }));
        setClientLogo(findMedia?.cloudfrontUrl);
      })
        // eslint-disable-next-line no-console
        .catch((error) => console.log(error));
    }
  }, [dispatch, survey.id]);

  useEffect(() => {
    setIsImageLoaded(false);
  }, [survey?.isDisplayClientLogo]);

  const handleImgLoad = () => {
    setIsImageLoaded(true);
  };

  const clientNameStyle = () => {
    if (survey.clientName) {
      return classes.clientName;
    }
    if (!isLandscapeMode) {
      return classes.potraitClientName;
    }
    return '';
  };

  return (
    <Grid
      container
      direction="column"
      className={`${classes.root} ${!isLandscapeMode ? classes.potraitRoot : ''
        }`}
    >
      <Grid item>
        <img
          src="/assets/logo/cbreLogo.svg"
          alt="cbre logo"
          className={`${classes.cbreLogo} ${!isLandscapeMode ? classes.potraitCbreLogo : ''
            }`}
        />
      </Grid>
      <Grid item className={` ${!isLandscapeMode ? classes.coverTitle : ''}`}>
        <SurveyTitleEle coverTitle={survey.coverTitle} />
      </Grid>
      <Grid item className={`${classes.subtitle} ${!isLandscapeMode ? classes.potraitSubtitle : ''}`}>
        {survey.coverSubTitle && <Typography className={`${classes.line1} ${!isLandscapeMode ? classes.potraitLine1 : ''}`}>
          {survey.coverSubTitle}
        </Typography>
        }
        <div>
          {survey.isDisplayClientName && survey.clientName &&
            <span>
              <Typography
                variant="body2"
                className={clientNameStyle()}
              >
                {survey.clientName}
              </Typography>
              <Typography variant="body2" className={`${classes.separator} ${!isLandscapeMode ? classes.potraitSeparator : ''}`}>
                |
              </Typography>
            </span>
          }
          <Typography variant="body2" className={classes.surveyDt}>
            {survey.coverDate || getFormattedDate(new Date())}
          </Typography>
        </div>
      </Grid>
      {clientLogo && survey?.isDisplayClientLogo &&
        <Grid item className={isImageLoaded ? classes.logoMargin : ''}>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <img
            onLoad={handleImgLoad} src={clientLogo} alt="Client Logo"
            className={isImageLoaded ? classes.clientLogoInnerImage : ''} />
        </Grid>
      }
    </Grid>
  );
};

export const CoverSummaryPDFPreview: React.FC<Props> = ({ survey }) =>
  SummaryDomElements(survey);

const CoverSummaryPreview: React.FC<PropsFromRedux> = ({ stateSurvey }) =>
  SummaryDomElements(stateSurvey);

const mapStateToProps = (state: any) => ({
  stateSurvey: state.survey,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export type Props = {
  survey: Survey;
};

CoverSummaryPreview.displayName = 'CoverSummaryPreview';
export default React.memo(connect(mapStateToProps)(CoverSummaryPreview));
