import { MARKETMAP_ACTION } from '../types/actionTypes';
import {
  MarketMapAction,
  MarketMapDispatchType,
  MarketMapStatePatch,
} from '../types/dataTypes';

const setMarketMapAction = (marketMapState: MarketMapStatePatch) => (
  dispatch: MarketMapDispatchType,
) => {
  const action: MarketMapAction = {
    type: MARKETMAP_ACTION.SET_MARKETMAP,
    marketMapState,
  };
  dispatch(action);
};

const recenterMarketMapAction = (marketMapState: MarketMapStatePatch) => (
  dispatch: MarketMapDispatchType,
) => {
  const action: MarketMapAction = {
    type: MARKETMAP_ACTION.RECENTER_MARKETMAP,
    marketMapState,
  };
  dispatch(action);
};

const updateMarketMapZoomCenterAction = (
  marketMapState: MarketMapStatePatch,
) => (dispatch: MarketMapDispatchType) => {
  const action: MarketMapAction = {
    type: MARKETMAP_ACTION.UPDATE_MARKETMAP_ZOOM_CENTER,
    marketMapState,
  };
  dispatch(action);
};

const updateMarketMapListAction = (marketMapState: MarketMapStatePatch) => (
  dispatch: MarketMapDispatchType,
) => {
  const action: MarketMapAction = {
    type: MARKETMAP_ACTION.UPDATE_MARKETMAPLIST,
    marketMapState,
  };
  dispatch(action);
};

const updateMarketMapPointsAction = (marketMapState: MarketMapStatePatch, patch: any) => (
  dispatch: MarketMapDispatchType,
) => {
  const action: MarketMapAction = {
    type: MARKETMAP_ACTION.UPDATE_MARKETMAP_POTINS,
    marketMapState,
    patch,
  };
  dispatch(action);
};

const updateMarketMapActiveIndexAction = (
  marketMapState: MarketMapStatePatch,
) => (dispatch: MarketMapDispatchType) => {
  const action: MarketMapAction = {
    type: MARKETMAP_ACTION.UPDATE_MARKETMAP_ACTIVE_INDEX,
    marketMapState,
  };
  dispatch(action);
};

const updatePdfPaperSizeAction = (marketMapState: MarketMapStatePatch) => (
  dispatch: MarketMapDispatchType,
) => {
  const action: MarketMapAction = {
    type: MARKETMAP_ACTION.UPDATE_PDF_PAPER_SIZE,
    marketMapState,
  };
  dispatch(action);
};

const updateMarketMapStyleAction = (marketMapState: MarketMapStatePatch) => (
  dispatch: MarketMapDispatchType,
) => {
  const action: MarketMapAction = {
    type: MARKETMAP_ACTION.UPDATE_MARKETMAP_STYLE,
    marketMapState,
  };
  dispatch(action);
};

const updateMarketMapSizeAction = (marketMapState: MarketMapStatePatch) => (
  dispatch: MarketMapDispatchType,
) => {
  const action: MarketMapAction = {
    type: MARKETMAP_ACTION.UPDATE_MARKETMAP_SIZE,
    marketMapState,
  };
  dispatch(action);
};

const updateMarketMapLegendHeightAction = (marketMapState: MarketMapStatePatch) => (
  dispatch: MarketMapDispatchType,
) => {
  const action: MarketMapAction = {
    type: MARKETMAP_ACTION.UPDATE_MARKETMAP_LEGEND_HEIGHT,
    marketMapState,
  };
  dispatch(action);
};

export default {
  setMarketMapAction,
  updateMarketMapListAction,
  updateMarketMapPointsAction,
  recenterMarketMapAction,
  updateMarketMapZoomCenterAction,
  updateMarketMapActiveIndexAction,
  updatePdfPaperSizeAction,
  updateMarketMapStyleAction,
  updateMarketMapSizeAction,
  updateMarketMapLegendHeightAction,
};
