import React from 'react';
import { SvgIcon } from '@material-ui/core';

type Props = {
  height: number;
  width: number;
};

const MapSettingRestoreIcon: React.FC<Props> = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.5 0C7.53 0 3.5 4.03 3.5 9H0.5L4.5 12.99L8.5 9H5.5C5.5 5.13 8.63 2 12.5 2C16.37 2 19.5 5.13 19.5 9C19.5 12.87 16.37 16 12.5 16C10.57 16 8.82 15.21 7.56 13.94L6.14 15.36C7.77 16.99 10.01 18 12.5 18C17.47 18 21.5 13.97 21.5 9C21.5 4.03 17.47 0 12.5 0ZM11.5 5V10L15.75 12.52L16.52 11.24L13 9.15V5H11.5Z" fill="#1A1A1A" fillOpacity="0.6"/>
  </SvgIcon>
);

MapSettingRestoreIcon.displayName = 'MapSettingRestoreIcon';
export default MapSettingRestoreIcon;
