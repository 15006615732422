import externalTheme from '@cbreone/core-ui/dist/themes/externalTheme';
import { ThemeProvider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import CoverPreview from '../../components/CoverPreview';
import MarketMapPDFSectionPreview from '../../components/MarketMapPDFPreview/MarketMapPDFSectionPreview';
import PropertyComparisonPreview from '../../components/PropertyComparisonPreview';
import PropertyPreviewWrapper from '../../components/PropertyPreview/PropertyPreviewWrapper';
import TourSchedulePreview from '../../components/TourSchedulePreview/TourSchedulePreview';
import marketMapAction from '../../redux/actions/marketMapAction';
import mediaFileAction from '../../redux/actions/mediaFileAction';
import PDFOrigin from '../../redux/data/constants';
import { Property, Survey } from '../../types';
import { getArrayChunks } from '../../utilities';

const SurveySectionPreview: React.FC<Props> = ({
  survey,
  propertyId,
  section,
  floorPlanIndex,
}) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [property, setProperty] = useState<Property>({} as Property);
  useEffect(() => {
    let propertyChunks = [];
    if (survey.isIncludeHeader) {
      propertyChunks = getArrayChunks(survey.properties, 12);
    } else {
      propertyChunks = getArrayChunks(survey.properties, 14);
    }
    const data = {
      id: survey.id,
      mapCount: survey.isIncludeLegend ? propertyChunks.length : 1,
      properties: survey.properties,
      mapSize: [0, 0] as [number, number],
      pdfPaperSize: survey.pdfPaperSize,
      marketMapBamData: survey.marketMapBamData,
      isIncludeLegend: survey.isIncludeLegend,
      isIncludeHeader: survey.isIncludeHeader,
      marketMapStyle: survey.marketMapStyle,
      activeMapIndex: -1,
      origin: PDFOrigin.PDFLambda,
      resettingMap: false,
    };
    dispatch(marketMapAction.updatePdfPaperSizeAction(data));
    dispatch(mediaFileAction.addMediaFilesAction({
      ids: survey.properties.map((item: Property) => item.id),
    }));
    if (propertyId) {
      // eslint-disable-next-line max-len
      const selectedProperty = survey.properties.find((item: Property) => item.id === propertyId) || {} as Property;
      setProperty(selectedProperty);
    }
  }, [survey]);

  const renderSwitch = (sectionName: string) => {
    switch (sectionName) {
      case 'cover':
        return (
          <CoverPreview order="1" key="0" survey={survey} pageMode="PDF" />
        );
      case 'marketmap':
        return (
          <MarketMapPDFSectionPreview
            order="2"
            key="1"
            survey={survey}
          />
        );
      case 'propertycomparison':
        return (
          <PropertyComparisonPreview
            order="3"
            key="2"
            survey={survey}
            pageMode="PDF"
          />
        );
      case 'properties':
        return (
          <PropertyPreviewWrapper
            order="4"
            key="3"
            survey={survey}
            pageMode="PDF"
            property={property}
            propertyIdPreview={propertyId}
            floorPlanIndex={floorPlanIndex}
          />
        );
      case 'tourschedule':
        return (
          <TourSchedulePreview
            order="5"
            key="4"
            survey={survey}
            pageMode="PDF"
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <ThemeProvider theme={externalTheme}>
      {survey && section && renderSwitch(section)}
    </ThemeProvider>
  );
};

export type Props = {
  survey: Survey;
  propertyId?: string;
  section?: string;
  floorPlanIndex?: number;
};

SurveySectionPreview.displayName = 'SurveySectionPreview';
export default SurveySectionPreview;
