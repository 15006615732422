/* eslint-disable*/
import { getLocaleStringForValue, getValidNumberString } from './propertyUtil';

// to identify availability fields from property level fields in the same row.
function isAvailabilityField(key: string) {
  switch (key) {
    case 'askingRent':
    case 'askingPrice':
      // from PropertyAvailabilitiesTypes
    case 'availabilityType':
    case 'askingPriceUnit':
    case 'askingRentServices':
    case 'askingRentUnit':
    case 'availabilityStatus':
    case 'availabilityName':
    case 'availabilityTitle':
    case 'availableDate':
    case 'availableUom':
    case 'availableSF':
    case 'createdBy':
    case 'createdOn':
    case 'floor':
    case 'floorType':
    case 'id':
    case 'leaseType':
    case 'maxAskingPrice':
    case 'maxAskingRent':
    case 'maxAvailableSF':
    case 'maxContiguous':
    case 'maxContiguousUom':
    case 'minAskingPrice':
    case 'minAskingRent':
    case 'minAvailableSF':
    case 'minDivisible':
    case 'minDivisibleUom':
    case 'occupancy':
    case 'order':
    case 'parkingSpacesNum':
    case 'propertyId':
    case 'proposedSpace':
    case 'source':
    case 'sourceSystem':
    case 'status':
    case 'suite':
    case 'term':
    case 'type':
    case 'updatedBy':
    case 'updatedOn':
    case 'virtualTour':
      return true;
  }
  return false;
}

export function hasEmptyAvailablity(record: any) {
  let empty = true;
  for (const key in record) {
    if (!isAvailabilityField(key)) {
      continue;
    }
    const value = (record[key] || '').trim();
    if (value) {
      empty = false;
      break;
    }
  }
  return empty;
}

function parseNumber(key: string, value: string) {
  let kmin = '';
  let kmax = '';

  let possibleInteger = true;

  switch (key) {
    case 'totalAvailableSpace':
      kmin = 'minTotalAvailableSpace';
      kmax = 'maxTotalAvailableSpace';
      break;
    case 'availableSF':
      kmin = 'minAvailableSF';
      kmax = 'maxAvailableSF';
      break;
    case 'askingRent':
      kmin = 'minAskingRent';
      kmax = 'maxAskingRent';
      possibleInteger = false;
      break;
    case 'askingPrice':
      kmin = 'minAskingPrice';
      kmax = 'maxAskingPrice';
      possibleInteger = false;
      break;
    case 'minDivisible':
    case 'maxContiguous':
      return { [key]: getLocaleStringForValue((value?.replace(/,/g, '')), true, 0) };
    default:
      return;
  }

  const fractionDigits = possibleInteger ? 0 : 2;

  const parts = value.match(/[0-9.,]+/g) || [];
  if (parts.length < 1) {
    return;
  }

  const smin = (parts[0] as string || '').replace(/,/g, '');

  const vmin = getValidNumberString(smin, possibleInteger, fractionDigits);
  const min = vmin || smin;
  if (parts.length < 2) {
    return { [kmin]: min };
  }

  const smax = (parts[1] as string || '').replace(/,/g, '');
  const vmax = getValidNumberString(smax, possibleInteger, fractionDigits);
  const max = vmax || smax;
  if (!!vmin && !!vmax) {
    const fmin = parseFloat(vmin.replace(/,/g, ''));
    const fmax = parseFloat(vmax.replace(/,/g, ''));
    if (fmax < fmin) {
      return { [kmin]: max, [kmax]: min };
    }
  }

  return { [kmin]: min, [kmax]: max };
}

const commaKeysToExclude = [
  'minAskingRent', 'maxAskingRent',
  'minAskingPrice', 'maxAskingPrice',
  'minDivisible', 'maxContiguous',
];

export function prepareImportPropertyAvailabilities(flatfile: any) {
    // utilities
    type Item$Group = {
        groupBy: {
            address1: string;
            city: string;
            state: string
        } & { [key: string]: string };

        item$Data: any;
    }

    function refineItemData(itemData: any) {
      const propertyFields: any = {};

      let availabilityFields: any;
      const hasAvailability = !hasEmptyAvailablity(itemData);

      for (const key in itemData) {
        let fields;
        if (hasAvailability && isAvailabilityField(key)) {
          availabilityFields = availabilityFields ?? {};
          fields = availabilityFields;
        } else {
          fields = propertyFields;
        }

        if (key === '$custom') {
          if (Object.keys(itemData[key]).length > 0) {
            const customObj:any = {};
            for (const [key1, value] of Object.entries(itemData[key])) {
              customObj[key1] = (value as string)?.trim() || null;
            }
            fields[key] = customObj;
          } else {
            fields[key] = itemData[key];
          }
        } else {
          const value = (itemData[key] || '').trim();
          const nn = parseNumber(key, value);
          if (nn) {
            for (const key in nn) {
              if (commaKeysToExclude.includes(key)) {
                fields[key] = nn[key].replace(/,/g, '') || null;
              } else {
                fields[key] = nn[key] || null;
              }
            }
          } else {
            fields[key] = value || null;
          }
        }
      }

      propertyFields.propertyAvailabilities = availabilityFields ? [availabilityFields] : [];

      return propertyFields;
    }

    // process
    console.log('ds: flatfile', flatfile);

    const $data = flatfile.$data.filter((item: any) => !item.deleted && item.valid);

    const $groups: Item$Group[] = $data.reduce((groups: Item$Group[], item: any) => {
      let item$Group = groups.find((group) => {
        let find = true;
        for (const gkey in group.groupBy) {
          const gv = group.groupBy[gkey] || '';
          const iv = item.data[gkey] || '';
          if (gv.toLowerCase() !== iv.toLowerCase()) {
            find = false;
          }
        }
        return find;
      });
      if (item$Group) {
        const { propertyAvailabilities } = refineItemData(item.data);
        if (propertyAvailabilities.length > 0) {
          item$Group.item$Data.data.propertyAvailabilities.push(...propertyAvailabilities);
        }
      } else {
        const { address1, city, state } = item.data;
        item$Group = {
          groupBy: { address1, city, state },
          item$Data: {
            ...item,
            data: refineItemData(item.data),
          },
        };
        groups.push(item$Group);
      }
      return groups;
    }, []);

    const result = {
      ...flatfile,
      $data: $groups.map((group) => group.item$Data),
    };

    console.log('ds: refined', result);
    return result;
}
