import createQueryString, { QueryParamObj } from '@cbreone/utilities/dist/createQueryString';
import { propertyServiceBaseURL } from '../config';
import { axios } from '../internal-lib';

import { Availability, AvailabilityAdd, AvailabilityPatch, AvailabilityStatusPatch, PropertyAvailabilityReorderPatch } from '../types';

import onSuccess from './onSuccess';

export const addAvailabilities = (propertyId: string) =>
  axios
    .post<AvailabilityAdd>(`${propertyServiceBaseURL}propertyAvailability`, { propertyId })
    .then(onSuccess);

export const getAvailability = (id: string) => axios.get<Availability>(`availability/${id}`).then(onSuccess);

export const getAvailabilities = (params?: QueryParamObj) => axios.get<Availability[]>(`availability${createQueryString(params)}`).then(onSuccess);

export const updateAvailability = (availability: AvailabilityPatch) => axios.patch<AvailabilityPatch>(`${propertyServiceBaseURL}propertyAvailability`, { propertyId: availability.propertyId, id: availability.id, [availability.name]: availability.value }).then(onSuccess);

export const deleteAvailability = (id: string) => axios.delete<string>(`availability/${id}`).then(onSuccess);

export const updateAvailabilityStatus = (availabilityStatus: AvailabilityStatusPatch) => axios.patch<any>(`${propertyServiceBaseURL}propertyAvailability/status`, availabilityStatus).then(onSuccess);
export const reorderPropertyAvailability = (post: PropertyAvailabilityReorderPatch) =>
  new Promise((resolve, reject) => {
    reorderPropertyAvailabilitySC(post)
      .then((availabilities) => {
        if (availabilities && Array.isArray(availabilities)) {
          resolve(availabilities);
        } else {
          reject();
        }
      })
      .catch(() => {
        reject();
      });
  });

export const reorderPropertyAvailabilitySC =
  (propertyAvailabilityReorder: PropertyAvailabilityReorderPatch) =>
    axios
      .patch<any[]>(
        `${propertyServiceBaseURL}propertyAvailability/reorder`,
        propertyAvailabilityReorder,
      )
      .then(onSuccess);

export const deleteAvailabilityService = (availabilityId: string) =>
  axios
    .delete<string>(`${propertyServiceBaseURL}propertyAvailability/availabilityID/${availabilityId}`)
    .then(onSuccess);
