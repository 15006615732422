import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Link,
} from '@material-ui/core';
import ApartmentIcon from '@material-ui/icons/Apartment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  createStyles, makeStyles, Theme,
} from '@material-ui/core/styles';
import { dayFormatter } from '../../internal-lib';
import { Survey } from '../../types';

const useStyles = makeStyles((theme: Theme) => createStyles({
  listItem: {
    backgroundColor: theme.palette.grey['200'],
    marginBottom: theme.spacing(0.625),
    border: '1px solid #FFFFFF',
    boxShadow: 'inset 0px -1px 0px rgb(0 63 45 / 15%)',
    boxSizing: 'border-box',
  },
  surveyTitle: {
    width: '75%',
  },
  iconSpacing: {
    minWidth: '0px',
    paddingRight: '8px',
  },
}));

const SurveyListItem: React.FC<Props> = ({
  survey,
  onDelete,
  onCopy,
}) => {
  const classes = useStyles();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let property = 'properties';
  if (survey.properties.length === 1) {
    property = 'property';
  }

  const getUpdatedOn = (date: string) => {
    const updatedOn = dayFormatter.utc(date).local();
    const diff = updatedOn.diff(new Date(), 'day');
    if (+diff > -7) {
      return updatedOn.fromNow();
    }
    return updatedOn.format('MMM. DD, YYYY');
  };

  return (
    <ListItem className={classes.listItem}>
      <div className={classes.surveyTitle}>
      <ListItemText secondary={`Last modified ${getUpdatedOn(survey.updatedOn)}`}>
        <Link component={RouterLink} to={`/${survey.id}/builder`}>
          {survey.name}
        </Link>
      </ListItemText>
      </div>
      <ListItemIcon className={classes.iconSpacing}>
        <ApartmentIcon />
      </ListItemIcon>
      <ListItemText>{survey.properties.length} {property}</ListItemText>
      <ListItemSecondaryAction>
         {/* @Todo "post-pilot" work to reference for later. */}

        {/* <Button variant="text" disabled>
          PDF
        </Button>
        <Button variant="text" disabled>
          Website
        </Button>
        <Button
          id="SC-OpenSurveyBuilder"
          variant="text"
          component={RouterLink}
          to={`/${survey.id}/builder`}
        >
          Edit
        </Button> */}
        <IconButton id="SC-OpenSurveySummary" data-testid="survey-kebab-button" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="SC-SurveyKebab"
          data-testid="survey-kebab-menu"
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          getContentAnchorEl={null}
        >
          <MenuItem
            id="SC-CopySurvey"
            data-testid="copy-survey"
            onClick={() => {
              handleClose();
              onCopy(survey.id);
            }}
          >
            Copy
          </MenuItem>
          <MenuItem
            id="SC-DeleteSurvey"
            data-testid="delete-survey"
            onClick={() => {
              handleClose();
              setOpenDeleteModal(true);
            }}
          >
            Delete
          </MenuItem>
          <Dialog
            open={openDeleteModal}
            onClose={() => { setOpenDeleteModal(false); }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{`Delete ${survey.name}`}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This will remove the survey and all uploaded files.
                Are you sure you want to delete this survey?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => { setOpenDeleteModal(false); }} variant="text">
                Cancel
              </Button>
              <Button
                data-testid="delete-survey-button"
                onClick={() => {
                  onDelete(survey.id);
                  setOpenDeleteModal(false);
                }}
                color="secondary"
                variant="contained"
                autoFocus
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Menu>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export type Props = {
  survey: Survey;
  onDelete: (surveyId: string) => void;
  onCopy: (surveyId: string) => void;
};

export default SurveyListItem;
