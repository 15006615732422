import Analytics from '@cbreone/core-services/dist/services/analytic-service';
import {
  Drawer,
} from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import ManagePropertiesView from '../../components/ManagePropertiesView';
import TourSchedulePanel from '../../components/TourSchedulePanel';
import { RouteParams } from '../../routes/routes';

import PropertyEdit from '../../components/PropertyEdit/PropertyEdit';
import { MediaState } from '../../redux/types/dataTypes';
import { UseSurveyEditorAPI } from './useSurveyEditorAPI';

const DrawerWrapper: React.FC<Props> = ({
  variant,
  anchor,
  open,
  hideBackdrop,
  onClose,
  classes,
  showManagePropertyView,
  showTourPanel,
  onClickFileupload,
  onDismiss,
  surveyApi,
}) => {
  const {
    reorderSurveyAttribute,
    selectProperty,
    selectedProperty,
    survey,
    updateProperty,
    updateMedia,
    addAvailability,
    addProperty,
    deleteAvailability,
    deleteProperty,
    updateAvailability,
    addCustomField,
    deleteCustomField,
    updateCustomField,
    updateAvailabilityStatus,
    deleteAvailabilities,
    reorderAvailability,
    updatePropertyCustomField,

  } = surveyApi;

  if (!survey.id) {
    return null;
  }

  const emptyUser = {
    userIdList: [],
    authorIdList: [],
  };
  if (survey.user === null) {
    survey.user = emptyUser;
  }

  if (survey.id !== undefined) {
    Analytics.send({
      event: 'userDataReady',
      user_id: localStorage.getItem('employeeId'),
      jobTitle: localStorage.getItem('position'),
      officeName: localStorage.getItem('office'),
      email: localStorage.getItem('email'),
      functUnitName: localStorage.getItem('functUnitName'),
      SiteIQUserID: survey.user.userIdList,
      teamName: (survey as any)?.teamName,
    });
  }

  const clickFileUpload = () => {
    // Close the Drawer first since the drawer is impacting the flatfile dropdown.
    onClickFileupload();
    const flatfileBtn = document.querySelector(
      'button[class="hiddenFlatfileBtn"]',
    ) as HTMLButtonElement;
    if (flatfileBtn) {
      flatfileBtn.click();
    }
  };

  return (
    <Drawer
      variant={variant}
      anchor={anchor}
      open={open}
      hideBackdrop={hideBackdrop}
      onClose={onClose}
      classes={classes}
    >
      {selectedProperty && (
        <PropertyEdit
          onUpdateProperty={updateProperty}
          onAddAvailability={addAvailability}
          onDeleteAvailability={deleteAvailability}
          onUpdateAvailability={updateAvailability}
          onAddCustomField={addCustomField}
          onDeleteCustomField={deleteCustomField}
          onUpdateCustomField={updateCustomField}
          onUpdateAvailabilityStatus={updateAvailabilityStatus}
          onUpdatePropertyCustomField={updatePropertyCustomField}
          property={selectedProperty}
          survey={survey}
          deleteAvailabilities={deleteAvailabilities}
          onReorder={reorderAvailability}
          onUpdateMedia={updateMedia}
          onBack={selectProperty}
        />
      )}
      {showManagePropertyView && (
        <ManagePropertiesView
          onAddProperty={addProperty}
          onDeleteProperty={deleteProperty}
          onDismiss={onDismiss}
          onPropertySelect={selectProperty}
          onUpdateProperty={updateProperty}
          survey={survey}
          onReorderProperties={reorderSurveyAttribute}
          onClickFileUpload={clickFileUpload}
        />
      )}
      {showTourPanel && (
        <TourSchedulePanel
          survey={survey}
          onUpdateProperty={updateProperty}
          onDismiss={onDismiss}
        />
      )}
    </Drawer>
  );
};

export type Props = {
  variant?: 'permanent' | 'persistent' | 'temporary',
  anchor?: 'left' | 'top' | 'right' | 'bottom',
  open: boolean,
  hideBackdrop: boolean,
  onClose: () => void,
  onClickFileupload: () => void,
  classes: object,
  params: RouteParams,
  mediaState?: MediaState,
  showManagePropertyView: boolean,
  showTourPanel: boolean,
  onDismiss: () => void,
  surveyApi: UseSurveyEditorAPI,
};

const mapStateToProps = (state: any, ownProps: any) => ({
  mediaState: state.media,
  ...ownProps,
});

DrawerWrapper.displayName = 'DrawerWrapper';
export default connect(mapStateToProps)(DrawerWrapper);
