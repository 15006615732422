import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';

const useStyles = makeStyles(() => ({
  mapOverlay: {
    position: 'absolute',
    zIndex: 1,
    background: 'rgba(255, 255, 255, 0.75)',
  },
  innerTxt: {
    color: '#1976D2',
    margin: '30% 10%',
    padding: '8px 4px',
    background: 'rgba(100, 181, 246, 0.2)',
    border: '1px solid #1976D2',
    borderRadius: '4px',

    display: 'flex',
    alignItems: 'center',
  },
}));

const MapOverlay: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.mapOverlay}>
      <div className={classes.innerTxt}>
        <NotListedLocationIcon />
        <Typography variant="body2" style={{ marginLeft: 4 }}>
          To add a map pin for this property, verify an address or enter coordinates.
        </Typography>
      </div>
    </div>
  );
};

export default MapOverlay;
