import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { PDFPaperSize } from '../../data/constants';
import * as SurveyService from '../../services/survey-service';
import { Survey } from '../../types';

const CreateSurveyLoader: React.FC<Props> = () => {
  const [id, setId] = useState<string>('');
  useEffect(() => {
    SurveyService.addSurvey({
      clientLogo: '',
      clientName: '',
      contacts: [],
      coverBackgroundImage: {
        mediaId: '',
        s3Url: '',
        cloudfrontUrl: '',
        displayName: '',
      },
      coverDate: '',
      coverSubTitle: '',
      coverTitle: '',
      customFields: [],
      customLegends: {
        hasDisplayLegendOnSeparatePage: false,
        displayDensity: 'small',
        displayFields: {
          hasIcon: true,
          hasPropertyName: true,
          hasAddressLine1: true,
          hasAddressLine2: true,
          hasCity: true,
          hasState: true,
          hasZipCode: true,
          hasGroupName: false,
          hasGroupIconOnlyAvailable: false,
        },
      },
      isDisplayClientLogo: false,
      isDisplayClientName: true,
      isIncludeFlyers: true,
      isIncludePhotos: true,
      isIncludeSitePlans: true,
      isIncludeHeader: true,
      isIncludeLegend: false,
      isClientShortlist: true,
      showCurrentLocation: true,
      marketMapName: 'Market Map',
      marketMapStyle: 'cbreLight',
      marketMapZoom: 100,
      name: 'Untitled Survey',
      passcode: '',
      marketMapBamData: '{"mapReset":true,"mapZoomLevel":4,"mapCentroid":[-95,40]}',
      pdfPaperSize: PDFPaperSize.Portrait,
      propertyComparisonLayout: 'Landscape',
      propertyDetailHighlightStats: [],
      propertyDetailMapZoom: 100,
      propertyMapStyle: 'cbreLight',
      propertyTableConfig: [],
      propertyPerPortraitComparisonPage: 4,
      properties: [],
      propertyType: '',
      sections: [],
      tourDetails: '',
      projectCreatedAt: '',
      user: {
        userIdList: [],
        authorIdList: [],
      },
      projectId: '',
      teamId: '',
    }).then((survey: Survey) => {
      setId(survey.id);
    });
  }, []);

  if (!id) {
    return <p>Loading...</p>;
  }
  return <Redirect to={`/${id}/builder`} />;
};

export type Props = {};

CreateSurveyLoader.displayName = 'CreateSurveyLoader';
export default CreateSurveyLoader;
