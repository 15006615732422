import React, { useState, useEffect } from 'react';
import { Survey, SurveyPatch, PropertyPatch } from '../../types';
import MarketMapPreview from './MarketMapPreview';
import { getArrayChunks } from '../../utilities';
import { PDFPaperSize } from '../../data/constants';
import { UseSurveyEditorAPI } from '../../pages/SurveyBuilder/useSurveyEditorAPI';

const MarketMapSectionPreview: React.FC<Props> = ({
  survey,
  anchore,
  updateSurvey,
  updatePropertyCoordinates,
  onPropertySelect,
  onUpdateProperty,
  order,
}) => {
  const [legendCheckBox, setLegendCheckBox] = useState<boolean>(
    survey.isIncludeLegend,
  );
  const [firstChunks, setPropertyChunks] = useState<any[]>([]);
  const [remainingChunks, setRemainingChunks] = useState<any[]>([]);

  useEffect(() => {
    let propertyChunks: any[] = [];
    const surveyProperties = survey.properties.filter((item) => !item.isHidden);
    const numberOfHiddenProperties = survey.properties.filter((item) => item.isHidden).length;
    if (survey.properties.length === 0) {
      setLegendCheckBox(false);
    } else if (survey.properties.length === 1 && survey.properties[0].isHidden === true) {
      setLegendCheckBox(false);
    } else if (survey.properties.length === numberOfHiddenProperties) {
      setLegendCheckBox(false);
    } else {
      setLegendCheckBox(survey.isIncludeLegend === undefined ? true : survey.isIncludeLegend);
    }
    if (survey.pdfPaperSize === PDFPaperSize.Landscape) {
      if (survey.isIncludeHeader) {
        propertyChunks = getArrayChunks(surveyProperties, 14);
      } else {
        propertyChunks = getArrayChunks(surveyProperties, 16);
      }
    }
    if (survey.pdfPaperSize === PDFPaperSize.Portrait) {
      if (survey.isIncludeHeader) {
        propertyChunks = getArrayChunks(surveyProperties, 14);
      } else {
        propertyChunks = getArrayChunks(surveyProperties, 18);
      }
    }
    setPropertyChunks(propertyChunks.splice(0, 1));
    setRemainingChunks(propertyChunks);
  }, [survey.properties, survey.isIncludeLegend]);

  return (
    <div key={order}>
      {legendCheckBox === true &&
        firstChunks.map((chunk: any[]) => (
          <MarketMapPreview
            order={`${survey.sections[1].order}`}
            key={0}
            index={0}
            survey={survey}
            anchore={anchore}
            updateSurvey={updateSurvey}
            updatePropertyCoordinates={updatePropertyCoordinates}
            properties={chunk}
            onPropertySelect={onPropertySelect}
            onUpdateProperty={onUpdateProperty}
          />
        ))}
      {legendCheckBox === true &&
        remainingChunks.map((chunk: any[], index: any) => (
          <MarketMapPreview
            order={`${survey.sections[1].order}`}
            key={index + 1}
            index={index + 1}
            survey={survey}
            anchore={anchore}
            updateSurvey={updateSurvey}
            updatePropertyCoordinates={updatePropertyCoordinates}
            properties={chunk}
            onPropertySelect={onPropertySelect}
            onUpdateProperty={onUpdateProperty}
          />
        ))}
      {legendCheckBox === false &&
        <MarketMapPreview
          order={`${survey.sections[1].order}`}
          key={0}
          index={0}
          survey={survey}
          anchore={anchore}
          updateSurvey={updateSurvey}
          updatePropertyCoordinates={updatePropertyCoordinates}
          onPropertySelect={onPropertySelect}
          onUpdateProperty={onUpdateProperty}
        />}
    </div>
  );
};

export type Props = {
  survey: Survey;
  anchore?: object;
  updatePropertyCoordinates: UseSurveyEditorAPI['updateProperty'];
  updateSurvey: (survey: SurveyPatch) => void;
  onPropertySelect: (property: string) => void;
  onUpdateProperty: (property: PropertyPatch) => void;
  order?: string;
};

MarketMapSectionPreview.displayName = 'MarketMapSectionPreview';
export default React.memo(MarketMapSectionPreview);
