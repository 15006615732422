import {
  makeStyles, createStyles, Grid,
} from '@material-ui/core';
import React, { useRef, useEffect } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { PDFPaperSize } from '../../data/constants';
import { Survey, SurveyContact } from '../../types';
import CoverSummaryPreview, { CoverSummaryPDFPreview } from '../CoverSummaryPreview';
import SurveyContactPreviewList from '../SurveyContactPreviewList';
import SurveyPDFPage from '../SurveyPDFPage';

const useStyles = makeStyles((theme) => createStyles({
  coverPanel: {
    maxHeight: '8.5in',
  },
  summaryPanel: {
    height: 'unset',
    backgroundColor: theme.palette.grey['400'],
  },
  coverSummary: {
    background: theme.palette.primary.dark,
    width: '100%',
  },
  coverImageBox: {
    flexGrow: 1,
  },
  coverImage: {
    objectFit: 'cover',
    width: '100%',
  },
  contactPanel: {
    height: '8.5in',
    backgroundColor: theme.palette.grey['400'],
  },
  portraitCoverPanel: {
    maxHeight: '11in',
  },
  portraitContactPanel: {
    height: 'unset',
    maxHeight: '11in',
  },
  portraitSummaryPanel: {
    height: '11in',
  },
  landscapeSummaryPanel: {
    height: '8.5in',
  },
  portraitCoverImage: {
    objectFit: 'cover',
  },
  landscapeContactPanel: {
    height: 'unset',
    maxHeight: '8.5in',
  },
}));

const CoverPreview: React.FC<Props> = (
  {
    survey,
    pageMode,
    order,
    anchore,
  },
) => {
  const isLandscape = survey.pdfPaperSize === PDFPaperSize.Landscape;
  const classes = useStyles();
  const summaryCompEle = useRef<any>();
  const { height, ref } = useResizeDetector();
  const imgCompEle = useRef<any>();
  const imgBoxEle = useRef<any>();

  let coverImgURL = '/assets/images/office_exterior.jpg';
  if (survey?.coverBackgroundImage?.cloudfrontUrl) {
    coverImgURL = survey?.coverBackgroundImage?.cloudfrontUrl;
    // coverImage is always from s3 url.
    if (pageMode && pageMode === 'PDF') {
      coverImgURL += '?d=print';
    } else {
      coverImgURL += '?d=web';
    }
  }
  const filteredSurveyContacts: SurveyContact[][] = [];
  const CONTACTS_PER_PAGE = isLandscape ? 5 : 6;
  if (survey && survey.contacts && survey.contacts.length > CONTACTS_PER_PAGE) {
    const itemsLength: number = Math.ceil(survey.contacts.length / CONTACTS_PER_PAGE);
    for (let i = 0; i < itemsLength; i += 1) {
      filteredSurveyContacts.push(survey.contacts
        .slice(i * CONTACTS_PER_PAGE, ((i + 1) * CONTACTS_PER_PAGE)));
    }
  } else {
    filteredSurveyContacts.push(survey.contacts);
  }

  useEffect(() => {
    const summaryEle = summaryCompEle.current;
    const titleHeight = height || 0;
    imgCompEle.current.style.height = `${summaryEle.clientHeight - titleHeight - 2}px`;
    imgBoxEle.current.style.height = `${summaryEle.clientHeight - titleHeight - 2}px`;
  }, [height, survey.pdfPaperSize]);

  return (
    <div id={`${order}`} style={anchore}>
      <SurveyPDFPage mode={pageMode} paperSize={survey.pdfPaperSize}>
        <Grid container direction="row" className={`${!isLandscape ? classes.portraitSummaryPanel : classes.landscapeSummaryPanel}`}>
          <Grid
            item container direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            className={`${!isLandscape ? classes.portraitSummaryPanel : classes.landscapeSummaryPanel}`}
            xs={8}>
            <Grid item className={classes.coverSummary} ref={ref}>
              {
                pageMode === 'PDF' ? <CoverSummaryPDFPreview survey={survey} /> :
                  <CoverSummaryPreview />
              }
            </Grid>
            <Grid item className={classes.coverImageBox} ref={imgBoxEle}>
              <img ref={imgCompEle} className={`${classes.coverImage} ${!isLandscape ? classes.portraitCoverImage : ''}`} src={coverImgURL} alt="" />
            </Grid>
          </Grid>
          <Grid
            ref={summaryCompEle}
            item xs={4}
            className={`${classes.contactPanel}  ${!isLandscape ? classes.portraitContactPanel : classes.landscapeContactPanel}`}>
            <SurveyContactPreviewList surveyContactList={filteredSurveyContacts[0]} />
          </Grid>
        </Grid>
      </SurveyPDFPage>
      {
        filteredSurveyContacts.length > 1 && filteredSurveyContacts.map((contacts, index) =>
          index > 0 && <SurveyPDFPage mode={pageMode}>
            <Grid container direction="row" className={`${!isLandscape ? classes.portraitCoverPanel : classes.coverPanel}`}>
              <Grid
                item container direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                className={`${classes.summaryPanel}  ${!isLandscape ? classes.portraitSummaryPanel : ''}`}
                xs={8}>
                <img className={`${classes.coverImage} ${!isLandscape ? classes.portraitCoverImage : ''}`} src={coverImgURL} alt="" />
              </Grid>
              <Grid
                item xs={4}
                className={`${classes.contactPanel}  ${!isLandscape ? classes.portraitContactPanel : classes.landscapeContactPanel}`}>
                <SurveyContactPreviewList surveyContactList={contacts} />
              </Grid>
            </Grid>
          </SurveyPDFPage>,
        )
      }
    </div>
  );
};

export type Props = {
  survey: Survey;
  pageMode?: string;
  order: string;
  anchore?: object;
};

CoverPreview.displayName = 'CoverPreview';
export default React.memo(CoverPreview);
