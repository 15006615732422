/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import React, { FC, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import TextField from '@cbreone/core-ui/dist/components/DebouncedTextField';
import FormLabel from '@material-ui/core/FormLabel';
import { debounce } from 'lodash';
import { parseInputChange } from '@cbreone/utilities';
import { dateFormatter, getAreaValueWithSF, getAskingPriceValue, getAskingRentValue, getAvailableSFValue, getCommas } from '../../utilities/property/propertyUtil';

const useStyles = makeStyles((theme: Theme) => createStyles({
  formRoot: {
    backgroundColor: theme.palette.grey[100],
    padding: '16px 0px 16px 28px',
  },
  title: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#1A1A1A',
    marginBottom: '16px',
  },
  textFields: {

    width: '25em',
    marginRight: '40px',
    '& .MuiInputBase-root': {
      color: '#212121',
      fontSize: '16px',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #212121',
    },
    '& .MuiOutlinedInput-notchedOutline:focus': {
      border: '1px solid #212121',
    },
    '& .MuiFormLabel-root': {
      // color: '#6b6767'
    },

  },
  lastTextField: {
    width: '45%',
    '& .MuiInputBase-root': {
      color: '#212121',
      fontSize: '16px',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #212121',
    },
    '& .MuiOutlinedInput-notchedOutline:focus': {
      border: '1px solid #212121',
    },
    '& .MuiInputLabel-outlined': {
      color: '#1A1A1A99',
    },
  },

  fieldGap: {
    marginBottom: '1rem',
    display: 'flex',
    // flexWrap: 'wrap'
  },
  headerTwo: {
    marginTop: '32px',
    marginBottom: '16px',
  },
  gridStyle: {
    width: '100%',
  },

}));

const AvailabilitiesInformation: FC<Props> = ({ propertyData, newAddedAvailability, onUpdateAvailability }: any) => {
  const classes = useStyles();
  const stopAccordionPropagation = (e: React.MouseEvent | React.FocusEvent) => e.stopPropagation();

  const [availableSF, setAvailableSF] = useState(getAvailableSFValue(propertyData?.minAvailableSF, propertyData?.maxAvailableSF));

  const [askingRent, setAskingRent] = useState(getAskingRentValue(propertyData?.minAskingRent, propertyData?.maxAskingRent, propertyData?.askingRentServices, propertyData?.askingRentUnit));

  const [askingPrice, setAskingPrice] = useState(getAskingPriceValue(propertyData?.minAskingPrice, propertyData?.maxAskingPrice, propertyData?.askingPriceUnit));

  const [maxContiguous, setMaxContiguous] = useState(getAreaValueWithSF(propertyData?.maxContiguous, propertyData?.maxContiguousUom));

  const [minDivisible, setMinDivisible] = useState(getAreaValueWithSF(propertyData?.minDivisible, propertyData?.minDivisibleUom));

  let debounced: any = null;

  const handleUpdateAvailability = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = parseInputChange(event.target);
    const { propertyId, id } = newAddedAvailability;
    let newValue: any = value?.toLocaleString();

    // refactor to switch case later
    if (name === 'floor') {
      onUpdateAvailability({
        propertyId,
        id,
        name: 'floor',
        value: newValue,
      }, (res: any) => { },
      );
    } else if (name === 'AvailableSF') {
      newValue = getAvailableSFValue(newValue, '')?.trim();
      setAvailableSF(newValue);
      propertyData?.maxAvailableSF && onUpdateAvailability({
        propertyId,
        id,
        name: 'maxAvailableSF',
        value: '',
      }, (res: any) => {
      },
      );
      onUpdateAvailability({
        propertyId,
        id,
        name: 'minAvailableSF',
        value: newValue,
      }, (res: any) => { },
      );
    } else if (name === 'AskingRent') {
      newValue = getAskingRentValue(newValue, '', '', '')?.trim();
      setAskingRent(newValue);
      propertyData?.maxAskingRent && onUpdateAvailability({
        propertyId,
        id,
        name: 'maxAskingRent',
        value: '',
      }, (res: any) => {
      });

      propertyData?.askingRentUnit && onUpdateAvailability({
        propertyId,
        id,
        name: 'askingRentUnit',
        value: '',
      }, (res: any) => { },
      );

      propertyData?.askingRentServices && onUpdateAvailability({
        propertyId,
        id,
        name: 'askingRentServices',
        value: '',
      }, (res: any) => { },
      );

      onUpdateAvailability({
        propertyId,
        id,
        name: 'minAskingRent',
        value: newValue,
      }, (res: any) => { },
      );
    } else if (name === 'AskingPrice') {
      newValue = getAskingPriceValue(newValue, '', '');
      setAskingPrice(newValue);
      propertyData?.maxAskingPrice && onUpdateAvailability({
        propertyId,
        id,
        name: 'maxAskingPrice',
        value: '',
      }, (res: any) => {

      },
      );
      propertyData?.askingPriceUnit && onUpdateAvailability({
        propertyId,
        id,
        name: 'askingPriceUnit',
        value: '',
      }, (res: any) => { });

      onUpdateAvailability({
        propertyId,
        id,
        name: 'minAskingPrice',
        value: newValue?.trim(),
      }, (res: any) => { },
      );
    } else if (name === 'maxContiguous') {
      newValue = getAreaValueWithSF(newValue, '')?.trim();
      setMaxContiguous(newValue);
      propertyData?.maxContiguousUom && onUpdateAvailability({
        propertyId,
        id,
        name: 'maxContiguousUom',
        value: '',
      }, (res: any) => {

      },
      );
      onUpdateAvailability({
        propertyId,
        id,
        name: 'maxContiguous',
        value: newValue,
      }, (res: any) => { },
      );
    } else if (name === 'minDivisible') {
      newValue = getAreaValueWithSF(newValue, '')?.trim();
      setMinDivisible(newValue);
      propertyData?.minDivisibleUom && onUpdateAvailability({
        propertyId,
        id,
        name: 'minDivisibleUom',
        value: '',
      }, (res: any) => {

      },
      );
      onUpdateAvailability({
        propertyId,
        id,
        name: 'minDivisible',
        value: newValue,
      }, (res: any) => { },
      );
    } else {
      onUpdateAvailability({
        propertyId,
        id,
        name,
        value: newValue,
      }, (res: any) => { },
      );
    }
  };

  debounced = debounce((event) => {
    if (event) {
      handleUpdateAvailability(event);
    }
  }, 200);

  const onValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const args = { target: { name: event.target.name, value: event.target.value } };
    if (debounced) {
      debounced.cancel();
    }
    debounced(args);
  };

  return (
    <FormControl className={classes.formRoot}>
      <FormLabel className={classes.title}>
        Availability Information
      </FormLabel>
      <div className={classes.gridStyle}>
        <div className={classes.fieldGap}>
          <TextField
            variant='outlined'
            onFocus={stopAccordionPropagation}
            onChange={onValueChange}
            onBlur={onValueChange}
            className={classes.textFields}
            label='Availability Type'
            name='availabilityType'
            autoFocus={true}
            value={((propertyData?.availabilityType) ? propertyData.availabilityType : '')?.trim()}
            debounceTime={400}
          />
          <TextField
            variant='outlined'
            onFocus={stopAccordionPropagation}
            onChange={onValueChange}
            onBlur={onValueChange}
            className={classes.textFields}
            label='Floor'
            name='floor'
            autoFocus={true}
            value={((propertyData?.floor) ? propertyData.floor : '')?.trim()}
            debounceTime={400}
          />
        </div>
        <div className={classes.fieldGap}>
          <TextField
            variant='outlined'
            onFocus={stopAccordionPropagation}
            onChange={onValueChange}
            onBlur={onValueChange}
            name='suite'
            className={classes.textFields}
            label='Suite'
            autoFocus={true}
            value={(propertyData.suite || '')?.trim()}
            debounceTime={400}

          />

          <TextField
            variant='outlined'
            onFocus={stopAccordionPropagation}
            onChange={onValueChange}
            onBlur={onValueChange}
            className={classes.textFields}
            label='Type'
            name='type'
            autoFocus={true}
            value={(propertyData.type || '')?.trim()}
            debounceTime={400}

          />
        </div>
        <div className={classes.fieldGap}>
          <TextField
            variant='outlined'
            onFocus={stopAccordionPropagation}
            onClick={(e) => {
              stopAccordionPropagation(e);
            }}
            onChange={onValueChange}
            onBlur={onValueChange}
            className={classes.textFields}
            label='Available SF'
            autoFocus={true}
            value={availableSF?.trim()}
            debounceTime={400}
            name='AvailableSF'
          />
          <TextField
            variant='outlined'
            onFocus={stopAccordionPropagation}
            onChange={onValueChange}
            onBlur={onValueChange}
            className={classes.textFields}
            label='Lease Type'
            name='leaseType'
            autoFocus={true}
            value={(propertyData.leaseType || '')?.trim()}
            debounceTime={400}
          />

        </div>
        <div className={classes.fieldGap}>
          <TextField
            variant='outlined'
            onFocus={stopAccordionPropagation}
            onChange={onValueChange}
            onBlur={onValueChange}
            className={classes.textFields}
            label='Availability Status'
            name='availabilityStatus'
            autoFocus={true}
            value={(dateFormatter(propertyData.availabilityStatus) || '')?.trim()}
            debounceTime={400}

          />

          <TextField
            variant='outlined'
            onFocus={stopAccordionPropagation}
            onChange={onValueChange}
            onBlur={onValueChange}
            className={classes.textFields}
            label='Date Available'
            name='availableDate'
            autoFocus={true}
            value={(dateFormatter(propertyData.availableDate) || '')?.trim()}
            debounceTime={400}

          />
        </div>
        <div className={classes.fieldGap}>
          <TextField
            variant='outlined'
            onFocus={stopAccordionPropagation}
            onChange={onValueChange}
            onBlur={onValueChange}
            className={classes.lastTextField}
            label='Occupancy'
            name='occupancy'
            autoFocus={true}
            value={(propertyData.occupancy || '')?.trim()}
            debounceTime={400}

          />
        </div>
      </div>
      <header className={classes.headerTwo}>
        Asking Rent and Asking Price
      </header>
      <div>

        <div className={classes.fieldGap}>
          <TextField
            variant='outlined'
            onFocus={stopAccordionPropagation}
            onClick={(e) => {
              stopAccordionPropagation(e);
            }}
            onChange={onValueChange}
            onBlur={onValueChange}
            className={classes.textFields}
            label='Asking Rent'
            autoFocus={true}

            value={askingRent?.trim()}
            debounceTime={400}
            name='AskingRent'
          />

          <TextField
            variant='outlined'
            onFocus={stopAccordionPropagation}
            onClick={(e) => {
              stopAccordionPropagation(e);
            }}
            onChange={onValueChange}
            onBlur={onValueChange}
            className={classes.textFields}
            label='Asking Price'
            autoFocus={true}
            debounceTime={400}
            value={askingPrice?.trim()}
            name='AskingPrice'
          />
        </div>

      </div>
      <header className={classes.headerTwo}>
        Additional Information
      </header>
      <div>

        <div className={classes.fieldGap}>
          <TextField
            variant='outlined'
            onFocus={stopAccordionPropagation}
            onChange={onValueChange}
            onBlur={onValueChange}
            className={classes.textFields}
            label='Max Contiguous (SF)'
            name='maxContiguous'
            autoFocus={true}
            value={maxContiguous?.trim()}
            debounceTime={400}

          />

          <TextField
            variant='outlined'
            onFocus={stopAccordionPropagation}
            onChange={onValueChange}
            onBlur={onValueChange}
            className={classes.textFields}
            label='Min Divisible (SF)'
            name='minDivisible'
            autoFocus={true}
            value={minDivisible?.trim()}
            debounceTime={400}

          />
        </div>
        <div className={classes.fieldGap}>
          <TextField
            variant='outlined'
            onFocus={stopAccordionPropagation}
            onChange={onValueChange}
            onBlur={onValueChange}
            className={classes.textFields}
            label='Term'
            name='term'
            autoFocus={true}
            value={(propertyData.term || '')?.trim()}
            debounceTime={400}

          />

          <TextField
            variant='outlined'
            onFocus={stopAccordionPropagation}
            onChange={onValueChange}
            onBlur={onValueChange}
            className={classes.textFields}
            label='# of Parking Spaces'
            name='parkingSpacesNum'
            autoFocus={true}
            value={getCommas(propertyData?.parkingSpacesNum)?.trim()}
            debounceTime={400}

          />
        </div>
        <div className={classes.fieldGap} >
          <TextField
            variant='outlined'
            onFocus={stopAccordionPropagation}
            onChange={onValueChange}
            onBlur={onValueChange}
            className={classes.lastTextField}
            label='Virtual Tour'
            name='virtualTour'
            autoFocus={true}
            value={(propertyData.virtualTour || '')?.trim()}
            debounceTime={400}

          />
        </div>

      </div>

    </FormControl >
  );
};

export type Props = {
  propertyData: any,
  newAddedAvailability: any,
  onUpdateAvailability: any;
};

export default React.memo(AvailabilitiesInformation);
