import { makeStyles, createStyles } from '@material-ui/core';

export const usePreviewStyles = makeStyles((theme) =>
  createStyles({
    availabilityTable: {
      marginTop: '12px',
    },
    headerStyle: {
      color: '#1A1A1A',
      fontWeight: 500,
      fontSize: '12px',
      fontFamily: 'Calibre-R',

    },
    contentStyle: {
      fontSize: '12px',
      whiteSpace: 'pre-line',
      fontFamily: 'Calibre-R',
    },
    tableCell: {
      padding: 8,
      borderBottom: 0,
      width: '50px',
      maxWidth: '50px',
      lineHeight: '16px',
      wordWrap: 'break-word',
    },
    tableHeader: {
      background: '#F5F7F7',
      borderBottom: '1px solid #DFE6E5',

    },
    availabilityTableCell: {
      padding: 8,
      borderBottom: 0,
      width: '74px',
      wordWrap: 'break-word',
      lineHeight: '16px',
      maxWidth: '74px',
      textAlign: 'left',
    },
    availabilityPraposedTableCell: {
      padding: 8,
      borderBottom: 0,
      width: '120px',
      lineHeight: '16px',
      maxWidth: '120px',
      textAlign: 'left',
    },
    commentSectionHeading: {
      width: '98%',
      margin: 'auto',
      marginTop: '20px',
      color: theme.palette.primary.main,
    },
    availabilityHeading: {
      width: '98%',
      margin: 'auto',
      color: theme.palette.primary.main,
    },
    commentSection: {
      width: '98%',
      margin: 'auto',
      marginTop: '10px',
      whiteSpace: 'pre-line',
      wordWrap: 'break-word',
    },
    floorplanContainer: {
      display: 'inline-flex',
      height: '7.5in',
      margin: 'auto',
    },
    floorplanPortraitContainer: {
      width: '8.5in',
    },
    floorplanLandscapeContainer: {
      width: '11in',
    },
    floorPlanImg: {
      height: '100%',
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
    },
    ftImg: {
      width: '98%',
      margin: 'auto',
      paddingBottom: '60%',
      backgroundColor: theme.palette.grey['100'],
      textAlign: 'center',
    },
    propertyImg: {
      display: 'inline-flex',
      margin: 'auto',
      width: '100%',
    },
    oddRow: {
      background: '#fbfcfc',
      verticalAlign: 'top',
    },
    evenRow: {
      background: theme.palette.common.white,
      verticalAlign: 'top',
    },
    pageContainer: {
      padding: '24px',
    },
    pageContainerLandscapeNoHeaderHeight: {
      height: '8in',
    },
    pageContainerLandscapeHeaderHeight: {
      height: '7in',
    },
    pageContainerPortraitNoHeaderHeight: {
      height: '10.5in',
    },
    pageContainerPortraitHeaderHeight: {
      height: '9.5in',
    },
    footer: {
      width: '100%',
      backgroundColor: '#cccccc',
      height: '0.5in',
      position: 'absolute',
      bottom: 0,
    },
    link: {
      wordWrap: 'break-word',
      fontSize: '12px',
    },
  }),
);

export default usePreviewStyles;
