import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import externalTheme from '@cbreone/core-ui/dist/themes/externalTheme';

import { PropertyPatch, Survey, SurveyPatch } from '../../types';
import CoverPreview from '../CoverPreview';
import PropertyComparisonPreview from '../PropertyComparisonPreview';
import TourSchedulePreview from '../TourSchedulePreview';
import CustomSectionPreview from '../CustomSectionPreview';
import MarketMapSectionPreview from '../MarketMapPreview/MarketMapSectionPreview';
import PropertyPreviewWrapper from '../PropertyPreview/PropertyPreviewWrapper';
import { UseSurveyEditorAPI } from '../../pages/SurveyBuilder/useSurveyEditorAPI';

// style fix to account for Header height when jumping to positions in PDF preview
const anchore = {
  marginTop: '-148px',
  paddingTop: '148px',
};

const SurveyPDFPreview: React.FC<Props> = ({
  survey,
  updateSurvey,
  updatePropertyCoordinates,
  onPropertySelect,
  onUpdateProperty,
}) => (
  <ThemeProvider theme={externalTheme}>
    {survey.sections.map((section) => {
      if (section.isHidden) return null;
      switch (section.name) {
        case 'cover': {
          return (
            <CoverPreview
              order={`${section.order}`}
              key={section.surveySectionId}
              survey={survey}
              anchore={anchore}
            />
          );
        }
        case 'market map': {
          return (
            <MarketMapSectionPreview
              order={`${section.order}`}
              key={section.surveySectionId}
              survey={survey}
              anchore={anchore}
              updateSurvey={updateSurvey}
              updatePropertyCoordinates={updatePropertyCoordinates}
              onPropertySelect={onPropertySelect}
              onUpdateProperty={onUpdateProperty}
            />
          );
        }
        case 'property comparison': {
          return (
            <PropertyComparisonPreview
              order={`${section.order}`}
              key={section.surveySectionId}
              survey={survey}
              anchore={anchore}
            />
          );
        }
        case 'properties': {
          return (
            <PropertyPreviewWrapper
              order={`${section.order}`}
              key={section.surveySectionId}
              survey={survey}
              anchore={anchore}
            />
          );
        }
        case 'tour schedule': {
          return (
            <TourSchedulePreview
              order={`${section.order}`}
              key={section.surveySectionId}
              survey={survey}
              anchore={anchore}
            />
          );
        }
        default:
          return (
            <CustomSectionPreview
              order={`${section.order}`}
              key={section.surveySectionId}
              section={section}
              anchore={anchore}
            />
          );
      }
    })}
  </ThemeProvider>
);

export type Props = {
  survey: Survey;
  updatePropertyCoordinates: UseSurveyEditorAPI['updateProperty'];
  updateSurvey: (survey: SurveyPatch) => void;
  onPropertySelect: (property: string) => void;
  onUpdateProperty: (property: PropertyPatch) => void;
};

SurveyPDFPreview.displayName = 'SurveyPDFPreview';
export default SurveyPDFPreview;
