import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  createStyles,
  Drawer,
  Grid,
  IconButton,
  Theme,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { parseInputChange } from '@cbreone/utilities';
import { UseSurveyEditorAPI } from '../../pages/SurveyBuilder/useSurveyEditorAPI';
import { Survey, SurveyPatch } from '../../types';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: 'calc(100% - 90px)',
    overflow: 'auto',
  },
  header: {
    borderBottom: '1px solid rgba(0, 63, 45, 0.15)',
  },
  paper: {
    width: '330px',
  },
  selector: {
    width: '282px',
    height: '42px',
    position: 'absolute',
  },
  selectorBox: {
    position: 'absolute',
    padding: '0px 24px 0px 24px',
  },
  selectorBoxLabel: {
    paddingLeft: '35px',
  },
  legendName: {
    padding: '16px 35px 16px 16px',
  },
  border: {
    boxhsadow: 'inset 0px -1px 0px rgba(0, 63, 45, 0.15)',
  },
  iconSize: {
    width: '20px',
    padding: '4px',
  },
  borderTop: {
    paddingTop: '32px',
  },
  cityStyle: {
    marginLeft: '180px',
  },
  stateStyle: {
    marginLeft: '170px',
  },
  zipCodeStyle: {
    marginLeft: '142px',
  },
  checkBoxStyles: {
    marginLeft: '100px',
  },
  hideMenuItem: {
    display: 'none',
  },
}));

const CustomizeLegend: React.FC<Props> = ({
  survey,
  onToggleDrawer,
  rightDrawerOpen,
  updateSurvey,
}) => {
  const classes = useStyles();
  const [numberSelected, setNumberSelected] = useState(0);

  const onCheckBoxChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = (e.target ? parseInputChange(e.target) : e) as {
      name: string;
      value: true;
    };
    updateSurvey({
      id: survey.id,
      customLegends: {
        hasDisplayLegendOnSeparatePage: false,
        displayDensity: 'small',
        displayFields: {
          hasIcon: true,
          hasPropertyName: name === 'hasPropertyName' ? value : survey.customLegends?.displayFields.hasPropertyName,
          hasAddressLine1: name === 'hasAddressLine1' ? value : survey.customLegends?.displayFields.hasAddressLine1,
          hasAddressLine2: name === 'hasAddressLine2' ? value : survey.customLegends?.displayFields.hasAddressLine2,
          hasCity: name === 'hasCity' ? value : survey.customLegends?.displayFields.hasCity,
          hasState: name === 'hasState' ? value : survey.customLegends?.displayFields.hasState,
          hasZipCode: name === 'hasZipCode' ? value : survey.customLegends?.displayFields.hasZipCode,
          hasGroupName: false,
          hasGroupIconOnlyAvailable: false,
        },
      },
    });
  };

  useEffect(() => {
    if (survey?.customLegends === undefined) {
      updateSurvey({
        id: survey.id,
        customLegends: {
          hasDisplayLegendOnSeparatePage: false,
          displayDensity: 'small',
          displayFields: {
            hasIcon: true,
            hasPropertyName: true,
            hasAddressLine1: true,
            hasAddressLine2: true,
            hasCity: true,
            hasState: true,
            hasZipCode: true,
            hasGroupName: false,
            hasGroupIconOnlyAvailable: false,
          },
        },
      });
    }
  }, [survey.customLegends, survey.id, updateSurvey]);

  const numberSelectedArray = [
    survey.customLegends?.displayFields.hasPropertyName,
    survey.customLegends?.displayFields.hasAddressLine1,
    survey.customLegends?.displayFields.hasCity,
    survey.customLegends?.displayFields.hasState,
    survey.customLegends?.displayFields.hasZipCode,
  ];

  useEffect(() => {
    const numberOfTrueValues = () => {
      let counter = 0;
      for (let index = 0; index < numberSelectedArray.length; index += 1) {
        if (numberSelectedArray[index] === true) {
          counter += 1;
        }
      }
      return counter;
    };
    setNumberSelected(numberOfTrueValues);
  }, [numberSelectedArray]);

  return (
    <>
      <Drawer
        variant="temporary"
        anchor="right"
        open={rightDrawerOpen}
        onClose={() => onToggleDrawer(false)}
        classes={{ paper: classes.paper }}
        style={{ height: 'calc(100% - 64px)', paddingTop: '100px' }}
      >
        <div className={classes.root}>
          <Grid container className={(classes.header)}>
            <Grid item xs={10} className={classes.legendName}>
              <Typography variant="h6" component="h2">
                Legend Display
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.iconSize}>
              <IconButton onClick={() => onToggleDrawer(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid>
            <Grid
              className={classes.borderTop}
            >
              <FormControl
                fullWidth
                variant="outlined"
                className={(classes.selectorBox)}
              >
                <InputLabel
                  id="custom-legend-label"
                  className={(classes.selectorBoxLabel)}
                >
                  Display Fields
                </InputLabel>
                <Select
                  className={(classes.selector, classes.border)}
                  labelId="custom-legend-label"
                  label="Custom Legend"
                  value="displayField"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem
                    className={classes.hideMenuItem}
                    value="displayField"
                  >
                    Selected ({`${numberSelected}`})
                  </MenuItem>
                  <MenuItem
                  >
                    <FormControlLabel
                      style={{ marginLeft: '8px' }}
                      labelPlacement="start"
                      label="Property Name"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      control={
                        <Checkbox
                          className={classes.checkBoxStyles}
                          name="hasPropertyName"
                          checked={survey.customLegends?.displayFields.hasPropertyName}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={onCheckBoxChange}
                        />
                      }
                    />
                  </MenuItem>
                  <MenuItem value="AddressLine1">
                    <FormControlLabel
                      style={{ marginLeft: '8px' }}
                      labelPlacement="start"
                      label="Address Line 1"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      control={
                        <Checkbox
                          className={classes.checkBoxStyles}
                          name="hasAddressLine1"
                          checked={survey.customLegends?.displayFields.hasAddressLine1}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={onCheckBoxChange}
                        />
                      }
                    />
                  </MenuItem>
                  <MenuItem value="Property name">
                    <FormControlLabel
                      style={{ marginLeft: '8px' }}
                      labelPlacement="start"
                      label="City"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      control={
                        <Checkbox
                          className={classes.cityStyle}
                          name="hasCity"
                          checked={survey.customLegends?.displayFields.hasCity}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={onCheckBoxChange}
                        />
                      }
                    />
                  </MenuItem>
                  <MenuItem value="Property name">
                    <FormControlLabel
                      style={{ marginLeft: '8px' }}
                      labelPlacement="start"
                      label="State"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      control={
                        <Checkbox
                          className={classes.stateStyle}
                          name="hasState"
                          checked={survey.customLegends?.displayFields.hasState}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={onCheckBoxChange}
                        />
                      }
                    />
                  </MenuItem>
                  <MenuItem value="Property name">
                    <FormControlLabel
                      style={{ marginLeft: '8px' }}
                      labelPlacement="start"
                      label="Zip Code"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      control={
                        <Checkbox
                          className={classes.zipCodeStyle}
                          name="hasZipCode"
                          checked={survey.customLegends?.displayFields.hasZipCode}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onChange={onCheckBoxChange}
                        />
                      }
                    />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </Drawer>
    </>
  );
};

export type Props = {
  onReorder: UseSurveyEditorAPI['reorderSurveyAttribute'];
  onUpdatePropertyFieldRule: UseSurveyEditorAPI['updatePropertyFieldRule'];
  onToggleDrawer: (isOpen: boolean) => void;
  rightDrawerOpen: boolean;
  survey: Survey;
  updateSurvey: (survey: SurveyPatch) => void;
};

CustomizeLegend.displayName = 'CustomizeLegend';
export default React.memo(CustomizeLegend);
