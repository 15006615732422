import React from 'react';
import {
  makeStyles, createStyles,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { PDFPaperSize } from '../../data/constants';
import { MarketMapStatePatch } from '../../redux/types/dataTypes';

const commonPDFContainer = {
  margin: 'auto',
  border: '1px solid #cccccc',
  boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
  overflow: 'hidden',
  fontFamily: 'Calibre-R',
};

const useStyles = makeStyles((theme) => createStyles({
  printPageContainerLandscape: {
    width: '11in',
    height: '8.5in',
    position: 'relative',
    backgroundColor: theme.palette.primary.contrastText,
    ...commonPDFContainer,
  },
  printPageContainerPortrait: {
    width: '8.5in',
    height: '11in',
    position: 'relative',
    backgroundColor: theme.palette.primary.contrastText,
    ...commonPDFContainer,
  },
  previewPageContainerLandscape: {
    width: '11in',
    height: '8.5in',
    position: 'relative',
    backgroundColor: theme.palette.primary.contrastText,
    marginBottom: '20px',
    ...commonPDFContainer,
  },
  previewPageContainerPortrait: {
    width: '8.5in',
    height: '11in',
    position: 'relative',
    backgroundColor: theme.palette.primary.contrastText,
    marginBottom: '20px',
    ...commonPDFContainer,
  },
  previewFlyerContainerLandscape: {
    width: '11in',
    height: 'auto',
    position: 'relative',
    backgroundColor: theme.palette.primary.contrastText,
    marginBottom: '20px',
    ...commonPDFContainer,
  },
  previewFlyerContainerPortrait: {
    width: '8.5in',
    height: 'auto',
    position: 'relative',
    backgroundColor: theme.palette.primary.contrastText,
    marginBottom: '20px',
    ...commonPDFContainer,
  },
}));

interface PagePreviewProps {
    children?: React.ReactNode;
    mode: string | undefined;
    paperSize: string | undefined;
    marketMapState?: MarketMapStatePatch;
}

const SurveyPDFPage:React.FC<PagePreviewProps> = (props) => {
  const {
    children,
    mode,
    marketMapState,
  } = props;
  const classes = useStyles();
  let styling;
  switch (mode) {
    case ('PDF'):
      styling = marketMapState?.pdfPaperSize ===
      PDFPaperSize.Portrait ? classes.printPageContainerPortrait : classes.printPageContainerLandscape;
      break;
    case ('PDFPreview'):
      styling = marketMapState?.pdfPaperSize ===
      PDFPaperSize.Portrait ? classes.previewPageContainerPortrait : classes.previewPageContainerLandscape;
      break;
    case ('FlyerPreview'):
      styling = marketMapState?.pdfPaperSize ===
       PDFPaperSize.Portrait ? classes.previewFlyerContainerPortrait : classes.previewFlyerContainerLandscape;
      break;
    default:
      styling = marketMapState?.pdfPaperSize ===
      PDFPaperSize.Portrait ? classes.previewPageContainerPortrait : classes.previewPageContainerLandscape;
      break;
  }

  return (
      <div className={styling} >
            {children}
      </div>
  );
};

const mapStateToProps = (state: any, ownProps: any) => ({
  marketMapState: state.map,
  ...ownProps,
});

SurveyPDFPage.displayName = 'SurveyPDFPage';
export default connect(mapStateToProps)(SurveyPDFPage);
