/* eslint-disable radix */
/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
import { FieldHookCallback, IDictionary } from '@flatfile/adapter';
import { IDataHookResponse, ScalarDictionaryWithCustom } from '@flatfile/react';
import { hasEmptyAvailablity } from './flatfileUtil';

// let cacheRecords: { record: ScalarDictionaryWithCustom, index: number }[] = []

function putResponse(hookResponse: IDataHookResponse, field: string, rkey: string, rvalue: any) {
  hookResponse[field] = hookResponse[field] ?? {};
  const hr = hookResponse[field];

  switch (rkey) {
    case 'value': {
      hr[rkey] = rvalue;
      break;
    }
    case 'info': {
      hr[rkey] = hr[rkey] ?? [];
      const rfield = hr[rkey];
      rfield?.push({ message: rvalue, level: 'error' });
      break;
    }
    default:
      break;
  }
}

function validateTest(hookResponse: IDataHookResponse,
  record: ScalarDictionaryWithCustom, field: string, pattern: RegExp, message: string) {
  const value = (record[field] || '') as string;

  if (!pattern.test(value)) {
    putResponse(hookResponse, field, 'info', message);
    return false;
  }
  return true;
}

export const ff_fieldHooks: IDictionary<FieldHookCallback> = {
  address1: (_values) =>
  // cacheRecords = []
    []
  // return values.map(([item, index]) => [
  //     {
  //         value: item + "@fieldHooks",
  //         info: [
  //             { message: "fieldHooks-info", level: "info" },
  //             { message: "fieldHooks-warning", level: "warning" },
  //             { message: "fieldHooks-error", level: "error" },
  //         ]
  //     },
  //     index
  // ]);
  ,
};

function ff_onRecordHook(record: ScalarDictionaryWithCustom, index: number):
 IDataHookResponse | Promise<IDataHookResponse> {
  const hookResponse: IDataHookResponse = {};

  for (const field in record) {
    if (field === '$custom') {
      continue;
    }
    const value = record[field];
    if (value) {
      const svalue = value as string;

      if ((/^\s|\s$/).test(svalue)) {
        record[field] = svalue.trim();
        putResponse(hookResponse, field, 'value', record[field]);
      }
    }
  }

  if (record.hasOwnProperty('floor')) {
    switch (record.availabilityType) {
      case 'Entire Building':
      {
        if (record.floor) {
          putResponse(hookResponse, 'floor', 'info',
            'Cannot have a value if Entire Building is selected',
          );
        }
        break;
      }
      case 'Entire Floor':
      case 'Partial Floor':
      {
        validateTest(hookResponse, record, 'floor',
          /^(Mezzanine|Lower Level|\d{1,3})$/g,
          'Enter a floor number under 1000, Mezzanine, or Lower Level',
        );
        break;
      }
      default:
      {
        validateTest(hookResponse, record, 'floor',
          /^(Mezzanine|Lower Level|\d{0,3})$/g,
          'Enter a floor number under 1000, Mezzanine, or Lower Level',
        );
        break;
      }
    }
  } else if (record.availabilityType !== 'Entire Building' && !hasEmptyAvailablity(record)) {
    putResponse(hookResponse, 'availabilityType', 'info',
      'The column "Floor" is not existing.<br>Make sure that column to be in template',
    );
  }

  if (!record.askingPriceUnit && !!record.askingPrice) {
    record.askingPriceUnit = '$';
    putResponse(hookResponse, 'askingPriceUnit', 'value', record.askingPriceUnit);
  }

  if (record.availableDate) {
    if (validateTest(hookResponse, record, 'availableDate',
      /^\d{1,2}\/\d{1,2}\/\d{4}$/g,
      'Please enter a date in this format MM/DD/YYYY or M/D/YYYY')) {
      const value = record.availableDate as string;
      const [month, day] = value.match(/\d{1,2}/g) as string[];
      const m = parseInt(month);
      const d = parseInt(day);

      if (isNaN(m) || m < 1 || m > 12) {
        putResponse(hookResponse, 'availableDate', 'info', 'Please enter a month between 1 and 12');
      } else if (isNaN(d) || d < 1 || d > 31) {
        putResponse(hookResponse, 'availableDate', 'info', 'Please enter a day between 1 and 31');
      }
    }
  }

  return hookResponse;
}

export function ff_onRecordInit(record: ScalarDictionaryWithCustom, index: number)
: IDataHookResponse | Promise<IDataHookResponse> {
  const hookResponse = ff_onRecordHook(record, index);
  // cacheRecords.push({ record, index })
  return hookResponse;
}

export function ff_onRecordChange(record: ScalarDictionaryWithCustom, index: number):
IDataHookResponse | Promise<IDataHookResponse> {
  return ff_onRecordHook(record, index);
}
