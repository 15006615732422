import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  Button,
  List,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

import { ErrorModal } from '@cbreone/core-ui';
import SurveyListItem from '../../components/SurveyListItem';
import { copySurveyMediaFiles } from '../../services/media-service';
import * as SurveyService from '../../services/survey-service';
import { MediaOwner, Survey } from '../../types';

const useStyles = makeStyles((theme: Theme) => createStyles({
  header: {
    padding: theme.spacing(3.75),
    backgroundColor: theme.palette.grey['300'],
  },
  iconButton: {
    padding: theme.spacing(1.25),
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '24px',
  },
  content: {
    marginLeft: theme.spacing(6.25),
    marginRight: theme.spacing(6.25),
    marginTop: theme.spacing(4.5),
  },
}));

const SurveyList: React.FC<Props> = () => {
  const classes = useStyles();
  const history = useHistory();
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const copySurveyError = 'We were unable to copy your survey. Try refreshing the page, or coming back later.';
  useEffect(() => {
    SurveyService.getSurveys().then((res) => setSurveys(res.surveys));
  }, []);

  const onDelete = (surveyId: string) => {
    SurveyService.deleteSurvey(surveyId).then(() => {
      setSurveys(surveys.filter((survey) => survey.id !== surveyId));
    });
  };

  const onCopy = (surveyId: string) => {
    SurveyService.copySurvey(surveyId).then(
      (data) => {
        const copyMediaRes = [
          {
            ownerId: data.id,
            copiedFrom: data.copiedFrom || '',
            idType: 'survey',
          },
        ] as MediaOwner[];
        if (Array.isArray(data.properties) && data.properties.length > 0) {
          data.properties.forEach((property) => {
            copyMediaRes.push({
              ownerId: property.id,
              copiedFrom: property.copiedFrom || '',
              idType: 'property',
            });
          });
        }
        copySurveyMediaFiles(copyMediaRes).then(
          (res) => {
            history.push(`/${data.id}/builder`);
          },
          () => {
            SurveyService.deleteSurvey(data.id);
            setOpenErrorModal(true);
          },
        );
      },
      () => {
        setOpenErrorModal(true);
      },
    );
  };

  return (
    <div>
      <div className={classes.content}>
        <div className={classes.wrapper}>
          {/* @Todo "post-pilot" work to reference for later. */}
          {/*           <TextField
            id="SC-SearchSurveys"
            label="Search"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          /> */}
          <Button
            id="SC-CreateNewSurvey"
            startIcon={<AddIcon />}
            component={Link}
            to={'/build'}
          >
            New Survey
          </Button>
        </div>
        <List>
          {surveys
            && surveys.map((survey) => (
              <SurveyListItem
                key={survey.id}
                survey={survey}
                onDelete={onDelete}
                onCopy={onCopy}
              />
            ))}
        </List>
      </div>
      {openErrorModal && (
        <ErrorModal
          errorMsg={copySurveyError}
          handleErrorModalClose={() => setOpenErrorModal(false)}
        />
      )}
    </div>
  );
};

export type Props = {};

SurveyList.displayName = 'SurveyList';
export default SurveyList;
