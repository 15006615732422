import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@cbreone/core-ui/dist/components/DebouncedTextField';
import { Property } from '../../types';
import FormCellRenderer from './FormCellRenderer';

const useStyles = makeStyles((theme: Theme) => ({
  v_spacer: {
    height: theme.spacing(3),
  },
}));

const PropertyInfoForm: React.FC<Props> = ({
  property,
  handlePropertyUpdate,
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6">Property Information</Typography>
      <div className={classes.v_spacer} />

      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <FormCellRenderer
            property={property}
            name="totalAvailableSpace"
            label="Total Available Space (SF)"
            handlePropertyUpdate={handlePropertyUpdate}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormCellRenderer
            property={property}
            name="propertySize"
            label="Property Size (SF)"
            handlePropertyUpdate={handlePropertyUpdate}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormCellRenderer
            property={property}
            name="netRentableArea"
            label="Net Rentable Area (SF)"
            handlePropertyUpdate={handlePropertyUpdate}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={property.propertyType}
            onChange={handlePropertyUpdate}
            onBlur={handlePropertyUpdate}
            fullWidth
            name="propertyType"
            label="Property Type"
            debounceTime={100}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={property.floors}
            onChange={handlePropertyUpdate}
            onBlur={handlePropertyUpdate}
            fullWidth
            name="floors"
            label="Floors in Building"
            debounceTime={100}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormCellRenderer
            property={property}
            name="typicalFloorSize"
            label="Typical Floor Size (SF)"
            handlePropertyUpdate={handlePropertyUpdate}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={property.submarket}
            onChange={handlePropertyUpdate}
            onBlur={handlePropertyUpdate}
            fullWidth
            name="submarket"
            label="Submarket"
            debounceTime={100}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={property.market}
            onChange={handlePropertyUpdate}
            onBlur={handlePropertyUpdate}
            fullWidth
            name="market"
            label="Market"
            debounceTime={100}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={property.yearBuilt}
            onChange={handlePropertyUpdate}
            onBlur={handlePropertyUpdate}
            fullWidth
            name="yearBuilt"
            label="Year Built"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={property.yearRenovated}
            onChange={handlePropertyUpdate}
            onBlur={handlePropertyUpdate}
            fullWidth
            name="yearRenovated"
            label="Year Renovated"
            debounceTime={100}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={property.parkingSpaces}
            onChange={handlePropertyUpdate}
            onBlur={handlePropertyUpdate}
            fullWidth
            name="parkingSpaces"
            label="Number of Parking Spaces"
            debounceTime={100}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={property.parkingRatio}
            onChange={handlePropertyUpdate}
            onBlur={handlePropertyUpdate}
            fullWidth
            name="parkingRatio"
            label="Parking Ratio"
            debounceTime={100}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            value={property.buildingClass}
            onChange={handlePropertyUpdate}
            onBlur={handlePropertyUpdate}
            fullWidth
            name="buildingClass"
            label="Class"
            debounceTime={100}
          />
        </Grid>
        {/* <Grid item sm={6} xs={12}>
          <FormCellRenderer
            property={property}
            name="askingRent"
            label="Asking Rent"
            handlePropertyUpdate={handlePropertyUpdate}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormCellRenderer
            property={property}
            name="askingPrice"
            label="Asking Price"
            handlePropertyUpdate={handlePropertyUpdate}
          />
        </Grid> */}
      </Grid>
    </>
  );
};

export type Props = {
  property: Property;
  handlePropertyUpdate: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
};

PropertyInfoForm.displayName = 'PropertyInfoForm';
export default PropertyInfoForm;
