import React, {
  ReactText, useMemo, ReactElement, useState,
} from 'react';
import {
  Button, createStyles, IconButton, Link, makeStyles, Switch, Theme, Typography, Chip,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { externalTheme } from '@cbreone/core-ui';
import CheckIcon from '@material-ui/icons/Check';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DragAndDropList from '@cbreone/core-ui/dist/components/DragAndDropList';
import DraggableListItem from '@cbreone/core-ui/dist/components/DraggableListItem';
import Analytics from '@cbreone/core-services/dist/services/analytic-service';
import { UseSurveyEditorAPI } from '../../pages/SurveyBuilder/useSurveyEditorAPI';
import { Survey } from '../../types';

const useStyles = makeStyles((theme: Theme) => createStyles({
  header: {
    padding: theme.spacing(2.5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  intercomPadding: {
    height: 'calc(100% - 100px)',
    overflow: 'auto',
  },
  uploadButton: {
    padding: theme.spacing(2.5),
    display: 'flex',
    justifyContent: 'flex-end',
  },

  propertiesList: {
    backgroundColor: '#F8F8F8',
    '& ul': {
      listStyle: 'none',
      padding: 0,
    },
  },

  addNewPropertyButton: {
    marginRight: 20,
  },

  propertyCard: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2.5),
    borderBottom: '1px solid #CDCDCD',
  },

  propertyCardName: {
    alignSelf: 'center',
    width: '50%',
    cursor: 'pointer',
  },

  propertyCardControls: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    alignItems: 'center',
  },

  propertyIndex: {
    marginLeft: theme.spacing(-3),
    marginRight: theme.spacing(3),
    width: theme.spacing(3),
  },
  chip: {
    backgroundColor: externalTheme.palette.primary.main,
    color: '#FFFFFF',
    fontSize: '12px',
  },
  shortlistChip: {
    width: '225px',
  },

}));

const ManagePropertiesView: React.FC<Props> = ({
  onAddProperty,
  onPropertySelect,
  onUpdateProperty,
  onDeleteProperty,
  onDismiss,
  onReorderProperties,
  onClickFileUpload,
  survey,
}) => {
  const classes = useStyles();
  const propertyIndexMap = useMemo(() => {
    let index = 0;
    return survey.properties.reduce((acc, property) => {
      if (property.isHidden) {
        return { ...acc, [property.id]: <>&#8211;</> };
      }
      if (property.isCurrentLocation) {
        return { ...acc, [property.id]: <>&#9733;</> };
      }
      index += 1;
      return { ...acc, [property.id]: index };
    }, {} as { [x: string]: ReactText | ReactElement; });
  }, [survey.properties]);

  const [propertiesStatus, setPropertiesStatus] = useState<any>(
    survey.properties.reduce((acc, property) => (
      { ...acc, [property.id]: !property.isHidden }), {} as { [x: string]: any; },
    ));

  return (
    <div className={classes.intercomPadding}>
      <div className={classes.header}>
        <Typography variant="h4">Manage Properties</Typography>
        <IconButton
          id="SC-CloseManageProperties"
          onClick={onDismiss}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.uploadButton}>
        <div className={classes.addNewPropertyButton}>
          <Button
            id="SC-CreateNewProperty"
            onClick={() => onAddProperty()}
            variant="text"
            startIcon={<AddIcon />}
          >
            New Property
          </Button>
        </div>
        <Button
          id="SC-UploadPropertiesfromExcel"
          onClick={() => onClickFileUpload()}
          startIcon={<CloudUploadOutlinedIcon />}
        >
          Upload properties from Excel
        </Button>
      </div>
      <div className={classes.propertiesList}>
        <DragAndDropList
          droppableId="properties"
          onDragEnd={onReorderProperties}
        >
          {survey.properties.map((property, index) => (
            <DraggableListItem
              id="SC-DragHandle"
              onClick={() => Analytics.send({ DragHandleEntity: 'Individual Property' })}
              key={property.id}
              className={classes.propertyCard}
              index={index}
              draggableId={property.id}
            >
              <strong className={classes.propertyIndex}>
                {propertyIndexMap[property.id]}
              </strong>
              <div className={classes.propertyCardName}>
                <Typography>
                  <Link onClick={() => onPropertySelect(property.id)} >
                    {property.name}
                  </Link>
                </Typography>
              </div>
              <div className={classes.shortlistChip}>
                {property.isClientShortlist &&
                  <Chip
                    label="Shortlisted"
                    size="small"
                    icon={<CheckIcon className={classes.chip} />}
                    className={classes.chip} />
                }
              </div>
              <div className={classes.propertyCardControls}>
                {/* <PhotoCameraOutlinedIcon />
                <InsertDriveFileOutlinedIcon /> */}
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        id="SC-ToggleShowHide"
                        data-testid={`switch-${property.id}`}
                        checked={propertiesStatus[property.id]}
                        onChange={
                          (e) => {
                            onUpdateProperty({
                              id: property.id,
                              isHidden: !e.target.checked,
                            });
                            setPropertiesStatus({
                              ...propertiesStatus,
                              [property.id]: e.target.checked,
                            });
                          }
                        }
                        onClick={() => Analytics.send({ ShowHideEntity: 'IndividualProperty' })}
                        color="primary"
                      />
                    }
                    label="Show"
                    labelPlacement="start"
                  />
                </FormGroup>
                <IconButton
                  id="SC-DeleteProperty"
                  onClick={() => {
                    onDeleteProperty({
                      propertyId: property.id,
                      ownerId: property.ownerId,
                      ownerType: property.ownerType,
                    });
                  }
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </DraggableListItem>
          ))}
        </DragAndDropList>
      </div>
      {/* <div className={classes.addNewPropertyButton}>
        <Button
          id="SC-CreateNewProperty"
          onClick={() => onAddProperty()}
          variant="text"
          startIcon={<AddIcon />}
        >
          New Property
      </Button>
      </div> */}
    </div>);
};

export type Props = {
  onAddProperty: UseSurveyEditorAPI['addProperty'];
  onDeleteProperty: UseSurveyEditorAPI['deleteProperty'];
  onDismiss: () => void;
  onPropertySelect: UseSurveyEditorAPI['selectProperty'];
  onUpdateProperty: UseSurveyEditorAPI['updateProperty'];
  onReorderProperties: UseSurveyEditorAPI['reorderSurveyAttribute'];
  onClickFileUpload: () => void;
  survey: Survey;
};

ManagePropertiesView.displayName = 'ManagePropertiesView';
export default React.memo(ManagePropertiesView);
