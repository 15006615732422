import { parseInputChange } from '@cbreone/utilities';
import {
  Button, createStyles, Grid, makeStyles, Theme,
} from '@material-ui/core';
import TextField from '@cbreone/core-ui/dist/components/DebouncedTextField';
import getObjectDiff from '@cbreone/utilities/dist/getObjectDiff';
import React, { useState } from 'react';
import { SurveyContact, SurveyContactPatch, SurveyContactPost } from '../../types';

const useStyles = makeStyles((theme: Theme) => createStyles({
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
}));

const SurveyContactForm: React.FC<Props> = ({ onClose, onSubmit, surveyContact }) => {
  const classes = useStyles();
  const [contact, setContact] = useState<SurveyContact>(surveyContact as SurveyContact);

  const onSubmitBtnClicked = () => {
    const contactReq = contact.contactId
      ? { ...getObjectDiff(surveyContact, contact), contactId: contact.contactId }
      : contact;
    if (contactReq) {
      onSubmit(contactReq);
    }
  };

  const onCloseBtnClicked = () => {
    onClose(contact?.contactId);
  };

  // update the contact state when the value is changed.
  const handleContactUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = parseInputChange(event.target);
    setContact({ ...contact, [name]: value });
  };

  return (<div>
    <Grid
      container alignItems="flex-start" spacing={2}
      className={classes.marginBottom}>
      <Grid item sm={12} xs={12}>
        <TextField
          id="SC-SurveyContactName"
          inputProps={{ 'aria-label': 'contact-name' }}
          value={contact?.name}
          onChange={handleContactUpdate}
          fullWidth
          name="name"
          label="Name"
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <TextField
          id="SC-SurveyContactTitle"
          inputProps={{ 'aria-label': 'contact-title' }}
          value={contact?.title}
          onChange={handleContactUpdate}
          fullWidth
          name="title"
          label="Title"
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <TextField
          id="SC-SurveyContactEmail"
          inputProps={{ 'aria-label': 'contact-email' }}
          value={contact?.email}
          onChange={handleContactUpdate}
          fullWidth
          name="email"
          label="Email"
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <TextField
          id="SC-SurveyContactPhone"
          inputProps={{ 'aria-label': 'contact-phoneNumber' }}
          value={contact?.phoneNumber}
          onChange={handleContactUpdate}
          fullWidth
          name="phoneNumber"
          label="Phone #"
        />
      </Grid>
    </Grid>
    <Grid container justifyContent="flex-end">
      <Button
        id="SC-CancelContact"
        color="primary"
        onClick={onCloseBtnClicked}
        variant="text">
        Cancel
      </Button>
      <Button
        id="SC-SaveContact"
        className={classes.marginLeft}
        onClick={onSubmitBtnClicked}
        variant="contained">
        {contact?.contactId ? 'Update' : 'Save'}
      </Button>
    </Grid>
  </div>
  );
};

export type Props = {
  onClose: (id?: string) => void,
  onSubmit: (surveyContact: SurveyContactPost | SurveyContactPatch) => void;
  surveyContact?: SurveyContact;
};

SurveyContactForm.displayName = 'SurveyContactForm';
export default React.memo(SurveyContactForm);
