import React from 'react';
import { match } from 'react-router';
import { Provider } from 'react-redux';

import { Props as SurveyAppProps } from '../../apps/SurveyApp';
import store from '../../redux/store';
import SurveyBuilder from './SurveyBuilder';

const SurveyBuilderWrapper: React.FC<Props> = (props) => (
    <Provider store={store}>
      <SurveyBuilder {...props} />
    </Provider>
);

export type Props = {
  match: match;
  position: SurveyAppProps['position'];
};

export default SurveyBuilderWrapper;
