import React, { useEffect, useRef, useState } from 'react';
import TextField from '@cbreone/core-ui/dist/components/DebouncedTextField';
import { debounce } from 'lodash';
import { Grid, Typography, Container, FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { parseInputChange } from '@cbreone/utilities';
import { Property } from '../../types/property';
import { checkCoordinateInvalid, checkValueIsUnavailable, isEmptyObjectFromStr } from '../../utilities';
import { iconColorYellow, iconColorBlack, starIcon, emptyIcon } from '../../map/constants/MapStyles';
import MapOverlay from './MapOverlay';
import { BAMWSO2ClientID, BAMWSO2SecretID } from '../../config';

const useStyles = makeStyles((theme: Theme) => ({
  v_spacer: {
    height: theme.spacing(3),
  },
  mapbox: {
    width: '100%',
    height: '300px',
    marginTop: theme.spacing(2),
  },
}));

let args: any = null;
const PropertyAddressForm: React.FC<Props> = ({
  property,
  handlePropertyUpdate,
  handleCoordinatesUpdate,
  handleZoomAndCentroidUpdate,
}) => {
  let debounced: any = null;
  const classes = useStyles();
  const mapCompEle = useRef<any>();
  const isMapActivated = useRef(false);
  const isResetMapCenter = useRef(true);
  const [coordinates, setCoordinates] = useState<[any, any]>([
    property.latitude,
    property.longitude,
  ]);
  const defualtBamValues = {
    mapCentroid: [
      parseFloat(property.longitude),
      parseFloat(property.latitude),
    ],
    mapZoomLevel: 12,
  };
  const [propertyMapData, setPropertyMapData] = useState<any>(() => {
    if (
      property.bamMapData &&
      typeof JSON.parse(property.bamMapData) === 'object' &&
      !isEmptyObjectFromStr(property.bamMapData)
    ) {
      return JSON.parse(property.bamMapData);
    }
    return {
      mapZoomLevel: defualtBamValues.mapZoomLevel,
      mapCentroid: defualtBamValues.mapCentroid,
    };
  });
  const propertyMapDataRef = React.useRef(propertyMapData);

  const getPropertyAddress = (propertyDetails: Property) => {
    const { city, state } = propertyDetails;
    const address = `${city ? city.toString() : ''}, ${state ? state.toString() : ''}`;
    return address.replace(/<|>/g, '');
  };
  debounced = debounce((event) => {
    if (event) {
      isValidGeoAddress(event);
    }
  }, 0);

  const onValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    args = { target: { name: event.target.name, value: event.target.value } };
    if (debounced) {
      debounced.cancel();
    }
    debounced(args);
  };
  const getPoints = (
    coordinatesParam: [number, number],
    propertyParam: Property,
  ) => ({
    type: 'FeatureCollection',
    features: [
      {
        id: propertyParam.id,
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [+coordinatesParam[1], +coordinatesParam[0]] as [
            number,
            number
          ],
        },
        properties: {
          id: 1,
          title: 'Mapbox',
          group: 1,
          order: 1,
          description: `${propertyParam.city} ${propertyParam.state}`,
          location: {},
          markerConfig: {
            popup: `<h3>Mapbox</h3><p>${getPropertyAddress(propertyParam)}</p>`,
            iconSize: '24',
            iconColor: propertyParam.isCurrentLocation
              ? iconColorYellow
              : iconColorBlack,
            icon: propertyParam.isCurrentLocation ? starIcon : emptyIcon,
            textX: '27',
            textY: '38',
            textSize: '0',
          },
        },
      },
    ],
  });

  const renderMapPoints = (
    coordinatesParam: [any, any],
    propertyParam: Property,
  ) => {
    const points = getPoints(coordinatesParam, propertyParam);
    const mapComp = mapCompEle.current;
    // eslint-disable-next-line valid-typeof
    if (mapComp && typeof mapComp.addPoints === 'function') {
      mapComp.removeAllPoints().then(() => {
        if (
          !checkValueIsUnavailable(coordinatesParam[1]) &&
          !checkValueIsUnavailable(coordinatesParam[0])
        ) {
          mapComp.addPoints(points, false).then(() => {
            if (propertyMapDataRef.current) {
              const mapDataBam = propertyMapDataRef.current;
              if (
                mapDataBam.mapCentroid &&
                mapDataBam.mapCentroid[0] &&
                mapDataBam.mapCentroid[1] &&
                !isResetMapCenter.current
              ) {
                mapComp.updateMapCenter([
                  mapDataBam.mapCentroid[0],
                  mapDataBam.mapCentroid[1],
                ]);
              } else {
                mapComp.updateMapCenter([
                  +coordinatesParam[1],
                  +coordinatesParam[0],
                ]);
              }
              mapComp.updateMapZoom((mapDataBam.mapZoomLevel ?? 12));
            } else {
              mapComp.updateMapCenter([
                +coordinatesParam[1],
                +coordinatesParam[0],
              ]);
              mapComp.updateMapZoom(12);
            }
          });
        } else {
          mapComp.updateMapCenter([-95, 40]);
          mapComp.updateMapZoom(2.5);
        }
      });
    }
  };

  useEffect(() => {
    renderMapPoints(coordinates, property);
  }, []);

  useEffect(() => {
    // get the new coordinates from the event.
    const updatePoints = (e: any) => {
      const geoDetail = e.detail;
      if (geoDetail && geoDetail.value && geoDetail.value.geometry) {
        const latLong = geoDetail.value.geometry.coordinates;
        if (latLong && latLong[0] && latLong[1]) {
          isResetMapCenter.current = false;
          if (isMapActivated.current) {
            setCoordinates([
              latLong[1],
              latLong[0],
            ]);
            handleCoordinatesUpdate(
              `${latLong[1]}`,
              `${latLong[0]}`,
            );
          }
        }
      }
    };
    const handleZoom = (e: any) => {
      const data = {
        ...propertyMapDataRef.current,
        mapZoomLevel: e.detail.value,
      };
      propertyMapDataRef.current = data;
      setPropertyMapData(data);
      updateMapData(data);
    };
    const handleCentoried = (e: any) => {
      const data = {
        ...propertyMapDataRef.current,
        mapCentroid: e.detail.value,
      };
      propertyMapDataRef.current = data;
      setPropertyMapData(data);
      updateMapData(data);
    };

    const activateMap = () => {
      isMapActivated.current = true;
    };

    if (mapCompEle && mapCompEle.current) {
      mapCompEle.current.addEventListener('pointDragEnd', debounce(updatePoints, 2000));
      mapCompEle.current.addEventListener('zoomEnd', debounce(handleZoom, 2000));
      mapCompEle.current.addEventListener('dragEnd', debounce(handleCentoried, 2000));
      mapCompEle.current.addEventListener('mousedown', activateMap);
    }
    return () => {
      if (mapCompEle && mapCompEle.current) {
        mapCompEle.current.removeEventListener('pointDragEnd', debounce(updatePoints, 2000));
        mapCompEle.current.removeEventListener('zoomEnd', debounce(handleZoom, 2000));
        mapCompEle.current.removeEventListener('dragEnd', debounce(handleCentoried, 2000));
        mapCompEle.current.removeEventListener('mousedown', activateMap);
      }
    };
  }, []);

  const updateMapData = (data: any) => {
    if (
      data &&
      Object.keys(data).length > 0 &&
      isMapActivated.current
    ) {
      handleZoomAndCentroidUpdate(data);
    }
  };

  const resetBamMapState = (coordinatesParam: [any, any]) => {
    propertyMapDataRef.current = null;
  };

  const isValidGeoAddress = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    handlePropertyUpdate(event);
    // Only geocode when a coordinate doesn't already exist
    if (!property.latitude || !property.longitude) {
      if (property.address1) {
        if (
          (property.city && property.state) ||
          (property.postalCode && property.postalCode.length >= 5)
        ) {
          const mapComp = mapCompEle.current;
          const points = {
            type: 'FeatureCollection',
            features: [
              {
                properties: {
                  markerConfig: {
                    iconSize: '24',
                    iconColor: property.isCurrentLocation
                      ? iconColorYellow
                      : iconColorBlack,
                    icon: property.isCurrentLocation ? starIcon : emptyIcon,
                    textSize: '0',
                  },
                },
                geometry: {
                  coordinates: [],
                },
              },
            ] as any,
          };

          points.features[0].properties.location = {
            address: property.address1,
            city: property.city,
            stateprov: property.state,
            postalcode: property.postalCode,
            country: 'USA',
          };

          if (
            checkValueIsUnavailable(coordinates[1]) &&
            checkValueIsUnavailable(coordinates[0]) && points.features[0]
          ) {
            // @ts-ignore
            delete points.features[0].geometry.coordinates;
          }
          // eslint-disable-next-line valid-typeof
          if (mapComp && typeof mapComp.addPoints === 'function') {
            mapComp.removeAllPoints().then(() => {
              mapComp
                .addPoints(points, false)
                .then(
                  (point: {
                    features: { geometry: { coordinates: any } }[];
                  }) => {
                    const coors = point.features[0].geometry.coordinates;
                    setCoordinates([coors[1], coors[0]]);
                    mapComp.updateMapCenter([+coors[0], +coors[1]]);
                    handleCoordinatesUpdate(`${coors[1]}`, `${coors[0]}`);
                    mapComp.updateMapZoom(12);
                  },
                );
            });
          } else {
            mapComp.updateMapCenter([-95, 40]);
            mapComp.updateMapZoom(2.5);
          }
        }
      }
    }
  };

  React.useEffect(() => () => {
    if (mapCompEle && mapCompEle.current && typeof mapCompEle.current.removeMap === 'function') {
      mapCompEle.current.removeMap();
    }
  }, []);

  const hasValidCoordinate = (coord: any, coordType: string) => {
    const num = parseFloat(coord);
    if (Number.isNaN(num)) return false;
    let returnVal;
    if (coordType === 'latitude') {
      returnVal = num >= -90 && num <= 90;
    } else if (coordType === 'longitude') {
      returnVal = num >= -180 && num <= 180;
    }
    return returnVal;
  };

  return (
    <>
      <Typography variant="h6">Location Information</Typography>
      <div className={classes.v_spacer} />

      <Grid container>
        <Grid item sm={6} xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={property.isCurrentLocation}
                    name="isCurrentLocation"
                    onChange={(event) => {
                      handlePropertyUpdate(event);
                      renderMapPoints(
                        [property.latitude, property.longitude],
                        {
                          ...property,
                          isCurrentLocation: event.target.checked,
                        },
                      );
                    }}
                  />
                }
                label="Current Location"
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                inputProps={{ 'aria-label': 'address1' }}
                value={property.address1}
                onChange={onValueChange}
                onBlur={onValueChange}
                fullWidth
                name="address1"
                label="Address"
                debounceTime={400}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                inputProps={{ 'aria-label': 'city' }}
                value={property.city}
                onChange={onValueChange}
                onBlur={onValueChange}
                fullWidth
                name="city"
                label="City"
                debounceTime={400}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                inputProps={{ 'aria-label': 'state' }}
                value={property.state}
                onChange={onValueChange}
                onBlur={onValueChange}
                fullWidth
                name="state"
                label="State"
                debounceTime={400}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <TextField
                inputProps={{ 'aria-label': 'postalCode' }}
                value={property.postalCode}
                onChange={onValueChange}
                onBlur={onValueChange}
                fullWidth
                name="postalCode"
                label="Postal Code"
                debounceTime={400}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                inputProps={{ 'aria-label': 'country' }}
                value={property.country}
                onChange={onValueChange}
                onBlur={onValueChange}
                fullWidth
                name="country"
                label="Country"
                debounceTime={400}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                id="SC-SurveyPropertyLat"
                inputProps={{ 'aria-label': 'latitude' }}
                value={coordinates[0]}
                onChange={(event) => {
                  const latitude = parseInputChange(event.target).value;
                  if (hasValidCoordinate(latitude, 'latitude')) {
                    handlePropertyUpdate(event);
                    isResetMapCenter.current = true;
                    setCoordinates([latitude, property.longitude]);
                    resetBamMapState([latitude, property.longitude]);
                    renderMapPoints([latitude, property.longitude], property);
                  }
                }}
                type="number"
                fullWidth
                name="latitude"
                label="Latitude"
                debounceTime={400}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                id="SC-SurveyPropertyLong"
                inputProps={{ 'aria-label': 'longitude' }}
                value={coordinates[1]}
                onChange={(event) => {
                  const longitude = parseInputChange(event.target).value;
                  if (hasValidCoordinate(longitude, 'longitude')) {
                    handlePropertyUpdate(event);
                    isResetMapCenter.current = true;
                    setCoordinates([property.latitude, longitude]);
                    resetBamMapState([property.latitude, longitude]);
                    renderMapPoints([property.latitude, longitude], property);
                  }
                }}
                type="number"
                fullWidth
                name="longitude"
                label="Longitude"
                debounceTime={400}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Container className={classes.mapbox}>
            <mapping-component
              vendor="mapbox"
              client-id={BAMWSO2ClientID}
              secret-id={BAMWSO2SecretID}
              ref={mapCompEle}
              style={{ position: 'relative' }}
            >
              {(checkCoordinateInvalid(property.latitude)
              || checkCoordinateInvalid(property.longitude)) && <MapOverlay />}
            </mapping-component>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export type Props = {
  property: Property;
  handlePropertyUpdate: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  handleCoordinatesUpdate: (lat: string, long: string) => void;
  handleZoomAndCentroidUpdate: (bamData: any) => void;
};

export default PropertyAddressForm;
