type AppConfig = {
  surveyServiceBaseURL: string;
  propertyServiceBaseURL: string;
  userServiceBaseURL: string;
  mediaServiceBaseURL: string;
  mediaRootURL: string;
  deliverableRootURL: string;
  appRootURL: string;
  BAMWSO2ClientID: string;
  BAMWSO2SecretID: string;
  EnvironmentType: string;
  mulesoftRootUrl: string;
  mulesoftClientId: string;
  mulesoftClientSecret: string;
  mediaFileGenerationURL:string;
};

export const surveyServiceBaseURL = process.env.NX_SURVEY_SERVICE_BASE_URL;
export const propertyServiceBaseURL = process.env.NX_PROPERTY_SERVICE_BASE_URL;
export const userServiceBaseURL = process.env.NX_USER_SERVICE_BASE_URL;
export const mediaServiceBaseURL = process.env.NX_MEDIA_SERVICE_BASE_URL;
export const mediaFileGenerationURL = mediaServiceBaseURL?.substring(0, mediaServiceBaseURL.lastIndexOf('media/'));
export const deliverableRootURL = process.env.NX_DELIVERABLE_ROOT_URL;
export const parentAppURL = process.env.NX_PARENT_APP_URL;
export const appRootURL = process.env.NX_APP_ROOT_URL;
export const BAMWSO2ClientID = process.env.NX_WSO2_CLIENT_ID;
export const BAMWSO2SecretID = process.env.NX_WSO2_SECRET_ID;
export const EnvironmentType = process.env.NX_ENVIRONMENT_TYPE;
export const mulesoftRootUrl = process.env.NX_MULESOFT_ROOT_URL;
export const mulesoftProxyUrl = process.env.NX_MULESOFT_PROXY_URL;
export const mulesoftProxyId = process.env.NX_MULESOFT_PROXY_ID;
export const mulesoftClientId = process.env.NX_MULESOFT_CLIENT_ID;
export const mulesoftClientSecret = process.env.NX_MULESOFT_SECRET_ID;
export const mediaRootURL = process.env.NX_MEDIA_ROOT_URL;
export const propertyGetCollateralsURL = `${parentAppURL}/services/apexrest/getCollaterals`;

export default {
  surveyServiceBaseURL,
  propertyServiceBaseURL,
  userServiceBaseURL,
  mediaServiceBaseURL,
  deliverableRootURL,
  appRootURL,
  BAMWSO2ClientID,
  BAMWSO2SecretID,
  EnvironmentType,
  mulesoftRootUrl,
  mulesoftClientId,
  mulesoftClientSecret,
  mediaRootURL,
  mediaFileGenerationURL,
} as AppConfig;
